.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.icons-list div {
  line-height: 40px;
  white-space: nowrap;
  cursor: default;
  position: relative;
  z-index: 1;
  padding: 5px;
  border-right: 1px solid #252a33;
}
.icons-list div i {
  display: inline-block;
  width: 40px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: font-size 0.2s;
  -moz-transition: font-size 0.2s;
  transition: font-size 0.2s;
}
.icons-list div:hover i {
  font-size: 26px;
}

.form_wizard_tab {
    margin-right: 2px; border-radius: 0;
}

.form_wizard_tab:hover {
  
  margin-right: 2px;
}

.form_wizard_tab.active {
  
}

.form_wizard_width {
  width: calc(25% - 2px);
}

.lookalike .form_wizard_width {
  width: calc(50% - 2px);
}

.lookalike a {
  text-align: center;
}

.form_wizard_width1 {
  width: calc(33% - 2px);
}

.left_dropdown_btn {
  background-color: transparent !important;
  border: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.left_dropdown_btn:hover {
  background-color: transparent;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:active {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:focus {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.user-account .dropdown .dropdown-menu {
  top: 0 !important;
}

.sidebar-scroll {
  overflow-y: auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  height: 100%;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 0;
  background-color: #141414;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #141414;
}

.black-editor {
  background-color: #141414 !important;
}

.text-editor-black .public-DraftEditor-content {
  height: 150px;
  border: 1px solid #f1f1f1;
  padding: 5px;
}

.custom_tab.active {
  background-color: #2f3338 !important;
  border-color: #2f3338 !important;
  color: #a5a8ad !important;
}

.custom_tab.nav-link {
  color: #ffffff;
}

.mini_sidebar .mini_sidebar_on .user-account {
  display: none !important;
}

.custom_pagination > * {
  margin: 10px;
}

.custom_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiButtonBase-root.MuiPaginationItem-root {
  background-color: #ffffff;
}

.VulnerabilitiesBox .row .col-lg-6,
.VulnerabilitiesBox .row .col-md-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.bg-critical {
  background-color: #f66d9b !important;
}
.bg-high {
  background-color: #6435c9 !important;
}
.bg-medium {
  background-color: #9367b4 !important;
}
.bg-low {
  background-color: #5e6266 !important;
}

.vulerabilityHeader {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #141414;
  padding: 12px 12px 4px 12px;
}

.vulerabilityHeader .card {
  margin-bottom: 24px;
}

.assetsDiscoveryModal .modal-dialog {
  overflow-y: initial !important;
}
.assetsDiscoveryModal .modal-body,
#webTech .modal-body,
#clientModal .modal-body {
  height: 80vh;
  overflow-y: auto;
}

.badge-info {
  background: transparent;
  border-color: rgb(4, 78, 227);
  color: rgb(4, 78, 227);
}

.badge-low {
  background: transparent;
  border-color: green;
  color: green;
}

.badge-med {
  background: transparent;
  border-color: yellow;
  color: yellow;
}

.badge-high {
  background: transparent;
  border-color: orange;
  color: orange;
}

.badge-critical {
  background: transparent;
  border-color: red;
  color: red;
}

.toggle-password {
  position: absolute;
  right: 17px;
  /* top: 30%; */
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer;
}
.toggle-password.fa-eye {
  margin-right: 1px;
}

ul.metismenu {
  padding-bottom: 46px !important;
}

#tags-standard {
  color: #ffffff;
}

.disabledClass {
  pointer-events: none;
  cursor: default;
}

.reactTooltipClass {
  width: 250px;
  word-break: break-all;
  overflow-wrap: break-word;
}

#summaryBox .col-6,
#summaryBox2 .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}

#summaryBox .body {
  max-height: 158px;
}

#summaryBox2 .card .body {
  /* margin-right: 1%; */
  min-height: 184px;
}

.clientCalendar .react-datepicker-wrapper {
  width: 100%;
}

.clientPageModal {
  min-width: 1000px;
}

.vendorModal {
  min-width: 400px;
  max-width: 600px;
}

.vendorMainModal {
  height: 70%;
  top: 10%;
}

#clientServices .MuiInput-underline:before {
  border-bottom: 1px solid #393d42;
}

.summaryGraph text {
  font-size: 32px;
  fill: #ffffff;
}

.page-wrapper_login {
  min-height: 100vh;
  background-color: #f2f2f7;
  display: flex;
}

.page-wrapper_login .header-wrapper {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 32px;
  padding-bottom: 0px;
}

.page-wrapper_login .container-default {
  max-width: 450px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.page-wrapper_login .header-content-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.page-wrapper_login .header-middle {
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  /* transform-style: preserve-3d; */
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}

.page-wrapper_login .header-logo-link {
  width: 100%;
  max-width: 241px;
  margin-right: 20px;
  padding-left: 0;
  /* transition: transform 0.3s, color 0.3s; */
}

.page-wrapper_login .section.bg-default {
  /* background-image: linear-gradient(#060606 20%, rgba(6, 6, 6, 0)),
    url(../images/bg-coming-soon-finantech-webflow-template.svg);
  background-position: 0 0, 50% 100%; */
  background: #f2f2f7;
  /* background-repeat: repeat, no-repeat;
  background-size: auto, cover; */
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.page-wrapper_login .container-default {
  max-width: 1332px;
  padding-left: 24px;
  padding-right: 24px;
}

.page-wrapper_login .btn-primary {
  background-color: #ffa2a2;
  border-radius: 30px;
  height: 40px;
  padding: 0;
  color: #000;
  border: 0;
  text-align: center;
  font-size: 16px;

  font-weight: 600;
  line-height: 32px;
}

.page-wrapper_login .section {
  padding-top: 60px;
}

.page-wrapper_login .btn-primary:hover {
  color: #fff;
  background-color: #dd3731;
  border-color: #dd3731;
  /* transform: translate3d(0, -3px, 0.01px); */
}

.page-wrapper_login .btn-secondary:hover {
  color: #fff;
  background-color: #dd3731;
  border-color: #dd3731;
  /* transform: translate3d(0, -3px, 0.01px); */
}

.page-wrapper_login .card._24px.sign-in-card {
  border: 0px solid #2f2f2f;
  background: #fff;
  border-radius: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.mg-bottom-24px,
.mg-bottom-24px.keep {
  margin-bottom: 24px;
  color: #fff;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.222em;
}

.page-wrapper_login .btn-secondary {
  background-color: transparent;
}

.page-wrapper_login form a {
  color: #dd3731;
  text-decoration: none;
  transition: color 0.3s;
}

.email-text-verfiy {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.429em;
}

.emailClass .w-input[disabled]:not(.w-input-disabled) {
  background-color: black;
}

.theme-cyan ::selection {
  color: #000000;
}

/*CSS added for UI Updates*/
.w-34 {
  width: 34%;
}
.bg-login-right {
  width: 66%;
  height: 100vh;
  width: 66%;
  height: 100vh;
  background: url(../images/image-gallery/bg-login-omdr.jpg) no-repeat center
    center;
  background-size: cover;
}
.inner-container._450px {
  width: 100%;
}
.sign-card-content .input {
  background-color: #f2f2f7;
  border-radius: 40px;
  border: 0;
  box-shadow: none;
  padding-top: 5px;
  height: 50px;
  font-size: 14px;
  padding: 0 20px 0 55px;
  line-height: inherit;
  margin: 0 0 10px;
  min-height: inherit; color: #444;
}
.divider._20px {
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-secondary.sign-in-button {
  background: #fae1e0;
  color: #231728;
  border-radius: 40px;
  height: 40px;
  font-size: 14px;
  padding-left: 0px;
  border: 0;
}
.social-icon-font.button-icon-left {
  font-size: 20px;
  margin-right: 12px;
}
.sign-card-content {
  padding-left: 10px;
  padding-right: 10px;
}
.icon-login-page {
  border-radius: 50%;
  margin: 5px 0 0 5px;
  width: 40px;
  height: 40px;
  background: #fff;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: absolute;

  color: #4d4d4d;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 20px;
}
.divider {
  background-color: #cec3ce;
}
.sign-form {
  min-height: auto;
}

.powered-by {
  position: absolute;
  bottom: 50px;
  color: #333;
  font-size: 14px;
  text-align: center;
}
.powered-logo {
  max-width: 180px;
}

/*-----------------Button CSS*/
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #dd3731;
  border-color: #dd3731;
  color: #fff;
}

.page-wrapper_login .btn-primary{color: #000 !important;}
.page-wrapper_login .btn-primary:hover{color: #fff !important;}

/* Container */
.av-container {
  background-color: #ffffff;
  color: #333333;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  border: 1px solid #dadada;
  border-radius: 8px;
}

/* Header */
.av-header {
  text-align: left;
  margin-bottom: 20px;
}

.av-header h1 {
  color: #333333;
  font-size: 24px;
}

.av-header p {
  color: #666666;
  font-size: 14px;
  margin-top: 5px;
}

/* Vulnerability Info Section */
.av-vulnerability-info {
  background-color: #fadfe1;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.av-vulnerability-info h2 {
  color: #b30000;
  font-size: 20px;
  margin-bottom: 10px;
}

.av-info-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.av-info-section p {
  font-size: 14px;
  color: #333333;
}

.av-risk-critical {
  color: #b30000;
  font-weight: bold;
}

/* Content Section */
.av-content {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
}

.av-content-section {
  margin-bottom: 20px;
}

.av-content-section h3 {
  color: #000000;
  font-size: 18px;
}

.av-content-section p {
  color: #333333;
  font-size: 14px;
  line-height: 1.6;
}

.av-content-section a {
  color: #000000;
  text-decoration: none;
}

.av-content-section a:hover {
  text-decoration: underline;
}

/* Icon Styling */
.av-info-section p svg {
  margin-right: 8px;
  color: #b30000;
}
