.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.w-commerce-commercecartopenlink {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartopenlinkcount {
  height: 18px;
  min-width: 18px;
  color: #3898ec;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  margin-left: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
}

.w-commerce-commercecartcontainerwrapper {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.w-commerce-commercecartcontainerwrapper--cartType-modal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: auto;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: auto;
  right: 0;
}

.w-commerce-commercecartcontainer {
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  overflow: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, .25);
}

.w-commerce-commercecartheader {
  border-bottom: 1px solid #e6e6e6;
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.w-commerce-commercecartheading {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.w-commerce-commercecartformwrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.w-commerce-commercecartform {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecartlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 12px 24px;
  overflow: auto;
}

.w-commerce-commercecartitem {
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.w-commerce-commercecartoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercecartquantity {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecartfooter {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  flex: none;
  padding: 16px 24px 24px;
  display: flex;
}

.w-commerce-commercecartlineitem {
  flex: none;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
  display: flex;
}

.w-commerce-commercecartordervalue {
  font-weight: 700;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  min-height: 30px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon, .w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecartcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecartemptystate {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.w-commerce-commercecarterrorstate {
  background-color: #ffdede;
  flex: none;
  margin: 0 24px 24px;
  padding: 10px;
}

.w-pagination-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.w-pagination-previous, .w-pagination-next {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus, .w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus, .w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus, .w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem, .w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutdiscounts {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  flex-wrap: wrap;
  padding: 20px;
  display: flex;
}

.w-commerce-commercecheckoutdiscountslabel {
  flex-basis: 100%;
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutdiscountsinput {
  height: 38px;
  width: auto;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  min-width: 0;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  flex: 1;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutdiscountsinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutdiscountsbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  margin-left: 8px;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commerceaddtocartform {
  margin: 0 0 15px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  margin-bottom: 10px;
  display: flex;
}

.w-commerce-commerceaddtocartoptionpill {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 10px;
  padding: 8px 15px;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  margin-top: 10px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  background-color: #ddd;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceaddtocarterror {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem, .w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: .33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper, .w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }

  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecartcontainerwrapper--cartType-modal {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown, .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .w-commerce-commercecartquantity, .w-commerce-commercecheckoutemailinput, .w-commerce-commercecheckoutshippingfullname, .w-commerce-commercecheckoutshippingstreetaddress, .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity, .w-commerce-commercecheckoutshippingstateprovince, .w-commerce-commercecheckoutshippingzippostalcode, .w-commerce-commercecheckoutshippingcountryselector, .w-commerce-commercecheckoutcardnumber, .w-commerce-commercecheckoutcardexpirationdate, .w-commerce-commercecheckoutcardsecuritycode, .w-commerce-commercecheckoutbillingfullname, .w-commerce-commercecheckoutbillingstreetaddress, .w-commerce-commercecheckoutbillingstreetaddressoptional, .w-commerce-commercecheckoutbillingcity, .w-commerce-commercecheckoutbillingstateprovince, .w-commerce-commercecheckoutbillingzippostalcode, .w-commerce-commercecheckoutbillingcountryselector, .w-commerce-commercecheckoutdiscountsinput, .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  color: silver;
  background-color: #060606;
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.667em;
}

h1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.222em;
}

h2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.316em;
}

h3 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.417em;
}

h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.273em;
}

h5 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.333em;
}

h6 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.375em;
}

p {
  margin-bottom: 16px;
}

a {
  color: #ffffff;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #fff;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 8px;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  color: #fff;
  margin-bottom: 12px;
  font-weight: 700;
  display: block;
}

strong {
  color: #fff;
  font-weight: 700;
}

blockquote {
  color: #fff;
  text-align: center;
  background-color: #141414;
  background-image: url('../images/block-quote-bg-finantech-x-webflow-template.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0 solid #000;
  border-radius: 24px;
  margin-top: 32px;
  margin-bottom: 48px;
  padding: 107px 66px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.375em;
}

figure {
  margin-top: 24px;
  margin-bottom: 48px;
}

figcaption {
  text-align: center;
  margin-top: 16px;
}

.page-wrapper {
  min-height: 100vh;
  background-color: #060606;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}



.grid-2-columns.style---styleguide-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: .25fr 1fr;
  align-items: start;
}

.grid-2-columns.form {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #060606;
  margin-bottom: 40px;
}

.grid-2-columns.contact-grid {
  grid-column-gap: 40px;
  grid-template-columns: 1fr minmax(490px, 1fr);
}

.grid-2-columns.newsletter {
  grid-column-gap: 100px;
  grid-template-columns: .86fr 1fr;
  position: relative;
}

.grid-2-columns.cta-v1 {
  grid-column-gap: 50px;
  grid-template-columns: 1fr .9fr;
}

.grid-2-columns.blog-card-featured {
  grid-column-gap: 0px;
  grid-template-columns: 1fr .85fr;
}

.grid-2-columns.title-and-paragraph {
  grid-column-gap: 40px;
  grid-template-columns: 1fr .8fr;
}

.grid-2-columns.text-left-default {
  grid-column-gap: 40px;
  grid-template-columns: .8fr 1fr;
}

.grid-2-columns.text-right-default {
  grid-column-gap: 40px;
  grid-template-columns: 1fr .8fr;
}

.grid-2-columns.text-left-short {
  grid-column-gap: 40px;
  grid-template-columns: .9fr 1fr;
}

.grid-2-columns.template-page-sidebar {
  grid-column-gap: 40px;
  grid-template-columns: .35fr 1fr;
  align-items: start;
}

.grid-2-columns.changelog-item {
  grid-column-gap: 60px;
  border-bottom: 1px solid #e7e7e7;
  grid-template-columns: .4fr 1fr;
  align-items: start;
  padding-top: 64px;
  padding-bottom: 64px;
}

.grid-2-columns.changelog-item.first {
  border-bottom-width: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.grid-2-columns.product-page {
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.grid-2-columns.checkout-page {
  grid-template-columns: 1fr .5fr;
  align-items: start;
}

.grid-2-columns.suprises-pages-right {
  grid-column-gap: 100px;
  grid-template-columns: 1fr 1.25fr;
  margin-bottom: 124px;
}

.grid-2-columns.suprises-pages-right.last {
  margin-bottom: 0;
}

.grid-2-columns.suprises-pages-left {
  grid-column-gap: 100px;
  grid-template-columns: 1.25fr 1fr;
  margin-bottom: 124px;
}

.grid-2-columns.home-hero-grid {
  grid-column-gap: 0px;
  grid-template-columns: minmax(480px, .85fr) 1.3fr;
}

.grid-2-columns.security-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-2-columns.slider-grid {
  grid-column-gap: 40px;
  grid-template-columns: .8fr 1fr;
}

.grid-2-columns.cta-v2 {
  grid-column-gap: 0px;
  grid-template-columns: 1.1fr 1fr;
}

.grid-2-columns.text-right-short {
  grid-column-gap: 40px;
  grid-template-columns: 1fr .6fr;
}

.grid-2-columns.text-right-short.alt-v1 {
  grid-template-columns: 1fr minmax(445px, .6fr);
}

.grid-2-columns.title-left {
  grid-template-columns: .6fr 1fr;
}

.grid-2-columns.author-card {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: .6fr 1fr;
}

.grid-2-columns.hero-images-grid {
  grid-column-gap: 14px;
  grid-template-columns: .7fr 1fr;
}

.grid-2-columns.sidebar-right-v1 {
  grid-template-rows: minmax(auto, 473px);
  grid-template-columns: 1fr .6fr;
}

.grid-2-columns.sidebar-left {
  grid-template-columns: .6fr 1fr;
}

.grid-2-columns.hero-images-grid-v2 {
  grid-column-gap: 16px;
  grid-template-columns: minmax(500px, 1.1fr) 1fr;
  position: relative;
}

.grid-2-columns.title-left-grid {
  grid-template-columns: minmax(435px, .55fr) 1fr;
}

.grid-2-columns.title-left-grid.top {
  margin-bottom: -16px;
}

.grid-2-columns.title-left-grid.center {
  margin-bottom: -60px;
}

.grid-2-columns.left-0-7fr {
  grid-template-columns: .7fr 1fr;
}

.grid-2-columns.sidebar-right {
  grid-template-columns: 1fr .6fr;
}

.grid-2-columns.coming-soon-grid {
  grid-template-columns: 1.2fr 1fr;
}

.grid-2-columns.team-strip {
  grid-template-columns: .5fr 1fr;
}

.grid-2-columns.title-and-buttons {
  grid-column-gap: 40px;
  grid-template-columns: 1fr auto;
}

.grid-2-columns.team-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: minmax(480px, 1fr) 1fr;
}

.grid-2-columns.sign-up-page-grid {
  grid-template-columns: minmax(600px, 1fr) .85fr;
}

.style---navigation-sidebar {
  z-index: 1;
  height: 100%;
  max-height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
  box-shadow: 1px 0 14px rgba(0, 0, 0, .06);
}

.style---main-wrapper {
  max-width: 1151px;
  background-color: #060606;
}

.card {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f2f2f;
  border-radius: 18px;
  overflow: hidden;
  transform: translate(0);
}

.card.sticky-top.top-24px {
  top: 24px;
}

.card.checkout-block {
  margin-bottom: 28px;
  padding: 32px 32px 48px;
}

.card.checkout-block.order-summary {
  padding-bottom: 40px;
}

.card.checkout-block.last {
  margin-bottom: 0;
}

.card.whats-included {
  flex-direction: column;
  display: flex;
}

.card.figma-file {
  justify-content: center;
  align-items: stretch;
  margin-top: 32px;
  display: flex;
}

.card.sales-home-page {
  text-align: center;
  flex-direction: column;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: flex;
}

.card.sales-home-page:hover {
  transform: translate(0, -4px);
}

.card.buy-now {
  background-color: #4253ff;
  border-radius: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: flex;
  overflow: hidden;
}

.card.buy-now:hover {
  transform: translate(0, -4px);
}

.card.buy-now.white {
  background-color: #2f2f2f;
  border-color: #717172;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
}

.card.security-card {
  box-shadow: 0 8px 66px rgba(0, 0, 0, .3);
}

.card.security-card.no-shadow {
  box-shadow: none;
}

.card._24px {
  border-radius: 24px;
  align-self: flex-start;
}

.card._24px.team-member-card {
  position: relative;
  overflow: hidden;
}

.card._24px.integration-information-card {
  width: 100%;
  max-width: 450px;
  background-color: #141414;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  box-shadow: 0 8px 60px rgba(0, 0, 0, .02);
}

.card._24px.product-card {
  height: 100%;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 10px rgba(21, 19, 91, .03);
}

.card._24px.product-card.popular {
  margin-bottom: 55px;
}

.card._24px.accordion-card-wrapper {
  padding-top: 48px;
  padding-bottom: 56px;
}

.card._24px.add-to-cart-card {
  width: 100%;
  max-width: 516px;
  background-color: #141414;
  padding: 72px 34px;
  box-shadow: 0 8px 60px rgba(0, 0, 0, .2);
}

.card._24px.add-to-cart-card.email_verfiy{
  max-width: auto;
}

.card._24px.sign-in-card {
  z-index: 1;
  background-color: #141414;
  padding-top: 72px;
  padding-bottom: 72px;
  position: relative;
}

.cta-section-form .card._24px.sign-in-card{
  padding-top: 0px;
  padding-bottom: 0px;
}

.sign-in-card-new

.card._24px.demo-card {
  background-color: #141414;
  padding: 138px 95px;
}

.card.features-card-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 24px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.card.integration-card {
  height: 100%;
  background-color: #060606;
  border-color: #2f2f2f;
  flex-direction: column;
  align-items: flex-start;
  padding: 63px 32px;
  text-decoration: none;
  display: flex;
}

.card.integration-card_m{
  padding: 25px;
} 

.card.integration-card.border-neutral-600 {
  border-color: #717172;
}

.card.testimonial-card {
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 35px;
  display: flex;
}

.card.search-result {
  min-height: 100%;
  color: silver;
}

.card.search-result:hover {
  color: silver;
}

.style---color-block {
  min-height: 180px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  position: relative;
}

.style---color-block-content {
  border-top: 1px solid #232323;
  padding: 24px 24px 32px;
}

.style---color-block-name {
  color: #fff;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.style---color-block-hex {
  line-height: 20px;
}

.grid-4-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.bg-accent-1 {
  background-color: #b0f127;
}

.bg-secondary-1 {
  background-color: #547907;
}

.bg-secondary-2 {
  background-color: #71a407;
}

.bg-secondary-3 {
  background-color: #141414;
}

.bg-secondary-4 {
  background-color: #232323;
}

.bg-neutral-800 {
  background-color: #060606;
}

.bg-neutral-700 {
  background-color: #2f2f2f;
}

.bg-neutral-600 {
  background-color: #717172;
}

.bg-neutral-500 {
  background-color: #939393;
}

.bg-neutral-400 {
  background-color: silver;
}

.bg-neutral-300 {
  background-color: #e7e7e7;
}

.bg-neutral-200 {
  background-color: #f4f4f4;
}

.bg-neutral-100 {
  background-color: #fff;
}

.style---color-block-text-example {
  background-color: #fff;
  border-radius: 40px;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 16px;
  bottom: auto;
  left: auto;
  right: 16px;
}

.style---color-block-text-example.badge-dark {
  background-color: #060606;
}

.color-accent-1 {
  color: #b0f127;
}

.color-secondary-1 {
  color: #547907;
}

.color-secondary-2 {
  color: #71a407;
}

.color-secondary-3 {
  color: #141414;
}

.color-secondary-4 {
  color: #232323;
}

.color-neutral-800 {
  color: #060606;
}

.color-neutral-700 {
  color: #2f2f2f;
}

.color-neutral-600 {
  color: #717172;
}

.color-neutral-500 {
  color: #939393;
}

.color-neutral-300 {
  color: #e7e7e7;
}

.color-neutral-200 {
  color: #f4f4f4;
}

.color-neutral-100 {
  color: #fff;
}

.bg-green-400 {
  background-color: #05C168;
}

.color-green-400 {
  color: #05C168;
}

.bg-blue-400 {
  background-color: #086cd9;
}

.color-blue-400 {
  color: #086cd9;
}

.bg-blue-300 {
  background-color: #1d88fe;
}

.color-blue-300 {
  color: #1d88fe;
}

.bg-blue-200 {
  background-color: #8fc3ff;
}

.bg-blue-100 {
  background-color: #eaf4ff;
}

.color-blue-100 {
  color: #eaf4ff;
}

.color-neutral-400 {
  color: silver;
}

.bg-green-300 {
  background-color: #05c168;
}

.color-green-300 {
  color: #05c168;
}

.bg-green-200 {
  background-color: #7fdca4;
}

.color-green-200 {
  color: #7fdca4;
}

.color-green-100 {
  color: #def2e6;
}

.bg-green-100 {
  background-color: #def2e6;
}

.bg-red-400 {
  background-color: #dc2b2b;
}

.color-red-400 {
  color: #dc2b2b;
}

.bg-red-300 {
  background-color: #ff5a65;
}

.color-red-300 {
  color: #ff5a65;
}

.bg-red-200 {
  background-color: #ffbec2;
}

.color-red-200 {
  color: #ffbec2;
}

.bg-red-100 {
  background-color: #ffeff0;
}

.color-red-100 {
  color: #ffeff0;
}

.bg-orange-400 {
  background-color: #d5691b;
}

.color-orange-400 {
  color: #d5691b;
}

.bg-orange-300 {
  background-color: #ff9e2c;
}

.color-orange-300 {
  color: #ff9e2c;
}

.bg-orange-200 {
  background-color: #ffd19b;
}

.color-orange-200 {
  color: #ffd19b;
}

.bg-orange-100 {
  background-color: #fff3e4;
}

.color-orange-100 {
  color: #fff3e4;
}

.style---block-sub-heading {
  margin-bottom: 32px;
}

.style---block-sub-heading.border {
  border-bottom: 1px solid #2f2f2f;
  margin-bottom: 64px;
  padding-bottom: 40px;
}

.divider {
  height: 1px;
  background-color: #2f2f2f;
  margin-top: 80px;
  margin-bottom: 80px;
}

.divider._64px {
  margin-top: 64px;
  margin-bottom: 64px;
}

.divider._64px.template-pages-start-here {
  margin-bottom: 40px;
}

.divider._40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.divider._0px {
  margin-top: 0;
  margin-bottom: 0;
}

.divider.integrations-divider {
  background-color: rgba(255, 255, 255, .2);
  align-self: stretch;
  margin-top: 24px;
  margin-bottom: 24px;
}

.divider._48px {
  margin-top: 48px;
  margin-bottom: 48px;
}

.style---typography-block-grid {
  grid-column-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 320px) 1fr;
}

.style---content-block {
  margin-bottom: 100px;
}

.mg-bottom-8px {
  margin-bottom: 8px;
}

.mg-bottom-16px, .mg-bottom-16px.keep {
  margin-bottom: 16px;
}

.mg-bottom-24px, .mg-bottom-24px.keep {
  margin-bottom: 24px;
}

.mg-bottom-32px {
  margin-bottom: 32px;
}

.mg-bottom-40px {
  margin-bottom: 40px;
}

.mg-bottom-48px {
  margin-bottom: 48px;
}

.mg-bottom-56px {
  margin-bottom: 56px;
}

.mg-bottom-64px {
  margin-bottom: 64px;
}

.mg-bottom-72px {
  margin-bottom: 72px;
}

.mg-top-16px {
  margin-top: 16px;
}

.mg-top-40px {
  margin-top: 40px;
}

.mg-top-48px {
  margin-top: 48px;
}

.mg-right-8px {
  margin-right: 8px;
}

.mg-right-24px {
  margin-right: 24px;
}

.text-200 {
  font-size: 18px;
  line-height: 1.111em;
}

.text-200.list {
  line-height: 24px;
}

.text-200.medium {
  font-weight: 500;
}

.text-200.bold {
  font-weight: 700;
}

.display-1 {
  color: #fff;
  font-size: 72px;
  font-weight: 600;
  line-height: 1.167em;
}

.display-1.color-accent-1 {
  color: #b0f127;
}

.display-1.dot {
  line-height: .4;
}

.grid-1-column {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-1-column.gap-row-0 {
  grid-row-gap: 0px;
}

.grid-1-column.hero-grid {
  grid-row-gap: 56px;
}

.gap-16px {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.gap-24px {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.gap-32px {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.gap-40px {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.gap-column-24px {
  grid-column-gap: 24px;
}

.gap-row-16px {
  grid-row-gap: 16px;
}

.gap-row-24px {
  grid-row-gap: 24px;
}

.gap-row-48px {
  grid-row-gap: 48px;
}

.gap-row-80px {
  grid-row-gap: 80px;
}

.display-2 {
  color: #fff;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.222em;
}

.display-2.color-accent-1 {
  color: #b0f127;
}

.display-2.mg-bottom-0.mg-right-10px {
  margin-right: 10px;
}

.display-2.color-neutral-800 {
  color: #060606;
}

.display-3 {
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.294em;
}

.display-3.mg-bottom-4px.team-member-name {
  transition: color .3s;
}

.display-3.mg-bottom-4px.team-member-name:hover {
  color: #b0f127;
}

.display-4 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.429em;
}

.display-4.color-neutral-800 {
  color: #060606;
}

.style---style-grid-wrapper {
  grid-row-gap: 180px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.paragraph-large {
  font-size: 24px;
  line-height: 1.583em;
}

.paragraph-small {
  font-size: 14px;
  line-height: 1.714em;
}

.text-400 {
  font-size: 24px;
  line-height: 1.083em;
}

.text-400.medium {
  font-weight: 500;
}

.text-400.bold {
  font-weight: 700;
}

.text-300 {
  font-size: 20px;
  line-height: 1.1em;
}

.text-300.medium {
  font-weight: 500;
}

.text-300.medium.top--8px {
  position: relative;
  top: -8px;
}

.text-300.medium.dropdown-title {
  color: #fff;
  margin-bottom: 40px;
}

.text-300.bold {
  font-weight: 700;
}

.text-300.price-subheading {
  color: #939393;
  font-weight: 500;
  position: relative;
  top: -6px;
}

.text-300.price-subheading.popular {
  color: #b0f127;
}

.text-100 {
  font-size: 16px;
  line-height: 1.125em;
}

.text-100.medium {
  font-weight: 500;
}

.text-100.bold, .text-bold {
  font-weight: 700;
}

.text-link {
  color: #b0f127;
  text-decoration: underline;
  transition: color .3s;
}

.text-link:hover, .text-link.white {
  color: #fff;
}

.text-link.white:hover {
  color: #b0f127;
}

.text-uppercase {
  letter-spacing: .1em;
  text-transform: uppercase;
}

.text-strikethrough {
  text-decoration: line-through;
}

.grid-3-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-3-columns.gap-row-80px {
  grid-row-gap: 80px;
}

.grid-3-columns.style---buttons-grid {
  grid-column-gap: 70px;
  grid-template-columns: auto auto 1fr;
}

.grid-3-columns.style---buttons-grid.download-app-grid {
  grid-template-columns: auto auto;
}

.grid-3-columns._1-col-tablet.gap-32px {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.grid-3-columns.gap-16px {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.grid-3-columns.testimonial-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-content: start;
  align-items: start;
  overflow: hidden;
}

.grid-3-columns.dropdown-grid {
  grid-row-gap: 68px;
  grid-template-columns: auto auto auto;
}

.style---shadow-card {
  min-height: 220px;
  text-align: center;
  background-color: #141414;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 44px;
  display: flex;
}

.style---shadow-card.button-shadow-color-01 {
  box-shadow: 0 4px 10px rgba(176, 241, 39, .06);
}

.shadow-01 {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
}

.shadow-02 {
  box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
}

.shadow-03 {
  box-shadow: 0 8px 60px rgba(0, 0, 0, .2);
}

.shadow-04 {
  box-shadow: 0 14px 42px rgba(20, 20, 43, .14);
}

.shadow-05 {
  box-shadow: 0 24px 65px rgba(20, 20, 43, .16);
}

.shadow-06 {
  box-shadow: 0 32px 72px rgba(20, 20, 43, .24);
}

.button-shadow-color-01 {
  box-shadow: 0 4px 10px rgba(176, 241, 39, .06);
}

.button-shadow-color-02 {
  box-shadow: 0 6px 20px rgba(176, 241, 39, .08);
}

.button-shadow-color-03 {
  box-shadow: 0 10px 28px rgba(176, 241, 39, .08);
}

.button-shadow-white-01 {
  box-shadow: 0 4px 10px rgba(20, 20, 43, .04);
}

.button-shadow-white-02 {
  box-shadow: 0 6px 20px rgba(20, 20, 43, .06);
}

.button-shadow-white-03 {
  box-shadow: 0 10px 28px rgba(20, 20, 43, .1);
}

.style---heading {
  min-height: 70vh;
  background-color: #141414;
  align-items: center;
  margin-bottom: 30px;
  padding: 72px 4vw;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
}

.inner-container._740px {
  max-width: 740px;
}

.inner-container._600px {
  max-width: 600px;
}

.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.inner-container._574px {
  max-width: 574px;
}

.inner-container._400px {
  max-width: 400px;
}

.inner-container._460px {
  max-width: 460px;
}

.inner-container._900px {
  max-width: 900px;
}

.inner-container._990px {
  max-width: 990px;
}

.inner-container._800px {
  max-width: 800px;
}

.inner-container._700px {
  max-width: 700px;
}

.inner-container._640px {
  max-width: 640px;
}

.inner-container._680px {
  max-width: 680px;
  max-width: 680px;
}

.inner-container._385px {
  max-width: 385px;
}

.inner-container._515px {
  max-width: 515px;
}

.inner-container._475px {
  max-width: 475px;
}

.inner-container._670px {
  max-width: 670px;
}

.inner-container._545px {
  max-width: 545px;
}

.inner-container._470px {
  max-width: 470px;
}

.inner-container._540px {
  max-width: 540px;
}

.inner-container._530px {
  max-width: 530px;
}

.inner-container._660px {
  max-width: 660px;
}

.inner-container._780px {
  max-width: 780px;
}

.inner-container._445px {
  max-width: 445px;
}

.inner-container._585px {
  max-width: 585px;
}

.inner-container._880px {
  max-width: 880px;
}

.inner-container._455px {
  max-width: 455px;
}

.inner-container._625px {
  max-width: 625px;
}

.inner-container._650px {
  max-width: 650px;
}

.inner-container._430px {
  max-width: 430px;
}

.inner-container._560px {
  max-width: 560px;
}

.inner-container._820px {
  max-width: 820px;
}

.inner-container._485px {
  max-width: 485px;
}

.inner-container._450px {
  max-width: 450px;
}

.inner-container._388px {
  max-width: 388px;
}

.inner-container._626px {
  max-width: 626px;
}

.inner-container._500px---tablet.grow {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.inner-container._457px {
  max-width: 457px;
}

.inner-container._1030px {
  max-width: 1030px;
}

.inner-container._685px {
  max-width: 685px;
}

.style---content-heading {
  background-color: #141414;
  border-radius: 24px;
  align-items: center;
  margin-bottom: 60px;
  padding: 48px;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
}

.style---heading-icon-wrapper {
  background-color: #060606;
  border-radius: 24px;
  margin-right: 18px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.style---content-wrapper {
  padding-top: 40px;
  padding-left: 4vw;
  padding-right: 4vw;
}

.border-radius-16px {
  border-radius: 16px;
}

.style---card-wrapper {
  background-color: #141414;
  border: 1px solid #232323;
  border-radius: 18px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, .06);
}

.style---components-wrapper {
  background-color: #141414;
  border-radius: 24px;
  overflow: hidden;
}

.style---bg-white {
  background-color: #141414;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 48px;
}

.style---bg-white.bottom {
  border-radius: 0 0 24px 24px;
}

.style---bg-white.bottom.bg-accent-1 {
  background-color: #b0f127;
}

.style-buttons-dark {
  background-color: #060606;
  border: 1px solid #141414;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 48px;
}

.style-buttons-dark.top {
  border-radius: 24px 24px 0 0;
}

.btn-primary {
  color: #060606;
  text-align: center;
  transform-style: preserve-3d;
  background-color: #b0f127;
  border: 1px solid #b0f127;
  border-radius: 48px;
  padding: 20px 38px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.111em;
  text-decoration: none;
  transition: border-color .3s, background-color .3s, transform .3s, color .3s;
}

.btn-primary:hover {
  color: #060606;
  background-color: #547907;
  border-color: #547907;
  transform: translate3d(0, -3px, .01px);
}

.btn-primary.small {
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-primary.small.header-btn-hidde-on-mb {
  white-space: nowrap;
}

.btn-primary.small.discount {
  height: auto;
  justify-content: center;
  margin-left: 0;
}

.btn-primary.large {
  padding: 28px 56px;
  font-size: 20px;
  line-height: 1.1em;
}

.btn-primary.color-neutral-800 {
  color: #b0f127;
  background-color: #060606;
  border-color: #060606;
}

.btn-primary.inside-input {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.btn-primary.inside-input.default {
  padding: 16px 24px;
  top: 8px;
  bottom: 8px;
  right: 8px;
}

.btn-primary.button-row {
  margin-right: 24px;
}

.btn-primary.width-100.add-to-cart {
  justify-content: center;
}

.btn-primary.download-app {
  color: #b0f127;
  white-space: nowrap;
  background-color: #060606;
  border-color: #060606;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-primary.download-app:hover {
  background-color: #232323;
}

.btn-primary.download-app.white {
  color: #060606;
  background-color: #b0f127;
}

.btn-primary.download-app.white:hover {
  background-color: #71a407;
  border-color: #71a407;
}

.btn-primary.download-app.button-row._16px {
  margin-right: 16px;
}

.btn-primary.cta-button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-primary.wide {
  padding-left: 86px;
  padding-right: 86px;
}

.btn-primary.webflow-button {
  color: #4253ff;
  background-color: #fff;
  border-color: #fff;
}

.btn-primary.webflow-button:hover {
  transform: none;
}

.btn-primary.webflow-button.white {
  color: #fff;
  background-color: #553ffe;
  border-color: #553ffe;
}

.btn-primary.pagination-btn {
  margin-top: 64px;
}

.btn-secondary {
  color: #fff;
  text-align: center;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 48px;
  padding: 20px 38px;
  font-size: 18px;
  line-height: 1.111em;
  text-decoration: none;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
  box-shadow: 0 4px 10px rgba(20, 20, 43, .04);
}

.btn-secondary:hover {
  color: #060606;
  background-color: #b0f127;
  border-color: #b0f127;
  transform: translate3d(0, -3px, .01px);
}

.btn-secondary.small {
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-secondary.large {
  padding: 28px 56px;
  font-size: 20px;
  line-height: 1.1em;
}

.btn-secondary.white {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #2f2f2f;
}

.btn-secondary.white:hover {
  color: #060606;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary.width-100 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-secondary.width-100.add-to-cart {
  white-space: nowrap;
  margin-top: 0;
}

.btn-secondary.cta-button {
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.btn-secondary.sign-in-button {
  border-color: #2f2f2f;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-secondary.pagination-btn {
  margin-top: 64px;
}

.badge-primary {
  color: #060606;
  background-color: #b0f127;
  border-radius: 40px;
  padding: 18px 28px;
  font-size: 18px;
  line-height: 1.111em;
  display: inline-block;
}

.badge-primary.white {
  color: #b0f127;
  background-color: #2f2f2f;
}

.badge-primary.light {
  color: #fff;
  background-color: #71a407;
}

.badge-primary.small {
  padding: 14px 22px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-primary.small.product-popular {
  padding: 11px 21px;
  font-weight: 700;
}

.badge-primary.large {
  padding: 22px 38px;
  font-size: 20px;
  line-height: 1.1em;
}

.badge-secondary {
  color: #060606;
  text-align: center;
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 40px;
  padding: 18px 28px;
  font-size: 18px;
  line-height: 1.111em;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .06);
}

.badge-secondary.white {
  box-shadow: none;
  color: silver;
  background-color: rgba(0, 0, 0, 0);
  border-color: #939393;
}

.badge-secondary.white.category-badges:hover {
  background-color: rgba(47, 47, 47, .3);
  border-color: rgba(47, 47, 47, .3);
}

.badge-secondary.white.category-badges.w--current {
  color: #060606;
  background-color: #b0f127;
  border-color: #b0f127;
  font-weight: 700;
}

.badge-secondary.light {
  box-shadow: none;
  color: #71a407;
  background-color: #fff;
  border-width: 0;
}

.badge-secondary.small {
  padding: 14px 22px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-secondary.large {
  padding: 22px 38px;
  font-size: 20px;
  line-height: 1.1em;
}

.badge-secondary.category-badges {
  transition: border-color .3s, background-color .3s, color .3s;
}

.badge-secondary.category-badges:hover, .badge-secondary.category-badges.w--current {
  color: #fff;
  background-color: #b0f127;
  border-color: #b0f127;
}

.input {
  min-height: 72px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f2f2f;
  margin-bottom: 0;
  padding: 16px 24px;
  font-size: 18px;
  line-height: 1.111em;
  transition: color .3s, border-color .3s;
  transform: translate(0);
}

.input:hover {
  border-color: #b0f127;
}

.input:focus, .input.-wfp-focus {
  color: #fff;
  border-color: #b0f127;
}

.input::-ms-input-placeholder {
  color: silver;
}

.input::placeholder {
  color: silver;
}

.input.small {
  min-height: 60px;
  margin-bottom: 0;
  font-size: 16px;
}

.input.small::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.small::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.large {
  min-height: 80px;
  font-size: 20px;
}

.input.large.button-inside {
  padding-right: 39%;
}

.input.large.button-inside::-ms-input-placeholder {
  color: silver;
}

.input.large.button-inside::placeholder {
  color: silver;
}

.input.large.button-inside.newsletter {
  color: #060606;
  background-color: #b0f127;
  border-color: #2f2f2f;
  border-radius: 100px;
  transition: box-shadow .3s, color .3s, border-color .3s;
}

.input.large.button-inside.newsletter:hover {
  border-color: rgba(47, 47, 47, .6);
}

.input.large.button-inside.newsletter::-ms-input-placeholder {
  color: #060606;
}

.input.large.button-inside.newsletter::placeholder {
  color: #060606;
}

.input.large.button-inside.rounded {
  border-radius: 100px;
}

.input.mg-bottom-16px {
  margin-bottom: 16px;
}

.input.quntity {
  width: 110px;
}

.input.medium {
  min-height: 56px;
  margin-bottom: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.input.medium::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium.last {
  margin-bottom: 0;
}

.input.input-form {
  color: #fff;
  border-color: #2f2f2f;
}

.input.input-form:hover, .input.input-form:focus {
  border-width: 1px;
  border-color: #b0f127;
}

.input.input-form::-ms-input-placeholder {
  color: silver;
}

.input.input-form::placeholder {
  color: silver;
}

.input.input-form.left {
  border-top-color: rgba(0, 0, 0, 0);
}

.input.input-form.left:hover, .input.input-form.left:focus {
  border-color: #b0f127;
}

.input.input-form.top {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.input.input-form.top:hover, .input.input-form.top:focus {
  border-width: 1px;
  border-color: #b0f127;
}

.input.input-form.bottom {
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}

.input.input-form.bottom:hover, .input.input-form.bottom:focus {
  border-width: 1px;
  border-color: #b0f127;
}

.input.input-form.top-left {
  border-top-left-radius: 18px;
}

.input.input-form.top-left:hover, .input.input-form.top-left:focus {
  border-width: 1px;
  border-color: #b0f127;
}

.input.input-form.top-right {
  border-top-right-radius: 18px;
}

.input.input-form.top-right:hover, .input.input-form.top-right:focus {
  border-color: #b0f127;
}

.input.input-form.bottom-left {
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-left-radius: 18px;
}

.input.input-form.bottom-left:hover, .input.input-form.bottom-left:focus {
  border-color: #b0f127;
}

.input.input-form.bottom-right {
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-right-radius: 18px;
}

.input.input-form.bottom-right:hover, .input.input-form.bottom-right:focus {
  border-color: #b0f127;
}

.input.input-form.right {
  border-top-color: rgba(0, 0, 0, 0);
}

.input.input-form.right:hover, .input.input-form.right:focus {
  border-color: #b0f127;
}

.input.input-form.center {
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.input.input-form.center:hover, .input.input-form.center:focus {
  border-color: #b0f127;
}

.input.alt {
  border-radius: 100px;
}

.input.alt.select-wrapper {
  align-items: center;
  transition: border-color .3s;
  display: flex;
}

.input.select-inside {
  height: auto;
  min-height: auto;
  color: silver;
  border-style: none;
  padding: 0;
  transition: color .3s;
}

.input.select-inside:focus {
  color: #fff;
}

.position-relative {
  position: relative;
}

.position-relative.sign-up-image-wrapper {
  width: 100%;
  max-width: 558px;
  min-width: 500px;
  align-items: center;
  display: flex;
}

.text-area {
  max-height: 200px;
  max-width: 100%;
  min-height: 188px;
  min-width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f2f2f;
  padding: 16px 24px;
  font-size: 18px;
  transition: color .3s, border-color .3s;
}

.text-area:hover {
  border-color: #b0f127;
}

.text-area:focus {
  color: #fff;
  border-color: #b0f127;
}

.text-area::-ms-input-placeholder {
  color: silver;
}

.text-area::placeholder {
  color: silver;
}

.text-area.small {
  min-height: 102px;
  margin-bottom: 24px;
  font-size: 16px;
}

.text-area.small::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.small::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.input-form {
  color: #fff;
  border-width: 1px;
  border-color: #2f2f2f;
  border-radius: 0 0 18px 18px;
  margin-bottom: 0;
}

.text-area.input-form:hover, .text-area.input-form:focus {
  border-color: #b0f127;
}

.text-area.input-form::-ms-input-placeholder {
  color: silver;
}

.text-area.input-form::placeholder {
  color: silver;
}

.text-area.input-form.bottom {
  border-top-color: rgba(0, 0, 0, 0);
}

.text-area.input-form.bottom:hover, .text-area.input-form.bottom:focus {
  border-color: #b0f127;
}

.checkbox-field-wrapper {
  align-items: flex-start;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.667em;
  display: flex;
}

.checkbox-field-wrapper.large {
  font-size: 20px;
  line-height: 24px;
}

.checkbox-field-wrapper.small {
  font-size: 16px;
  line-height: 20px;
}

.checkbox {
  width: auto;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  background-color: #fff;
  border-width: 1px;
  border-color: silver;
  border-radius: 50%;
  margin-left: 0;
  margin-right: 12px;
  transition: border-color .3s, background-color .3s;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .06);
}

.checkbox:hover {
  border-color: #b0f127;
}

.checkbox.w--redirected-checked {
  color: #060606;
  background-color: #b0f127;
  background-image: url('../images/check-form-icon-finantech-x-webflow-template.svg');
  background-size: auto;
  border-color: #b0f127;
  border-radius: 50%;
  margin-left: 0;
}

.checkbox.w--redirected-focus {
  box-shadow: none;
  border-radius: 50%;
}

.checkbox.large {
  min-height: 28px;
  min-width: 28px;
}

.checkbox.small {
  min-height: 20px;
  min-width: 20px;
}

.radio-button-field-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 24px;
  display: flex;
}

.radio-button-field-wrapper.large {
  font-size: 20px;
  line-height: 26px;
}

.radio-button-field-wrapper.small {
  font-size: 16px;
  line-height: 22px;
}

.radio-button {
  width: auto;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  background-color: #fff;
  border-color: silver;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  transition: border-color .3s, border-width .3s, background-color .3s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .06);
}

.radio-button:hover {
  border-color: #b0f127;
}

.radio-button.w--redirected-checked {
  border-width: 6px;
  border-color: #b0f127;
}

.radio-button.w--redirected-focus {
  box-shadow: none;
}

.radio-button.large {
  min-height: 28px;
  min-width: 28px;
}

.radio-button.large.w--redirected-checked {
  border-width: 8px;
}

.radio-button.small {
  min-height: 20px;
  min-width: 20px;
}

.style---avatars-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 290px);
}

.avatar-circle {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(0);
}

.avatar-circle._01 {
  width: 32px;
  height: 32px;
  max-height: 32px;
  max-width: 32px;
}

.avatar-circle._02 {
  width: 48px;
  height: 48px;
  max-height: 48px;
  max-width: 48px;
}

.avatar-circle._03 {
  width: 56px;
  height: 56px;
  max-height: 56px;
  max-width: 56px;
}

.avatar-circle._04 {
  width: 80px;
  height: 80px;
  max-height: 80px;
  max-width: 80px;
}

.avatar-circle._05 {
  width: 120px;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
}

.avatar-circle._06 {
  width: 160px;
  height: 160px;
  max-height: 160px;
  max-width: 160px;
}

.avatar-circle._07 {
  width: 240px;
  height: 240px;
  max-height: 240px;
  max-width: 240px;
}

.avatar-circle._08 {
  width: 380px;
  height: 380px;
  max-height: 380px;
  max-width: 380px;
}

.style---icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 72px);
}

.square-icon {
  border-radius: 16px;
}

.style---social-square-icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 36px);
}

.social-icon-font {
  font-family: Social Icons Font, sans-serif;
  line-height: 21px;
}

.social-icon-font.button-left {
  margin-right: 6px;
}

.social-icon-font.button-right {
  margin-left: 6px;
  font-weight: 400;
}

.social-icon-font.button-icon-left {
  margin-right: 12px;
  font-size: 24px;
  line-height: 1em;
}

.social-icon-square {
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  background-color: #060606;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: flex;
}

.social-icon-square:hover {
  color: #060606;
  background-color: #b0f127;
}

.social-icon-square.size-24px {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 8px;
  font-size: 14px;
}

.social-icon-square.size-10px {
  width: 10px;
}

.social-icon-square.white {
  color: #060606;
  background-color: #fff;
}

.social-icon-square.white:hover {
  background-color: #b0f127;
}

.line-rounded-icon {
  font-family: Line Rounded Icons, sans-serif;
}

.line-rounded-icon.style---dropdown-arrow-sidebar {
  line-height: 18px;
}

.line-rounded-icon.link-icon-right {
  margin-left: 6px;
  display: inline-block;
}

.line-rounded-icon.link-icon-left {
  margin-right: 6px;
  display: inline-block;
}

.line-rounded-icon.dropdown-arrow {
  margin-left: 6px;
}

.line-rounded-icon.success-message-check {
  margin-bottom: 6px;
  font-size: 22px;
}

.line-rounded-icon.success-message-check.large {
  color: #b0f127;
  margin-bottom: 16px;
  font-size: 80px;
  line-height: 88px;
}

.style---line-icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 20px);
}

.line-square-icon {
  font-family: Line Square Icons, sans-serif;
}

.line-square-icon.top-bar-close-icon {
  cursor: pointer;
  align-items: center;
  padding: 20px 32px;
  font-size: 24px;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.line-square-icon.top-bar-close-icon:hover {
  opacity: .65;
}

.filled-icons {
  font-family: Filled Icons, sans-serif;
}

.rich-text img {
  border-radius: 24px;
}

.rich-text p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text h2 {
  margin-bottom: 16px;
}

.rich-text h3 {
  margin-top: 48px;
  margin-bottom: 16px;
}

.rich-text figure {
  margin-top: 64px;
  margin-bottom: 64px;
}

.rich-text ul, .rich-text ol {
  margin-top: 24px;
  margin-bottom: 48px;
}

.rich-text h6, .rich-text h5, .rich-text h4 {
  margin-top: 48px;
  margin-bottom: 16px;
}

.rich-text blockquote {
  margin-top: 48px;
  margin-bottom: 48px;
}

.style---dropdown-wrapper-sidebar {
  width: 100%;
  overflow: hidden;
}

.style---dropdown-toggle {
  width: 100%;
  color: #e7e7e7;
  border-bottom: 1px solid #2f2f2f;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  transition: color .3s;
  display: flex;
}

.style---dropdown-toggle:hover, .style---dropdown-toggle.w--open {
  color: #b0f127;
}

.buttons-row {
  align-items: center;
  display: flex;
}

.buttons-row.center {
  justify-content: center;
}

.buttons-row.center.testimonial-button {
  position: absolute;
  top: auto;
  bottom: 90px;
  left: 0%;
  right: 0%;
}

.buttons-row.add-to-cart-btns {
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}

.style---dropdown-list-sidebar {
  background-color: #060606;
  border-bottom: 1px solid #2f2f2f;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.style---dropdown-link-sidebar {
  color: silver;
  border-right: 4px solid rgba(0, 0, 0, 0);
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 20px;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  transition: border-color .3s, background-color .3s, color .3s;
  display: flex;
}

.style---dropdown-link-sidebar:hover {
  color: #e7e7e7;
  background-color: rgba(47, 47, 47, .3);
  border-right-color: #b0f127;
  font-weight: 500;
}

.style---dropdown-link-sidebar.w--current {
  color: #060606;
  background-color: #b0f127;
  border-right-color: #b0f127;
  font-weight: 500;
}

.style---dropdown-link-icon-sidebar {
  max-width: 38px;
  background-color: #2f2f2f;
  border-radius: 8px;
  margin-right: 10px;
  padding: 2px;
}

.style---sidebar-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  overflow: scroll;
}

.style---logo-wrapper {
  width: 100%;
  color: #717172;
  text-align: center;
  background-color: #060606;
  border-bottom: 1px solid #2f2f2f;
  padding: 38px 22px;
  text-decoration: none;
}

.width-100 {
  width: 100%;
}

.style---button-wrapper-sidebar {
  margin-top: 20px;
  margin-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.style---logo-sidebar {
  transition: transform .3s;
}

.style---logo-sidebar:hover {
  transform: translate(0, -4px);
}

.container-default {
  max-width: 1332px;
  padding-left: 24px;
  padding-right: 24px;
}

.container-default.z-index-1 {
  z-index: 1;
  position: relative;
}

.container-default.order-confirmation {
  display: block;
}

.container-default.small {
  max-width: 1182px;
}

.elements---page-hero {
  background-color: #141414;
  padding-top: 240px;
  padding-bottom: 120px;
}

.section {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section.small {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.large {
  padding-top: 240px;
/*  padding-bottom: 240px;*/
}

.section.large.bottom-120px {
  padding-bottom: 120px;
}

.section.large.bottom-120px.border-bottom-neutral-700 {
  border-bottom: 1px solid #2f2f2f;
}

.section.large.top-120px {
  padding-top: 120px;
}

.section.large.coming-soon-section {
  min-height: 70vh;
  background-image: linear-gradient(#060606 31%, rgba(6, 6, 6, 0)), url('../images/bg-coming-soon-finantech-webflow-template.svg');
  background-position: 0 0, 50% 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.section.top {
  padding-top: 230px;
}

.section.top.bottom-0px {
  padding-bottom: 0;
}

.section.template-pages-hero {
  background-color: #060606;
  border-bottom: 1px solid #2f2f2f;
  padding-top: 230px;
  padding-bottom: 148px;
}

.section.template-pages-hero.short {
  padding-bottom: 100px;
}

.section.hero-page {
  background-image: linear-gradient(#141414 50%, #2f2f2f 50%);
  padding-top: 230px;
  padding-bottom: 200px;
  overflow: hidden;
}

.section.cta-home-pages {
  background-image: linear-gradient(#141414 47%, #060606 47%);
  padding-top: 0;
}

.section.hero-v1 {
  padding-top: 10%;
  padding-bottom: 80px;
  overflow: hidden;
}

.section.medium {
/*  padding-top: 140px;*/
  padding-bottom: 140px;
}

.section.medium.bg-secondary-3.border-bottom {
  border-bottom: 1px solid #2f2f2f;
}

.section.medium.top {
  padding-top: 230px;
}

.section.medium.top.hero-bg {
  background-image: url('../images/bg-career-single-finantech-webflow-template.svg');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.section.medium.top-0px {
  padding-top: 0;
}

.section.integrations-section {
  background-color: #141414;
/*  padding-top: 240px;*/
  padding-bottom: 240px;
  position: relative;
  overflow: hidden;
}

.section.bottom-80px {
  padding-bottom: 80px;
}

.section._180px {
  padding-top: 180px;
  padding-bottom: 180px;
}

.section.hero-images {
  background-image: linear-gradient(to right, #060606 25%, rgba(6, 6, 6, 0)), url('../images/bg-integrations-finantech-webflow-template.svg');
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  padding-top: 168px;
  padding-bottom: 22px;
  position: relative;
  overflow: hidden;
}

.section.integration-single-hero {
  background-image: linear-gradient(#060606 38%, #141414 38%);
  padding-top: 230px;
  padding-bottom: 140px;
}

.section._100px {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.coming-soon-section {
  padding-top: 240px;
  padding-bottom: 240px;
}

.section.pd-128px {
  padding-top: 128px;
  padding-bottom: 128px;
}

.section.pd-top-180px {
  padding-top: 180px;
}

.section.hero-v2 {
  padding-top: 169px;
  padding-bottom: 0;
  overflow: hidden;
}

.section.bg-default {
  background-image: linear-gradient(#060606 20%, rgba(6, 6, 6, 0)), url('../images/bg-coming-soon-finantech-webflow-template.svg');
  background-position: 0 0, 50% 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

.top-bar-wrapper {
  color: #060606;
  text-align: center;
  background-color: #b0f127;
  padding-left: 72px;
  padding-right: 72px;
  position: relative;
  overflow: hidden;
}

.top-bar-padding {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.top-bar-padding.small-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}

.top-bar-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.top-bar-flex.space-between {
  justify-content: space-between;
}

.link-wrapper {
  color: #fff;
  text-decoration: none;
  transition: color .3s;
}

.link-wrapper:hover, .link-wrapper.color-primary {
  color: #b0f127;
}

.link-wrapper.color-primary:hover {
  color: #fff;
}

.link-wrapper.color-neutral-800 {
  color: #060606;
}

.link-wrapper.highlighted {
  max-width: 175px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  line-height: 1.3em;
  display: flex;
}

.link-wrapper.text-200.text-bold.link-icon-left {
  white-space: nowrap;
}

.link-text {
  transition: none;
  display: inline-block;
}

.link-text.mg-right-5px {
  margin-right: 5px;
}

.link-text.no-hover {
  transition: none;
}

.link-text.no-hover:hover {
  color: #fff;
}

.elements---element-title {
  background-color: #141414;
  border: 1px solid #141414;
  border-radius: 12px;
  margin-top: 72px;
  margin-bottom: 32px;
  padding: 24px 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .06);
}

.elements---element-title.first, .elements---element-title.first.large {
  margin-top: 0;
}

.elements---element-title.large {
  margin-top: 266px;
  margin-bottom: 54px;
}

.heading-h1-size {
  color: #fff;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.222em;
}

.heading-h2-size {
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.316em;
}

.heading-h3-size {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.417em;
}

.heading-h4-size {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.273em;
}

.heading-h5-size {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.333em;
}

.heading-h6-size {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.375em;
}

.header-wrapper {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 32px;
  padding-bottom: 32px;
}

.header-wrapper.v1 {
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.header-content-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-nav-menu-list {
  z-index: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.header-nav-link {
  color: #fff;
  padding: 0;
}

.header-nav-link:hover {
  color: #b0f127;
}

.header-nav-link.w--current {
  color: #fff;
  font-weight: 700;
}

.header-nav-list-item {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 32px;
}

.header-nav-list-item.left {
  padding-left: 0;
  padding-right: 32px;
}

.header-nav-list-item.show-in-mobile {
  display: none;
}

.dropdown-toggle {
  color: #fff;
  align-items: center;
  padding: 0;
  display: flex;
}

.dropdown-toggle:hover {
  color: #b0f127;
}

.dropdown-column-wrapper {
  width: 640px;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  transform: translate(-50%);
}

.dropdown-link {
  width: 100%;
  color: silver;
  padding: 0;
  line-height: 20px;
}

.dropdown-link:hover {
  color: #b0f127;
}

.dropdown-pd {
  background-color: #060606;
  border: 1px solid #2f2f2f;
  border-radius: 24px;
  margin-top: 16px;
  padding: 56px 58px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
}

.header-logo {
  width: 100%;
}

.header-logo-link {
  width: 100%;
  max-width: 241px;
  margin-right: 20px;
  padding-left: 0;
  transition: transform .3s, color .3s;
}

.header-logo-link:hover {
  transform: translate(0, -4px);
}

.header-logo-link.left {
  margin-right: 40px;
}

.header-logo-link.center {
  margin-right: 0;
}

.hamburger-menu-wrapper {
  padding: 0;
}

.hamburger-menu-wrapper.w--open {
  background-color: rgba(0, 0, 0, 0);
}

.hamburger-menu-bar {
  width: 52px;
  height: 4px;
  background-color: #060606;
  border-radius: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.header-right-side {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header-right-side.wrap {
  grid-row-gap: 15px;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.header-middle {
  margin-left: auto;
  margin-right: auto;
}

.header-left-side {
  align-items: center;
  display: flex;
}

.btn-circle-primary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  color: #fff;
  background-color: #b0f127;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  text-decoration: none;
  transition: transform .3s, background-color .3s, color .3s;
  display: flex;
}

.btn-circle-primary:hover {
  color: #fff;
  background-color: #547907;
  transform: translate(0, -4px);
}

.btn-circle-primary.small {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  font-size: 24px;
}

.btn-circle-primary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 36px;
}

.btn-circle-primary.white {
  color: #b0f127;
  background-color: #fff;
}

.btn-circle-secondary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  color: #060606;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  text-decoration: none;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
  display: flex;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
}

.btn-circle-secondary:hover {
  color: #fff;
  background-color: #b0f127;
  border-color: #b0f127;
  transform: translate(0, -4px);
}

.btn-circle-secondary.small {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  font-size: 24px;
}

.btn-circle-secondary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 36px;
}

.btn-circle-secondary.white {
  box-shadow: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}

.btn-circle-secondary.white:hover {
  background-color: #b0f127;
  border-color: #b0f127;
}

.utility-page-wrap {
  min-height: 80vh;
  background-color: #141414;
  background-image: linear-gradient(#141414 20%, rgba(20, 20, 20, 0)), url('../images/bg-coming-soon-finantech-webflow-template.svg');
  background-position: 0 0, 50% 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  justify-content: center;
  align-items: center;
  padding-top: 250px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
}

.utility-page-wrap._404 {
  padding-top: 250px;
  padding-bottom: 180px;
}

.utility-page-wrap.password {
  grid-template-rows: auto;
  grid-template-columns: 1fr .8fr;
  grid-auto-columns: 1fr;
  padding-bottom: 200px;
  overflow: hidden;
}

.utility-page-content {
  width: 100%;
  max-width: 700px;
  text-align: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.utility-page-content.password {
  max-width: 1332px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.utility-page-form.password-form-wrapper {
  z-index: 1;
  width: 100%;
  max-width: 626px;
  position: relative;
}

.hidden-on-desktop {
  display: none;
}

.flex-vertical {
  flex-direction: column;
  display: flex;
}

.flex-vertical.center {
  align-items: center;
}

.flex-horizontal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.start {
  justify-content: flex-start;
}

.flex-horizontal.flex-wrap {
  flex-wrap: wrap;
}

.flex-horizontal.title-wrap {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.footer-wrapper {
  background-color: #060606;
  border-top: 1px solid #2f2f2f;
  position: relative;
}

.footer-logo {
  width: 100%;
}

.footer-list-wrapper {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.footer-list-item {
  margin-bottom: 18px;
  padding-left: 0;
  line-height: 1.111em;
}

.footer-list-item.last {
  margin-bottom: 0;
}

.footer-link {
  color: silver;
  text-decoration: none;
}

.footer-link:hover {
  color: #b0f127;
}

.footer-top {
  padding-top: 80px;
  padding-bottom: 80px;
}

.footer-bottom {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-bottom.text-right.text-center---tablet.pd-48px {
  padding-top: 48px;
  padding-bottom: 48px;
}

.footer-middle {
  padding-top: 120px;
  padding-bottom: 120px;
}

.footer-middle.v2 {
  padding-top: 0;
  padding-bottom: 80px;
}

.success-message {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.success-message.white-message {
  color: #b0f127;
  background-color: #fff;
}

.success-message.white-message.newsletter {
  color: #060606;
  background-color: #b0f127;
  border: 1px solid #060606;
  border-radius: 90px;
  flex: 1;
  padding: 21px 10px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.success-message.contact-form {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border: 1px solid #2f2f2f;
  border-radius: 18px;
  flex-direction: column;
  flex: 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.success-message.coming-soon-state {
  color: #fff;
  text-align: left;
  background-color: #060606;
  border: 1px solid #2f2f2f;
  border-radius: 90px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.error-message {
  color: #ff5a65;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 15px;
  padding: 0;
}

.error-message.cart-error {
  text-align: center;
  margin-top: 0;
}

.grid-footer-logo---paragraph {
  grid-template-rows: auto;
  grid-template-columns: .3fr 1fr;
  align-items: center;
}

.text-right {
  text-align: right;
}

.social-media-link-wrapper {
  color: #b0f127;
  text-align: left;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.social-media-link-wrapper:hover {
  color: #b0f127;
}

.social-media-link-text {
  color: silver;
  margin-left: 8px;
  transition: color .3s;
}

.social-media-link-text:hover {
  color: #b0f127;
}

.text-center {
  text-align: center;
}

.text-center.tablet {
  text-align: left;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-top._48px-top {
  top: 48px;
}

.opacity-90 {
  opacity: .9;
}

.icon-list-item-wrapper {
  align-items: center;
  display: flex;
}

.icon-list {
  margin-right: 14px;
}

.fit-cover {
  object-fit: cover;
}

.play-button-large {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  border-radius: 50%;
  transition: transform .3s;
}

.play-button-large:hover {
  transform: scale3d(.94, .94, 1.01);
}

.bg-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.overflow-hidden {
  overflow: hidden;
}

.video-bg-image {
  height: 100%;
  filter: blur(2px);
  object-fit: cover;
}

.lightbox-video-icon-wrapper {
  min-height: 125px;
  min-width: 125px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 8px 20px rgba(0, 0, 0, .4);
}

.cta-section {
  background-color: #b0f127;
  padding-top: 160px;
  padding-bottom: 160px;
  position: relative;
  overflow: hidden;
}

.cta-section.cta-v1 {
  padding-top: 0;
  padding-bottom: 0;
}

.cta-section.cta-section-form{
  padding-top: 80px;
  padding-bottom: 80px;
}

.cta-section.v2 {
  background-color: #141414;
  background-image: linear-gradient(to right, #141414 43%, rgba(20, 20, 20, 0)), url('../images/bg-cta-v2-finantech-x-webflow-template.svg');
  background-position: 0 0, 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, auto;
  padding-top: 200px;
  padding-bottom: 200px;
}

.grid-6-columns {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-6-columns.logo-strip {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.slider-mask {
  height: 100%;
  overflow: visible;
}

.accordion-item-wrapper {
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  padding: 48px 58px;
  display: flex;
}

.accordion-item-wrapper.main {
  border-bottom: 1px solid #2f2f2f;
  margin-bottom: -1px;
}

.accordion-item-wrapper.main:hover {
  transform: none;
}

.accordion-item-wrapper.main.first {
  padding-top: 0;
}

.accordion-item-wrapper.main.last {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.accordion-header {
  align-items: center;
  display: flex;
}

.acordion-body {
  width: 100%;
  max-width: 510px;
  overflow: hidden;
}

.accordion-spacer {
  min-height: 24px;
}

.accordion-btn-line {
  width: 18px;
  height: 2px;
  background-color: #060606;
  border-radius: 20px;
  position: absolute;
}

.accordion-btn-line.vertical {
  transform: rotate(90deg);
}

.accordion-btn-line.v2 {
  width: 24px;
}

.accordion-btn-line.v2.vertical, .accordion-btn-line.v2.horizontal {
  background-color: #b0f127;
}

.accordion-content-wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.accordion-side {
  margin-right: 24px;
}

.accordion-side.right-side {
  margin-left: 24px;
  margin-right: 0;
}

.accordion-icon-wrapper {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.accordion-title {
  margin-bottom: 0;
}

.accordion-big-number-wrapper {
  display: flex;
}

.empty-state {
  color: #717172;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f2f2f;
  border-radius: 11px;
  padding: 26px 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
}

.empty-state.small {
  border-radius: 8px;
  padding: 15px 20px;
}

.empty-state.card-inside {
  border-left-style: none;
  border-right-style: none;
  border-radius: 0;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-left: 0;
  padding-right: 0;
}

.empty-state.cart-empty {
  box-shadow: none;
  border-style: none;
  border-radius: 0;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
}

.pd---content-inside-card {
  padding: 40px 28px 48px;
}

.pd---content-inside-card.large {
  max-width: 625px;
  text-align: left;
  background-color: #060606;
  padding: 148px 46px;
}

.pd---content-inside-card.large.template-pages {
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 120px;
}

.pd---content-inside-card.template-pages---sidebar {
  padding: 32px 16px 24px;
}

.team-member-wrapper {
  height: 100%;
  text-decoration: none;
  transition: transform .3s, color .3s;
  position: relative;
  overflow: hidden;
}

.team-member-content {
  z-index: 1;
  max-width: 347px;
  grid-column-gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 48px;
  left: 24px;
  right: 24px;
}

.bg-overlay-gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
}

.bg-overlay-gradient.team-member-v8 {
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 30%, #060606);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.gap-0 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.image {
  width: 100%;
  height: 100%;
}

.image.fit-cover.left, .image.fit-cover.figma-file-image {
  object-position: 0% 50%;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.blog-card-featured-wrapper {
  width: 100%;
  color: silver;
  text-decoration: none;
}

.blog-card-featured-wrapper:hover {
  color: silver;
}

.blog-card-featured-wrapper.card {
  border-radius: 24px;
}

.blog-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-image.featured {
  object-position: 20% 50%;
}

.blog-card-image-wrapper {
  border-radius: 24px;
  overflow: hidden;
  transform: translate(0);
}

.blog-card-image-wrapper.featured-v2 {
  background-color: #141414;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-card-image-wrapper.inside-card {
  object-fit: fill;
  border-radius: 18px 18px 0 0;
  position: relative;
}

.categories-badges-item-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 15px;
  flex-flow: wrap;
  display: flex;
}

.categories-badges-item-wrapper.center {
  justify-content: center;
}

.categories-badges-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 15px;
  flex-wrap: wrap;
  display: flex;
}

.categories-badges-wrapper.center {
  justify-content: center;
}

.blog-card-wrapper {
  max-width: 1010px;
  color: #717172;
  margin-left: auto;
  text-decoration: none;
}

.blog-card-wrapper.card {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.blog-card-featured-inner-content {
  border-left: 1px solid #2f2f2f;
  align-items: center;
  padding: 60px 34px;
  display: flex;
}

.blog-card-content-inside {
  border-top: 1px solid #2f2f2f;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 56px 59px 60px;
  display: flex;
}

.divider-details {
  width: 24px;
  height: 1px;
  background-color: #717172;
  margin-left: 0;
  margin-right: 0;
}

.divider-details._32px {
  width: 32px;
}

.image-wrapper {
  overflow: hidden;
}

.image-wrapper.footer-cta-image {
  max-width: 95%;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: -55px;
  display: flex;
}

.image-wrapper.hero-float-bottom-left {
  width: 22%;
  max-width: 199px;
  position: absolute;
  top: -13%;
  left: 38.9%;
}

.image-wrapper.hero-float-bottom-right {
  width: 15%;
  max-width: 123px;
  position: absolute;
  top: auto;
  bottom: 53%;
  left: auto;
  right: 10%;
}

.image-wrapper.hero-float-top-left {
  width: 11%;
  max-width: 90px;
  position: absolute;
  top: -16%;
  bottom: auto;
  left: auto;
  right: 24%;
}

.image-wrapper.hero-float-top-right {
  width: 15%;
  max-width: 123px;
  position: absolute;
  top: -30%;
  bottom: auto;
  left: auto;
  right: 8%;
}

.image-wrapper.wallet-right-image {
  z-index: 0;
  width: 58%;
  max-width: 342px;
  margin-left: auto;
  position: relative;
  overflow: visible;
}

.image-wrapper.wallet-left-image {
  width: 58%;
  max-width: 342px;
  border: 1px solid #2f2f2f;
  border-radius: 18px;
  position: absolute;
  top: 22%;
  left: 0%;
  overflow: hidden;
  box-shadow: 0 8px 66px rgba(0, 0, 0, .3);
}

.image-wrapper.wallet-bottom-left-image {
  width: 23%;
  max-width: 141px;
  position: absolute;
  top: auto;
  bottom: 11%;
  left: 25%;
  right: auto;
}

.image-wrapper.security-image-left {
  z-index: 0;
  width: 71.5%;
  position: relative;
  overflow: visible;
}

.image-wrapper.security-image-right {
  width: 40%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.image-wrapper.feature-image {
  width: 558px;
  max-width: 70%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.image-wrapper.cta-v1-top-image {
  width: 66%;
  margin-top: -10%;
  margin-bottom: -12%;
  margin-left: 5%;
}

.image-wrapper.cta-v1-bottom-image {
  width: 89%;
  position: absolute;
  top: 39%;
  left: 15%;
}

.image-wrapper.cta-v1-bottom-float {
  width: 19%;
  position: absolute;
  top: 70%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.image-wrapper.cta-v1-top-float {
  width: 14%;
  position: absolute;
  top: 13%;
  bottom: auto;
  left: auto;
  right: 10%;
}

.image-wrapper.cta-v1-middle-float {
  width: 22%;
  position: absolute;
  top: 30%;
  bottom: auto;
  left: auto;
  right: -7%;
}

.image-wrapper.cta-v2-image-wrapper {
  width: 53%;
  max-width: 788px;
  position: absolute;
  right: -5%;
  overflow: visible;
}

.image-wrapper.hero-bottom-left-float {
  width: 7%;
  position: absolute;
  top: auto;
  bottom: 11%;
  left: 10%;
  right: auto;
}

.image-wrapper.hero-top-left-float {
  width: 12%;
  position: absolute;
  top: auto;
  bottom: 37%;
  left: 28%;
  right: auto;
}

.image-wrapper.hero-top-right-float {
  width: 6%;
  position: absolute;
  top: auto;
  bottom: 62%;
  left: auto;
  right: 29%;
}

.image-wrapper.hero-bottom-right-float {
  width: 13%;
  position: absolute;
  top: auto;
  bottom: 12%;
  left: auto;
  right: 11%;
}

.image-wrapper.our-story-image {
  max-width: 695px;
}

.image-wrapper.blog-image-wrapper {
  background-color: #141414;
  border: 1px solid #2f2f2f;
  border-radius: 24px;
  overflow: hidden;
}

.image-wrapper.author-card-image {
  height: 100%;
}

.image-wrapper.hero-center-image {
  width: 100%;
  max-width: 66%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.image-wrapper.hero-back-image {
  width: 115%;
  position: absolute;
  top: -5%;
  left: -12%;
  right: auto;
}

.image-wrapper.hero-ahead-image {
  width: 96%;
  position: absolute;
  top: auto;
  bottom: 13%;
  left: auto;
  right: 1%;
}

.image-wrapper.product-image-wrapper {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 24px;
}

.image-wrapper.career-hero-float-left {
  width: 19%;
  max-width: 129px;
  position: absolute;
  top: 7%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.image-wrapper.career-hero-float-right {
  width: 12%;
  max-width: 85px;
  position: absolute;
  top: 44%;
  bottom: auto;
  left: auto;
  right: -11%;
}

.image-wrapper.coming-soon-top-left-float {
  width: 34%;
  max-width: 129px;
  position: absolute;
  top: -4%;
  bottom: auto;
  left: -10%;
  right: auto;
}

.image-wrapper.coming-soon-top-right-float {
  width: 37%;
  max-width: 142px;
  position: absolute;
  top: 8%;
  bottom: auto;
  left: auto;
  right: -38%;
}

.image-wrapper.coming-soon-bottom-left-float {
  width: 31%;
  max-width: 120px;
  position: absolute;
  top: auto;
  bottom: 8%;
  left: -45%;
  right: auto;
}

.image-wrapper.coming-soon-bottom-right {
  width: 20%;
  max-width: 75px;
  position: absolute;
  top: auto;
  bottom: 10%;
  left: auto;
  right: -28%;
}

.image-wrapper.sign-up-image {
  width: 158%;
  position: absolute;
  left: 0%;
}

.image-wrapper.sign-up-top-left-image {
  width: 25%;
  position: absolute;
  top: 11%;
  bottom: auto;
  left: -1%;
  right: auto;
}

.image-wrapper.sign-up-bottom-left {
  width: 18%;
  position: absolute;
  top: auto;
  bottom: 48%;
  left: auto;
  right: 3%;
}

.image-wrapper.sign-up-right-image {
  width: 18%;
  position: absolute;
  top: auto;
  bottom: 36%;
  left: 3%;
  right: auto;
}

.image-wrapper._404 {
  width: 74%;
  max-width: 525px;
  align-self: center;
  margin-bottom: -3%;
}

.image-wrapper.password-left-image {
  width: 48%;
  position: absolute;
  top: auto;
  bottom: 1%;
  left: -61%;
  right: auto;
}

.image-wrapper.password-right-image {
  width: 40%;
  position: absolute;
  top: 18%;
  bottom: auto;
  left: auto;
  right: -51%;
}

.image-wrapper.team-member-image {
  width: 100%;
  height: 100%;
}

.image-wrapper.hero-bottom-left-float-v2 {
  width: 5%;
  position: absolute;
  top: auto;
  bottom: 7%;
  left: 37%;
  right: auto;
}

.image-wrapper.section-featured-float-left {
  width: 14%;
  max-width: 98px;
  position: absolute;
  top: 51%;
  bottom: auto;
  left: -15%;
  right: auto;
}

.image-wrapper.section-featured-float-right {
  width: 16%;
  max-width: 107px;
  position: absolute;
  top: -4%;
  bottom: auto;
  left: auto;
  right: 20%;
}

.image-wrapper.order-list-image {
  max-width: 60px;
  border-radius: 8px;
}

.template-pages---sidebar-navigation {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.template-pages---nav-item-link {
  color: #e7e7e7;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: flex;
}

.template-pages---nav-item-link:hover {
  color: #e7e7e7;
  background-color: rgba(47, 47, 47, .3);
}

.template-pages---nav-item-link.w--current {
  color: #060606;
  background-color: #b0f127;
  font-weight: 700;
}

.template-pages---phography-wrapper {
  transform-style: preserve-3d;
  border-radius: 12px;
  transition: transform .3s, color .3s;
  overflow: hidden;
}

.template-pages---phography-wrapper:hover {
  transform: scale3d(.94, .94, 1.01);
}

.cart-button-wrapper {
  margin-right: 32px;
}

.cart-button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  transition: opacity .3s, color .3s;
  position: relative;
}

.cart-button:hover {
  color: #b0f127;
}

.cart-container {
  box-shadow: none;
  background-color: #141414;
  border-radius: 24px;
}

.cart-header {
  border-bottom-color: #2f2f2f;
  padding-top: 22px;
  padding-bottom: 22px;
}

.cart-list {
  padding: 24px 0;
}

.cart-footer {
  border-top-color: #2f2f2f;
  padding-top: 30px;
  padding-bottom: 40px;
}

.cart-item-title {
  color: #fff;
  margin-bottom: 6px;
  font-weight: 600;
  text-decoration: none;
}

.cart-item-title:hover {
  color: #b0f127;
}

.cart-item-wrapper {
  align-items: flex-start;
  display: flex;
}

.cart-quantity-input {
  background-color: rgba(0, 0, 0, 0);
  border-color: #2f2f2f;
  border-radius: 8px;
  transition: color .3s, border-color .3s;
}

.cart-quantity-input:hover {
  border-color: #b0f127;
}

.cart-quantity-input:focus {
  color: #fff;
  border-color: #b0f127;
}

.pay-btn {
  border-radius: 90px;
}

.pay-btn.cart {
  height: 60px;
}

.pay-btn.order-summary {
  height: 50px;
}

.cart-close-button {
  width: 24px;
  height: 24px;
  color: silver;
  justify-content: center;
  align-items: center;
  font-family: Line Rounded Icons, sans-serif;
  text-decoration: none;
  display: flex;
}

.cart-close-button:hover {
  color: #b0f127;
}

.checkout-form {
  min-height: auto;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.checkout-col-left {
  margin-right: 0;
}

.checkout-col-right {
  position: static;
}

.checkout-block-header {
  grid-column-gap: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0 0 1px;
  border-bottom-color: #2f2f2f;
  flex-wrap: wrap;
  padding: 0 0 20px;
}

.checkout-block-content {
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  padding: 30px 0 0;
}

.checkout-block-content.pd-top-0px {
  padding-top: 0;
}

.shipping-list {
  border: 0 solid #000;
}

.shipping-method {
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  border-bottom: 1px solid #2f2f2f;
  padding: 31px 0;
}

.shipping-method:last-child {
  border-bottom-style: none;
  padding-bottom: 0;
}

.shipping-price {
  color: #fff;
}

.order-item-list {
  margin-bottom: 0;
}

.order-item {
  border-bottom: 1px solid #2f2f2f;
  align-items: flex-start;
  margin-top: 18px;
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.order-item:last-child {
  border-bottom-style: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.order-list-price {
  color: #fff;
}

.order-list-title {
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.111em;
  text-decoration: none;
  display: inline-block;
}

.order-list-title:hover {
  color: #b0f127;
}

.order-option-item {
  padding-left: 0;
}

.order-item-result, .order-summary-price {
  color: #fff;
}

.discounts-wrapper {
  background-color: rgba(0, 0, 0, 0);
  border-width: 1px 0 0;
  border-top-color: #2f2f2f;
  margin-top: 32px;
  padding: 32px 0 0;
}

.text-underline {
  text-decoration: underline;
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.home-pages-cointainer {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  display: flex;
}

.home-pages-top-wrapper {
  justify-content: center;
  margin-bottom: 25px;
  display: flex;
  position: static;
}

.image-home-link-wrapper {
  max-width: 400px;
  border: 1px solid #2f2f2f;
  border-radius: 18px;
  flex: none;
  margin-left: 12px;
  margin-right: 12px;
  transition: transform .3s, color .3s;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .05);
}

.image-home-link-wrapper:hover {
  transform: translate(0, -4px);
}

.image-home-link {
  width: 100%;
  object-fit: cover;
}

.home-pages-bottom-wrapper {
  justify-content: center;
  display: flex;
  position: static;
  top: 50%;
}

.card-sales-home-image {
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.card-sales-home-content {
  text-align: center;
  background-color: #141414;
  border-top: 1px solid #2f2f2f;
  flex: 1;
  padding: 32px;
}

.figma-file-image-wrapper {
  width: 100%;
  max-width: 51%;
  border-left: 1px solid #2f2f2f;
}

.figma-file-text-wrapper {
  background-color: #141414;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 60px 30px 60px 56px;
  display: flex;
}

.badge-home {
  color: #211f54;
  background-color: #fff;
  border-radius: 50px;
  padding: 8px 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 20px;
  bottom: auto;
  left: auto;
  right: 20px;
}

.home-surprises-card {
  border: 1px solid #717172;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(20, 20, 43, .08);
}

.card-home-pages-wrapper {
  padding-top: 56px;
  padding-left: 56px;
  padding-right: 56px;
}

.cta-square-logo {
  border-radius: 22px;
  margin-bottom: 22px;
  box-shadow: 0 8px 28px rgba(20, 20, 43, .1);
}

.cta-square-logo.white {
  border: 0 solid #f4f4f4;
}

.mg-bottom-12px {
  margin-bottom: 12px;
}

.cta-image-card {
  width: 1140px;
  max-width: 1140px;
  margin-top: 52px;
  margin-bottom: -23px;
}

.nav-cart-quantity {
  height: auto;
  min-width: 0;
  color: #b0f127;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.111em;
}

.header-social-media-wrapper {
  grid-column-gap: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  display: flex;
}

.social-media-icon {
  color: #fff;
  font-family: Social Icons Font, sans-serif;
  font-size: 20px;
  line-height: 1em;
  text-decoration: none;
}

.social-media-icon:hover, .social-media-icon.color-accent-1 {
  color: #b0f127;
}

.social-media-icon.color-accent-1:hover {
  color: #fff;
}

.header-container {
  min-height: 120px;
  position: relative;
}

.grid-footer-v1---4-column {
  grid-column-gap: 60px;
  grid-row-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: 3.5fr auto auto 1fr;
}

.footer-cta-wrapper {
  max-width: 550px;
  background-color: #b0f127;
  border-radius: 18px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
}

.footer-title {
  color: #fff;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1em;
}

.footer-main-pages-wrapper {
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-main-pages-wrapper.v2 {
  grid-column-gap: 40px;
}

.footer-cta-content-top {
  z-index: 1;
  padding-top: 70px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

.grid-footer-bottom-v1 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.social-media-icon-wrapper {
  grid-column-gap: 14px;
  grid-row-gap: 8px;
  display: flex;
}

.footer-cta-wrapper-v2 {
  background-color: #b0f127;
  background-image: url('../images/suscribe-to-out-newsletter-bg-image-finantech-x-webflow-template.svg');
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 68% 100%;
  border-radius: 18px;
  padding: 64px 72px;
}

.footer-cta-content-top-v2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr .8fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.grid-footer-4-columns-v2 {
  grid-column-gap: 72px;
  grid-row-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
}

.hero-image-container {
  width: 120%;
  max-width: 830px;
  min-width: 565px;
  position: relative;
  top: 15%;
}

.bottom-shadow {
  z-index: 0;
  width: 100%;
  height: 53px;
  filter: blur(18px);
  background-color: #060606;
  border-radius: 50%;
  margin-top: -25px;
  position: relative;
}

.wallet-image-container {
  width: 100%;
  max-width: 590px;
  position: relative;
}

.security-image-container {
  max-width: 484px;
  position: relative;
}

.security-card-content {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  display: flex;
}

.security-card-content.top-left {
  border-bottom: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
}

.security-card-content.top-right {
  border-bottom: 1px solid #2f2f2f;
}

.security-card-content.bottom-left {
  border-right: 1px solid #2f2f2f;
}

.security-card-content.large {
  padding-top: 48px;
  padding-bottom: 48px;
}

.text-no-wrap {
  white-space: nowrap;
}

.feature-card {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 94px 32px;
  display: flex;
}

.feature-card.large {
  border-bottom: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  justify-content: flex-start;
  padding-top: 52px;
  position: relative;
}

.feature-card.top-right {
  border-bottom: 1px solid #2f2f2f;
}

.feature-card.bottom-left, .feature-card.middle {
  border-right: 1px solid #2f2f2f;
}

.feature-card-large-content {
  z-index: 1;
  max-width: 335px;
  text-align: left;
  align-self: flex-start;
  position: relative;
}

.cta-v1-content-left-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}

.cta-v1-content-left-wrapper-new{
  padding-right: 100px;
}

.lightbox-wrapper {
  min-height: 628px;
  background-color: #141414;
  border-right: 1px solid #2f2f2f;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  position: relative;
  overflow: hidden;
}

.tabs-menu-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.tab-link {
  background-color: rgba(0, 0, 0, 0);
  padding: 48px 24px;
}

.tab-link.w--current {
  background-color: rgba(0, 0, 0, 0);
}

.tab-link.middle {
  border-top: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
}

.tab-content-wrapper {
  width: 100%;
  max-width: 521px;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.tab-image-wrapper {
  max-height: 100px;
  max-width: 100px;
  min-height: 100px;
  min-width: 100px;
  background-color: #b0f127;
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.tab-icon-wrapper {
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.tab-body {
  width: 100%;
  overflow: hidden;
}

.tab-btn-line---horizontal {
  width: 24px;
  height: 2px;
  background-color: #fff;
  position: absolute;
}

.tab-btn-line---vertical {
  width: 24px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  transform: rotate(0);
}

.slider-frame {
  width: 48%;
  min-width: 585px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 52%;
  right: auto;
}

.slider-phone {
  height: auto;
  max-width: 396px;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 100px;
  position: absolute;
  left: 26px;
}

.integration-card-content-top {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.integration-badge {
  color: #060606;
  background-color: #b0f127;
  border-radius: 80px;
  margin-left: 16px;
  padding: 8px 14px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.125em;
}

.integration-badge.slider-bagde {
  top: 64px;
}

.slide {
  height: 100%;
  margin-right: 50px;
}

.slider-arrow {
  max-height: 80px;
  max-width: 80px;
  min-height: 80px;
  min-width: 80px;
  color: #060606;
  background-color: #b0f127;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 1em;
  display: flex;
}

.slider-arrow.right {
  transform-style: preserve-3d;
  transition: transform .3s;
  right: -65px;
}

.slider-arrow.right:hover {
  transform: translate3d(0, -4px, .01px);
}

.slider-arrow.left {
  left: -60px;
}

.slider-arrow.left.hidden-on-desktop {
  display: none;
}

.slider-arrow.timeline-right-arrow {
  transition: background-color .3s;
  top: -144px;
  bottom: auto;
  left: auto;
  right: 0%;
}

.slider-arrow.timeline-right-arrow:hover {
  background-color: #71a407;
}

.slider-arrow.timeline-left-arrow {
  color: #fff;
  background-color: #060606;
  border: 1px solid #fff;
  transition: color .3s, border-color .3s, background-color .3s;
  top: -144px;
  bottom: auto;
  left: auto;
  right: 104px;
}

.slider-arrow.timeline-left-arrow:hover {
  color: #060606;
  background-color: #b0f127;
  border-color: #b0f127;
}

.integrations-wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 8px;
  overflow: hidden;
}

.integrations-right-shadow {
  z-index: 1;
  width: 20%;
  max-width: 321px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #141414 95%);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: -8px;
}

.integration-image {
  margin-right: 16px;
}

.testimonial-content-bottom-wrapper {
  grid-column-gap: 12px;
  align-items: center;
  display: flex;
}

.testimonial-bottom-gradient {
  max-height: 412px;
  min-height: 412px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #060606 88%);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.details-wrapper {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  display: flex;
}

.details-wrapper.mg-bottom-16px.center {
  justify-content: center;
}

.cta-content {
  z-index: 1;
  position: relative;
}

.team-member {
  border-bottom: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  position: relative;
}

.values-content-wrapper {
  height: 100%;
  grid-column-gap: 24px;
  background-color: #060606;
  justify-content: space-between;
  align-items: flex-start;
  padding: 72px 40px;
  display: flex;
}

.values-content-wrapper.top-left {
  border-bottom: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
}

.values-content-wrapper.top-right {
  border-bottom: 1px solid #2f2f2f;
}

.values-content-wrapper.bottom-left {
  border-right: 1px solid #2f2f2f;
}

.timeline-slider {
  height: auto;
  background-color: rgba(0, 0, 0, 0);
}

.timeline-slider-mask {
  max-width: 580px;
  overflow: visible;
}

.testimonial-slide-card-wrapper {
  border: 1px solid #2f2f2f;
  padding: 48px 64px;
}

.testimonial-slide-card-wrapper.left {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.testimonial-slide-card-wrapper.right {
  border-left-color: rgba(0, 0, 0, 0);
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.testimonial-slide-card-wrapper.center {
  border-left-color: rgba(0, 0, 0, 0);
}

.bg-image-gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 30%, #060606);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.team-member-content-left {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 72px;
  left: 24px;
  right: 24px;
}

.team-member-contert-right {
  background-color: #141414;
  border-left: 1px solid #2f2f2f;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.team-member-contert-top-right {
  padding: 80px 40px 40px;
}

.team-member-content-bottom-right {
  border-top: 1px solid #2f2f2f;
  padding: 40px;
}

.heading-wrapper {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.heading-wrapper.center {
  grid-column-gap: 10px;
  justify-content: center;
}

.cta-v3-image-wrapper {
  width: 100%;
  max-width: 53%;
  position: absolute;
  top: -123px;
  bottom: auto;
  left: auto;
  right: 3%;
}

.author-card-content-left {
  position: absolute;
  top: auto;
  bottom: 34px;
  left: 24px;
  right: 24px;
}

.author-card-content-top-right {
  border-bottom: 1px solid #2f2f2f;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 40px 40px 47px;
  display: flex;
}

.author-card-content-right {
  border-left: 1px solid #2f2f2f;
  flex-direction: column;
  display: flex;
}

.integration-information-top {
  padding-top: 48px;
  padding-left: 43px;
  padding-right: 43px;
}

.integration-information-bottom {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  padding-bottom: 48px;
  padding-left: 43px;
  padding-right: 43px;
  display: flex;
}

.integration-single-image {
  margin-right: 24px;
}

.product-card-content-top {
  border-bottom: 1px solid #2f2f2f;
  padding: 56px 32px 40px;
}

.product-card-content-bottom {
  flex-direction: column;
  flex: 1;
  padding: 40px 32px 72px;
  display: flex;
}

.product-badge-wrapper {
  position: absolute;
  top: 28px;
  right: 28px;
}

.price-wrapper {
  grid-column-gap: 6px;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 8px;
  display: flex;
}

.price-wrapper.mg-bottom-24px {
  margin-bottom: 24px;
}

.career-hero-image-wrapper {
  width: 100%;
}

.perk-wrapper {
  text-align: center;
  background-color: #060606;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 90px 40px;
  display: flex;
}

.perk-wrapper.top-left, .perk-wrapper.top-center {
  border-bottom: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
}

.perk-wrapper.top-right {
  border-bottom: 1px solid #2f2f2f;
}

.perk-wrapper.bottom-left, .perk-wrapper.bottom-center {
  border-right: 1px solid #2f2f2f;
}

.testimonial-grid-vertical {
  max-width: 845px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  align-items: start;
  display: grid;
  overflow: hidden;
}

.testimonial-top-gradient {
  height: 400px;
  background-image: linear-gradient(#060606 12%, rgba(0, 0, 0, 0));
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.career-card {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 48px 56px;
  display: flex;
}

.career-card-item {
  border-bottom: 1px solid #2f2f2f;
}

.career-card-item:last-child {
  border-bottom-width: 0;
}

.mg-right-12px {
  margin-right: 12px;
}

.contact-card-wrapper {
  text-align: center;
  background-color: #060606;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 72px 30px;
  display: flex;
}

.contact-card-wrapper.left, .contact-card-wrapper.center {
  border-right: 1px solid #2f2f2f;
}

.coming-soon-image-wrapper {
  position: absolute;
  left: 0%;
  right: 0%;
}

.sign-card-content {
  padding-left: 72px;
  padding-right: 72px;
}

.cta-section-form .sign-card-content{
  padding-top: 48px;
}

.form {
  margin-bottom: 0;
}

.no-hover {
  transition: none;
}

.dropdown-main-pages-wrapper {
  grid-column-gap: 28px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: flex;
}

.pd-left-0px {
  padding-left: 0;
}

.sign-up-form {
  min-height: 385px;
}

.demo-form {
  min-height: 305px;
}

.padding-top---desktop {
  height: 148px;
}

.utility-page-wrapper {
  max-height: 100vh;
  min-height: 100vh;
}

.footer-logo-link {
  width: 100%;
  max-width: 209px;
  padding-left: 0;
  transition: transform .3s, color .3s;
}

.footer-logo-link:hover {
  transform: translate(0, -4px);
}

.slider-phone---wrapper {
  width: 100%;
  max-width: 465px;
  align-items: center;
  display: flex;
}

.footer-cta-content-top---wrapper {
  max-width: 462px;
  margin-left: auto;
  margin-right: auto;
}

.team-collection-list-wrapper {
  margin: -1px;
}

.dot-span {
  position: relative;
  top: -25px;
}

.form-button-inside {
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.author-card-content-bottom-right {
  align-items: center;
  padding: 24px 40px;
  display: flex;
}

.rich-text-v2 img {
  border-radius: 24px;
}

.rich-text-v2 p {
  margin-bottom: 16px;
}

.rich-text-v2 h2 {
  margin-bottom: 16px;
  font-size: 54px;
  line-height: 1.222em;
}

.rich-text-v2 h3 {
  margin-top: 48px;
  margin-bottom: 16px;
}

.rich-text-v2 figure {
  margin-top: 64px;
  margin-bottom: 64px;
}

.rich-text-v2 ul, .rich-text-v2 ol {
  margin-top: 24px;
  margin-bottom: 48px;
}

.rich-text-v2 h6, .rich-text-v2 h5, .rich-text-v2 h4 {
  margin-top: 48px;
  margin-bottom: 16px;
}

.rich-text-v2 blockquote {
  margin-top: 48px;
  margin-bottom: 48px;
}

.integration-card-content-bottom {
  width: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.mg-top-auto {
  margin-top: auto;
}

.mg-top-31px {
  margin-top: 31px;
}

.token-information-wrapper {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.add-cart---main {
  min-height: 166px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.career-hero-image---main {
  width: 100%;
  max-width: 593px;
  min-width: 542px;
  margin-bottom: -66%;
  display: flex;
  position: relative;
}

.testimonial-grid-column {
  max-width: 845px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.contact-form-block {
  min-height: 508px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.success-contact-form {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.success-message-newsletter {
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.coming-soon-state-wrapper {
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.coming-soon-image-wrapper---main {
  width: 100%;
  max-width: 382px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.sign-form {
  min-height: 570px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.checkbox-field-paragraph {
  color: silver;
}

.sign-up-form-v1 {
  min-height: 773px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.cta-section-form .sign-up-form-v1{
  min-height: auto;
}

.cta-section-form .divider_form{
  margin-bottom: 0px;
}

.demo-form-block {
  min-height: 569px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.password-images-wrapper {
  max-width: 23%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: 6%;
}

.testimonial-grid-1-column {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.testimonial-grid-1-column._01, .testimonial-grid-1-column._03 {
  margin-top: 45px;
}

.order-summary-line-item, .cart-line-item-subtotal {
  grid-column-gap: 15px;
  flex-wrap: wrap;
}

.remove-button {
  color: #939393;
  line-height: 1.111em;
}

.remove-button:hover {
  color: #b0f127;
}

.cart-item {
  border-bottom: 1px solid #2f2f2f;
  justify-content: space-between;
  padding: 20px 24px;
}

.cart-item:first-child {
  padding-top: 0;
}

.cart-item:last-child {
  border-bottom-style: none;
  padding-bottom: 0;
}

.cart-item-image-wrapper {
  max-width: 60px;
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (min-width: 1440px) {
  .grid-2-columns.style---styleguide-wrapper {
    grid-template-columns: .3fr 1fr;
  }

  .grid-2-columns.contact-grid {
    grid-column-gap: 80px;
    grid-template-columns: 1fr 1.15fr;
  }

  .grid-2-columns.home-hero-grid {
    grid-template-columns: .7fr 1fr;
  }

  .grid-2-columns.slider-grid {
    grid-column-gap: 64px;
  }

  .grid-2-columns.author-card {
    grid-template-columns: .6fr 1fr;
  }

  .grid-2-columns.left-0-7fr {
    grid-template-columns: .7fr 1fr;
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 380px) .8fr;
  }

  .inner-container._880px {
    max-width: 880px;
  }

  .btn-secondary.cta-button {
    font-weight: 600;
  }

  .line-rounded-icon._24px {
    font-size: 24px;
    line-height: 1em;
  }

  .style---dropdown-toggle {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 20px;
    line-height: 20px;
  }

  .style---dropdown-link-sidebar {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
  }

  .style---dropdown-link-icon-sidebar {
    max-width: 46px;
    border-radius: 12px;
    margin-right: 14px;
  }

  .style---logo-wrapper {
    justify-content: center;
    display: flex;
  }

  .style---logo-sidebar {
    max-width: 260px;
  }

  .section.large.coming-soon-section {
    justify-content: space-between;
  }

  .section.hero-v1, .section.hero-v2 {
    padding-top: 180px;
  }

  .accordion-btn-line.v2.vertical {
    background-color: #fff;
    transform: rotate(0);
  }

  .accordion-btn-line.v2.horizontal {
    background-color: #fff;
  }

  .accordion-side.right-side {
    margin-left: 64px;
  }

  .team-member-content {
    left: 48px;
    right: 48px;
  }

  .image-wrapper.feature-image {
    width: 66%;
  }

  .image-wrapper.cta-v2-image-wrapper {
    width: auto;
    right: -6.5%;
  }

  .footer-main-pages-wrapper.v2 {
    grid-column-gap: 40px;
  }

  .footer-cta-content-top {
    padding-left: 64px;
    padding-right: 64px;
  }

  .feature-card.large {
    padding-top: 52px;
  }

  .feature-card.top-right {
    padding: 90px 40px;
  }

  .slider-frame {
    width: 52%;
    max-width: 900px;
    left: 48%;
  }

  .integration-badge {
    top: 64px;
  }
}

@media screen and (min-width: 1920px) {
  .style---heading {
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .style---content-wrapper {
    padding-left: 8vw;
    padding-right: 8vw;
  }

  .link-text {
    transition: none;
  }

  .checkout-col-right {
    position: -webkit-sticky;
    position: sticky;
    top: 24px;
  }

  .image-home-link-wrapper {
    max-width: 560px;
  }

  .slider-frame {
    left: 48%;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  blockquote {
    padding: 90px 55px;
    font-size: 28px;
  }

  .grid-2-columns.style---styleguide-wrapper {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.contact-grid {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.newsletter {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.cta-v1 {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.blog-card-featured {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-paragraph {
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-left-default {
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.text-left-default.alt {
    grid-template-columns: minmax(350px, .85fr) 1fr;
  }

  .grid-2-columns.text-right-default {
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.text-right-default.alt {
    max-width: 94vw;
    grid-template-columns: 1fr minmax(400px, .85fr);
    justify-content: end;
  }

  .grid-2-columns.text-left-short {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.template-page-sidebar {
    grid-template-columns: .5fr 1fr;
  }

  .grid-2-columns.product-page {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.checkout-page {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.suprises-pages-right, .grid-2-columns.suprises-pages-left {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    margin-bottom: 100px;
  }

  .grid-2-columns.home-hero-grid {
    grid-row-gap: 150px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.slider-grid, .grid-2-columns.cta-v2, .grid-2-columns.text-right-short {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-right-short.alt-v1 {
    width: 100%;
    justify-content: end;
  }

  .grid-2-columns.title-left {
    grid-template-columns: .5fr 1fr;
  }

  .grid-2-columns.author-card {
    grid-template-columns: .7fr 1fr;
  }

  .grid-2-columns.hero-images-grid {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.sidebar-right-v1 {
    grid-row-gap: 70px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.sidebar-left {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.hero-images-grid-v2 {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-left-grid {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.left-0-7fr, .grid-2-columns.sidebar-right {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.coming-soon-grid {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .grid-2-columns.team-strip {
    grid-row-gap: 48px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons {
    grid-row-gap: 30px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.team-grid {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.sign-up-page-grid {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .style---navigation-sidebar {
    max-height: 100%;
    min-height: auto;
    box-shadow: none;
    position: static;
  }

  .card.checkout-block {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card.figma-file {
    flex-direction: column;
    overflow: hidden;
  }

  .card._24px {
    align-self: center;
  }

  .card._24px.integration-information-card {
    width: 100%;
    max-width: 100%;
    position: static;
  }

  .card._24px.product-card {
    flex-direction: row;
    top: 0;
  }

  .card._24px.product-card.popular {
    margin-bottom: 0;
    bottom: 0;
  }

  .card._24px.accordion-card-wrapper {
    padding-bottom: 48px;
  }

  .card._24px.add-to-cart-card {
    max-width: 100%;
    padding: 64px;
  }

  .card._24px.demo-card {
    padding: 100px 40px;
  }

  .card.features-card-wrapper, .grid-4-columns {
    grid-template-columns: 1fr 1fr;
  }

  .style---block-sub-heading {
    margin-bottom: 24px;
  }

  .divider {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .divider._64px {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 250px) 1fr;
  }

  .mg-bottom-16px.keep {
    margin-bottom: 16px;
  }

  .mg-bottom-24px.keep {
    margin-bottom: 24px;
  }

  .mg-right-24px.mg-bt-16px {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .display-1 {
    font-size: 60px;
  }

  .grid-1-column.gap-40px.token-information {
    grid-template-columns: 1fr 1fr;
  }

  .gap-row-80px {
    grid-row-gap: 56px;
  }

  .display-2 {
    font-size: 46px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 140px;
  }

  .paragraph-large {
    font-size: 20px;
  }

  .text-400 {
    font-size: 22px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr 1fr;
  }

  .grid-3-columns.gap-row-80px {
    grid-row-gap: 64px;
  }

  .grid-3-columns._1-col-tablet, .grid-3-columns.gap-32px._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-3-columns.dropdown-grid {
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .style---heading {
    min-height: auto;
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .inner-container._740px.full-width-tablet {
    max-width: 100%;
  }

  .inner-container._600px.full-width-tablet {
    width: 100%;
    max-width: 100%;
  }

  .inner-container._400px.full-width-tablet, .inner-container._460px.full-width-tablet, .inner-container._800px, .inner-container._800px.full-width-tablet, .inner-container._385px.full-width-tablet, .inner-container._670px.full-width-tablet, .inner-container._540px.full-width-tablet, .inner-container._530px.full-width-tablet, .inner-container._445px.full-width-tablet, .inner-container._880px, .inner-container._455px.full-width-tablet, .inner-container._650px.full-width-tablet, .inner-container._485px.full-width-tablet {
    max-width: 100%;
  }

  .inner-container._400px---tablet {
    max-width: 400px;
  }

  .inner-container._600px---tablet {
    max-width: 600px;
  }

  .inner-container._700px---tablet {
    max-width: 700px;
  }

  .inner-container._760px---tablet {
    max-width: 760px;
  }

  .inner-container._650px---tablet {
    max-width: 650px;
  }

  .inner-container._550px---tablet {
    max-width: 550px;
  }

  .inner-container._100---tablet {
    max-width: 100%;
  }

  .inner-container._500px---tablet {
    max-width: 500px;
  }

  .inner-container._450px---tablet {
    max-width: 450px;
  }

  .inner-container._800px---tablet {
    max-width: 800px;
  }

  .inner-container.center---tablet {
    margin-left: auto;
    margin-right: auto;
  }

  .style---content-heading {
    margin-bottom: 60px;
    padding: 32px;
  }

  .btn-primary.small.header-btn-hidde-on-mb {
    z-index: 1;
    margin-right: 16px;
    position: relative;
  }

  .btn-primary.large, .btn-secondary.large {
    padding: 26px 52px;
  }

  .btn-secondary.sign-in-button {
    width: 100%;
  }

  .position-relative.sign-up-image-wrapper {
    width: 100%;
    max-width: none;
    min-width: auto;
  }

  .avatar-circle._06 {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
  }

  .avatar-circle._07 {
    width: 160px;
    height: 160px;
    max-height: 160px;
    max-width: 160px;
  }

  .avatar-circle._08 {
    width: 280px;
    height: 280px;
    max-height: 280px;
    max-width: 280px;
  }

  .line-square-icon.top-bar-close-icon {
    padding: 18px 24px;
  }

  .style---dropdown-wrapper-sidebar {
    display: none;
  }

  .buttons-row.center---tablet {
    justify-content: center;
  }

  .style---sidebar-wrapper {
    overflow: hidden;
  }

  .style---logo-wrapper {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .style---button-wrapper-sidebar {
    display: none;
  }

  .elements---page-hero {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.small {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.large {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .section.large.bottom-120px {
    padding-bottom: 100px;
  }

  .section.large.top-120px {
    padding-top: 100px;
  }

  .section.large.coming-soon-section {
    padding-top: 80px;
  }

  .section.top {
    padding-top: 180px;
  }

  .section.top.v2 {
    padding-top: 150px;
  }

  .section.template-pages-hero {
    padding-top: 180px;
  }

  .section.hero-page {
    padding-top: 180px;
    padding-bottom: 100px;
  }

  .section.cta-home-pages {
    background-image: linear-gradient(#141414 25%, #060606 25%);
  }

  .section.hero-v1 {
    padding-top: 180px;
    padding-bottom: 40px;
  }

  .section.medium {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.medium.top {
    padding-top: 180px;
  }

  .section.integrations-section {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .section.bottom-80px {
    padding-bottom: 60px;
  }

  .section._180px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.hero-images {
    background-image: linear-gradient(#060606 25%, rgba(6, 6, 6, 0)), url('../images/bg-integrations-finantech-webflow-template.svg');
    background-position: 0 0, 50% 100%;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
  }

  .section.integration-single-hero {
    padding-top: 180px;
  }

  .section.coming-soon-section {
    padding-top: 100px;
  }

  .section.pd-128px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.pd-top-180px {
    padding-top: 100px;
  }

  .top-bar-wrapper {
    padding-left: 56px;
    padding-right: 56px;
  }

  .top-bar-padding {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .top-bar-flex {
    flex-direction: column;
  }

  .link-wrapper.highlighted {
    max-width: 165px;
  }

  .heading-h1-size {
    font-size: 48px;
  }

  .heading-h2-size {
    font-size: 32px;
  }

  .header-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .header-nav-menu-wrapper {
    background-color: #060606;
    border-bottom: 1px solid #2f2f2f;
    padding: 24px;
  }

  .header-nav-menu-list {
    flex-direction: column;
    align-items: stretch;
  }

  .header-nav-link {
    margin-left: 0;
    margin-right: 0;
  }

  .header-nav-list-item {
    margin-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  .dropdown-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-column-wrapper {
    width: auto;
    position: static;
    overflow: visible;
    transform: none;
  }

  .dropdown-pd {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border-width: 0;
    border-radius: 0;
    padding: 20px 0 14px;
  }

  .header-logo-link {
    z-index: 1;
    max-width: 221px;
  }

  .hamburger-menu-wrapper {
    z-index: 1;
    transition: opacity .3s;
  }

  .hamburger-menu-wrapper:hover {
    opacity: .6;
  }

  .hamburger-menu-bar {
    width: 44px;
    height: 3px;
    background-color: #b0f127;
  }

  .hamburger-menu-bar.bottom {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .header-left-side {
    flex: 1;
    justify-content: space-between;
  }

  .utility-page-wrap._404 {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .utility-page-wrap.password {
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    padding-top: 180px;
    padding-bottom: 100px;
  }

  .utility-page-content.password {
    flex-direction: column-reverse;
  }

  .utility-page-form.password-form-wrapper {
    z-index: 0;
  }

  .flex-horizontal.start.gap-24px.coming-soon-social-media {
    justify-content: center;
  }

  .footer-middle {
    padding-top: 94px;
    padding-bottom: 94px;
  }

  .grid-footer-logo---paragraph._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .text-center.tablet {
    text-align: center;
  }

  .sticky-top._48px-top.sticky-tablet {
    position: static;
  }

  .cta-section {
    padding-top: 112px;
    padding-bottom: 112px;
  }

  .cta-section.cta-v1 {
    padding-bottom: 0;
  }

  .cta-section.v2 {
    background-image: linear-gradient(#141414 50%, rgba(20, 20, 20, 0)), url('../images/bg-cta-v2-finantech-x-webflow-template.svg');
    background-position: 0 0, 100% 100%;
    background-repeat: repeat, no-repeat;
    background-size: auto, auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .cta-section.cta-v2 {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .grid-6-columns.logo-strip {
    max-width: 650px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .accordion-item-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .accordion-item-wrapper.main {
    padding: 48px;
  }

  .acordion-body {
    max-width: 100%;
  }

  .pd---content-inside-card.large {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .image.fit-cover.left {
    object-position: 0% 50%;
  }

  .blog-card-image {
    width: 100%;
  }

  .blog-card-image-wrapper.featured-v2 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .categories-badges-item-wrapper.center---tablet, .categories-badges-wrapper.center---tablet {
    justify-content: center;
  }

  .blog-card-featured-inner-content {
    border-top: 1px solid #2f2f2f;
    border-left-width: 0;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .blog-card-content-inside {
    padding: 32px 34px 40px;
  }

  .image-wrapper.footer-cta-image {
    flex: 1;
    margin-left: -5%;
  }

  .image-wrapper.feature-image {
    width: 60%;
  }

  .image-wrapper.cta-v1-top-image {
    width: 71%;
  }

  .image-wrapper.cta-v1-bottom-image.float {
    width: 94%;
  }

  .image-wrapper.cta-v1-bottom-float.float {
    width: 20%;
  }

  .image-wrapper.cta-v1-top-float.float {
    width: 16%;
    right: 4%;
  }

  .image-wrapper.cta-v1-middle-float.main {
    width: 23%;
    top: 36%;
    right: -16%;
  }

  .image-wrapper.cta-v2-image-wrapper {
    width: 100%;
    max-width: 650px;
    position: static;
  }

  .image-wrapper.our-story-image {
    min-width: 300px;
  }

  .image-wrapper.sign-up-image {
    position: relative;
    left: 18%;
  }

  .image-wrapper.sign-up-top-left-image {
    width: 15%;
    left: 18%;
  }

  .image-wrapper.sign-up-bottom-left {
    width: 10%;
    right: 23%;
  }

  .image-wrapper.sign-up-right-image {
    width: 10%;
    left: 21%;
  }

  .image-wrapper.password-left-image {
    bottom: 13%;
    left: -64%;
  }

  .template-pages---phography-wrapper {
    border-radius: 10px;
  }

  .cart-button-wrapper {
    z-index: 2;
    margin-right: 16px;
  }

  .checkout-form {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }

  .image-home-link-wrapper {
    max-width: 260px;
  }

  .figma-file-image-wrapper {
    max-width: 100%;
    border-top: 1px solid #2f2f2f;
    border-left-style: none;
  }

  .figma-file-text-wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .grid-footer-v1---4-column {
    grid-template-columns: .45fr auto;
  }

  .footer-cta-wrapper {
    max-width: 100%;
    flex-direction: row;
  }

  .footer-main-pages-wrapper {
    grid-template-columns: auto auto 1fr;
  }

  .footer-main-pages-wrapper.v2 {
    grid-column-gap: 64px;
  }

  .footer-cta-content-top {
    width: 61%;
    padding: 48px;
  }

  .grid-footer-bottom-v1 {
    flex-direction: column;
  }

  .footer-cta-wrapper-v2 {
    padding: 56px 48px;
  }

  .footer-cta-content-top-v2 {
    grid-template-columns: 1fr;
  }

  .grid-footer-4-columns-v2 {
    grid-column-gap: 64px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto 1fr;
  }

  .hero-image-container {
    width: 100%;
    min-width: auto;
    margin-left: auto;
    margin-right: auto;
    top: 0%;
  }

  .wallet-image-container {
    min-width: 400px;
  }

  .security-image-container {
    min-width: 370px;
  }

  .feature-card {
    padding: 56px 48px;
  }

  .feature-card.large {
    border-right-width: 0;
    padding-top: 56px;
  }

  .feature-card.top-right {
    border-right: 1px solid #2f2f2f;
  }

  .feature-card.bottom-left {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .feature-card-large-content {
    margin-bottom: 100px;
  }

  .cta-v1-content-left-wrapper {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 70px;
    display: flex;
  }

  .lightbox-wrapper {
    min-height: 0;
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
  }

  .slider-frame {
    width: 100%;
    min-width: auto;
    position: relative;
    left: 0%;
  }

  .integration-badge {
    top: 32px;
  }

  .slider-arrow.right {
    right: -60px;
  }

  .integrations-right-shadow {
    right: -24px;
  }

  .cta-content {
    max-width: 600px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .values-content-wrapper {
    grid-row-gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 64px 40px;
  }

  .testimonial-slide-card-wrapper {
    padding: 40px 48px;
  }

  .team-member-content-left {
    align-items: flex-start;
    bottom: 56px;
    left: 34px;
    right: 34px;
  }

  .team-member-contert-right {
    border-top: 1px solid #2f2f2f;
    border-left-width: 0;
    flex-direction: row;
  }

  .team-member-contert-top-right {
    padding-top: 56px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .team-member-content-bottom-right {
    border-top-style: none;
    border-left: 1px solid #2f2f2f;
    padding-top: 56px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .heading-wrapper.center---tablet {
    justify-content: center;
  }

  .cta-v3-image-wrapper {
    max-width: 100%;
    margin-bottom: -44%;
    position: static;
  }

  .integration-information-top {
    padding-left: 48px;
    padding-right: 48px;
  }

  .integration-information-bottom {
    grid-column-gap: 40px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .product-card-content-top {
    width: 50%;
    border-bottom-width: 0;
    border-right: 1px solid #2f2f2f;
    padding: 48px;
    position: relative;
  }

  .product-card-content-bottom {
    width: 50%;
    flex-direction: column;
    padding: 48px;
    display: flex;
  }

  .career-hero-image-wrapper {
    right: 0%;
  }

  .perk-wrapper {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .perk-wrapper.top-center {
    border-right-width: 0;
  }

  .perk-wrapper.top-right {
    border-right: 1px solid #2f2f2f;
  }

  .perk-wrapper.bottom-left {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .perk-wrapper.bottom-center {
    border-right-width: 1px;
  }

  .testimonial-grid-vertical {
    max-width: 100%;
  }

  .contact-card-wrapper {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .contact-card-wrapper.left, .contact-card-wrapper.center {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .coming-soon-image-wrapper {
    position: relative;
  }

  .dropdown-main-pages-wrapper {
    grid-column-gap: 40px;
    grid-auto-columns: 1fr;
  }

  .center-content-tablet {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .demo-form {
    min-height: 298px;
  }

  .padding-top---desktop {
    display: none;
  }

  .text-center---tablet {
    text-align: center;
  }

  .dot-span {
    top: -22px;
  }

  .rich-text-v2 h2 {
    font-size: 46px;
  }

  .token-information-wrapper {
    justify-content: flex-start;
  }

  .career-hero-image---main {
    max-width: 91%;
    min-width: auto;
    margin-bottom: -65%;
  }

  .testimonial-grid-column {
    max-width: 100%;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .sign-form {
    min-height: 645px;
  }

  .sign-up-form-v1 {
    min-height: 763px;
  }

  .demo-form-block {
    min-height: 555px;
  }

  .password-images-wrapper {
    max-width: 141px;
    margin-bottom: -96px;
    left: 0%;
  }

  .testimonial-grid-1-column {
    grid-template-columns: 1fr 1fr;
  }

  .testimonial-grid-1-column._01, .testimonial-grid-1-column._03 {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 40px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  blockquote {
    border-radius: 18px;
    padding: 75px 34px;
    font-size: 24px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.style---styleguide-wrapper {
    grid-template-columns: auto;
  }

  .grid-2-columns.contact-grid {
    grid-row-gap: 60px;
  }

  .grid-2-columns.newsletter {
    grid-row-gap: 50px;
  }

  .grid-2-columns.title-and-paragraph {
    grid-row-gap: 12px;
    grid-template-columns: 1.1fr;
  }

  .grid-2-columns.text-left-default {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-left-default.alt {
    grid-row-gap: 30px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-right-default {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-right-default.alt {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-left-short {
    grid-row-gap: 60px;
  }

  .grid-2-columns.template-page-sidebar {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.changelog-item.first {
    grid-column-gap: 30px;
  }

  .grid-2-columns.product-page {
    grid-row-gap: 60px;
  }

  .grid-2-columns.checkout-page {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.home-hero-grid {
    grid-row-gap: 50px;
  }

  .grid-2-columns.security-grid {
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.slider-grid, .grid-2-columns.cta-v2, .grid-2-columns.text-right-short {
    grid-row-gap: 60px;
  }

  .grid-2-columns.text-right-short.alt-v1 {
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-left {
    grid-row-gap: 10px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.author-card {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.hero-images-grid, .grid-2-columns.sidebar-right-v1, .grid-2-columns.hero-images-grid-v2 {
    grid-row-gap: 60px;
  }

  .grid-2-columns.title-left-grid {
    grid-row-gap: 16px;
  }

  .grid-2-columns.coming-soon-grid {
    grid-row-gap: 60px;
    justify-items: stretch;
  }

  .grid-2-columns.team-strip {
    grid-row-gap: 16px;
  }

  .grid-2-columns.sign-up-page-grid {
    grid-row-gap: 60px;
  }

  .card.sticky-top.top-24px.static-mbl {
    position: static;
  }

  .card.buy-now, .card._24px {
    border-radius: 18px;
  }

  .card._24px.product-card {
    flex-direction: column;
  }

  .card._24px.accordion-card-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card._24px.add-to-cart-card {
    padding: 50px 34px;
  }

  .card._24px.sign-in-card {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .card._24px.demo-card {
    padding: 80px 34px;
  }

  .card.features-card-wrapper {
    border-radius: 18px;
    grid-template-columns: 1fr;
  }

  .card.integration-card {
    padding: 48px 34px;
  }

  .card.testimonial-card {
    padding: 40px 34px;
  }

  .style---block-sub-heading.border {
    margin-bottom: 48px;
    padding-bottom: 24px;
  }

  .divider {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .divider._48px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .style---typography-block-grid {
    grid-row-gap: 28px;
    grid-template-columns: 1fr;
  }

  .style---content-block {
    margin-bottom: 64px;
  }

  .mg-bottom-16px.keep {
    margin-bottom: 16px;
  }

  .mg-bottom-24px.keep, .mg-bottom-32px {
    margin-bottom: 24px;
  }

  .mg-bottom-48px {
    margin-bottom: 40px;
  }

  .mg-bottom-56px {
    margin-bottom: 48px;
  }

  .mg-bottom-64px {
    margin-bottom: 54px;
  }

  .mg-top-48px {
    margin-top: 40px;
  }

  .text-200 {
    font-size: 16px;
  }

  .text-200.list {
    line-height: 22px;
  }

  .display-1 {
    font-size: 42px;
  }

  .grid-1-column.gap-column-24px {
    grid-row-gap: 20px;
  }

  .grid-1-column.gap-row-24px {
    grid-row-gap: 16px;
  }

  .grid-1-column.gap-row-48px.gap-row-100px---mobile {
    grid-row-gap: 100px;
  }

  .grid-1-column.hero-grid {
    grid-row-gap: 48px;
  }

  .grid-1-column.gap-40px.token-information {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .display-2 {
    font-size: 36px;
  }

  .display-3 {
    font-size: 28px;
  }

  .display-4 {
    font-size: 24px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 120px;
  }

  .paragraph-large {
    font-size: 18px;
  }

  .text-400 {
    font-size: 20px;
  }

  .text-300 {
    font-size: 18px;
  }

  .text-300.medium.top--8px {
    top: -6px;
  }

  .text-300.medium.dropdown-title {
    margin-bottom: 32px;
  }

  .text-100 {
    font-size: 14px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;
  }

  .grid-3-columns.gap-row-80px {
    grid-row-gap: 40px;
  }

  .grid-3-columns.style---buttons-grid, .grid-3-columns.style---buttons-grid.download-app-grid {
    grid-template-columns: auto;
  }

  .grid-3-columns.dropdown-grid {
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr;
  }

  .style---shadow-card {
    border-radius: 18px;
  }

  .style---heading {
    padding: 104px 24px;
  }

  .inner-container._740px, .inner-container._574px, .inner-container._400px, .inner-container._460px, .inner-container._800px, .inner-container._800px.center, .inner-container._700px, .inner-container._385px, .inner-container._515px, .inner-container._670px, .inner-container._545px, .inner-container._470px, .inner-container._530px, .inner-container._660px, .inner-container._780px, .inner-container._445px, .inner-container._585px, .inner-container._455px, .inner-container._625px, .inner-container._650px, .inner-container._430px, .inner-container._560px, .inner-container._820px, .inner-container._485px, .inner-container._450px {
    max-width: 100%;
  }

  .inner-container._500px---mbl {
    max-width: 500px;
  }

  .inner-container._600px---mbl {
    max-width: 600px;
  }

  .inner-container._100---mbl {
    max-width: 100%;
  }

  .inner-container._550px---mbl {
    max-width: 550px;
  }

  .inner-container._400px---mbl {
    max-width: 400px;
  }

  .inner-container._450px---mbl {
    max-width: 450px;
  }

  .inner-container._350px---mbl {
    max-width: 350px;
  }

  .inner-container._300px---mbl {
    max-width: 300px;
  }

  .inner-container._685px {
    max-width: 100%;
  }

  .style---content-heading {
    border-radius: 18px;
    margin-bottom: 36px;
    padding: 24px;
  }

  .style---heading-icon-wrapper {
    max-width: 58px;
    border-radius: 16px;
  }

  .style---content-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .style---components-wrapper {
    border-radius: 18px;
  }

  .style---bg-white {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 34px 24px;
  }

  .style---bg-white.bottom {
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
  }

  .style-buttons-dark {
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .style-buttons-dark.top {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .btn-primary {
    padding: 18px 32px;
    font-size: 16px;
  }

  .btn-primary.small {
    padding: 14px 20px;
    font-size: 14px;
  }

  .btn-primary.small.header-btn-hidde-on-mb {
    display: none;
  }

  .btn-primary.large {
    padding: 24px 43px;
    font-size: 18px;
  }

  .btn-primary.inside-input.default {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .btn-primary.button-row {
    margin-right: 16px;
  }

  .btn-primary.pagination-btn {
    margin-top: 53px;
  }

  .btn-secondary {
    padding: 18px 32px;
    font-size: 16px;
  }

  .btn-secondary.small {
    padding: 14px 20px;
    font-size: 14px;
  }

  .btn-secondary.large {
    padding: 24px 43px;
    font-size: 18px;
  }

  .btn-secondary.pagination-btn {
    margin-top: 53px;
  }

  .badge-primary {
    padding: 16px 24px;
    font-size: 16px;
  }

  .badge-primary.small {
    padding: 12px 20px;
    font-size: 14px;
  }

  .badge-primary.large {
    padding: 20px 32px;
    font-size: 18px;
  }

  .badge-secondary {
    padding: 16px 24px;
    font-size: 16px;
  }

  .badge-secondary.small {
    padding: 12px 20px;
    font-size: 14px;
  }

  .badge-secondary.large {
    padding: 20px 32px;
    font-size: 18px;
  }

  .input {
    min-height: 60px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
  }

  .input.small {
    min-height: 50px;
    font-size: 14px;
  }

  .input.large {
    min-height: 70px;
    font-size: 18px;
  }

  .input.large.button-inside {
    min-height: 68px;
  }

  .input.input-form.top-left {
    border-top-right-radius: 18px;
  }

  .input.input-form.top-right {
    border-top-color: rgba(0, 0, 0, 0);
    border-top-right-radius: 0;
  }

  .input.input-form.bottom-left {
    border-bottom-left-radius: 0;
  }

  .input.input-form.bottom-right {
    border-bottom-left-radius: 18px;
  }

  .position-relative.sign-up-image-wrapper {
    width: 130%;
  }

  .text-area {
    min-height: 160px;
    font-size: 16px;
  }

  .text-area.small {
    min-height: 90px;
  }

  .style---avatars-grid {
    grid-template-columns: repeat(auto-fit, 180px);
  }

  .avatar-circle._02 {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
  }

  .avatar-circle._03 {
    width: 56px;
    height: 56px;
    max-height: 56px;
    max-width: 56px;
  }

  .avatar-circle._04 {
    width: 64px;
    height: 64px;
    max-height: 64px;
    max-width: 64px;
  }

  .avatar-circle._05 {
    width: 80px;
    height: 80px;
    max-height: 80px;
    max-width: 80px;
  }

  .avatar-circle._06 {
    width: 120px;
    height: 120px;
    max-height: 120px;
    max-width: 120px;
  }

  .avatar-circle._07 {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
  }

  .avatar-circle._08 {
    width: 220px;
    height: 220px;
    max-height: 220px;
    max-width: 220px;
  }

  .style---icons-grid {
    grid-template-columns: repeat(auto-fit, 56px);
  }

  .square-icon {
    max-width: 56px;
    border-radius: 12px;
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 68px;
    line-height: 73px;
  }

  .style---line-icons-grid {
    grid-template-columns: repeat(auto-fit, 18px);
  }

  .line-square-icon.top-bar-close-icon {
    font-size: 20px;
  }

  .rich-text img {
    border-radius: 18px;
  }

  .rich-text h2 {
    margin-bottom: 10px;
  }

  .rich-text h3 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .rich-text figure {
    margin-top: 54px;
    margin-bottom: 54px;
  }

  .rich-text ul, .rich-text ol {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .rich-text h6, .rich-text h5, .rich-text h4 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .rich-text blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .buttons-row.center.testimonial-button {
    bottom: 40px;
  }

  .buttons-row.center---mbl {
    justify-content: center;
  }

  .buttons-row.add-to-cart-btns {
    grid-column-gap: 16px;
  }

  .style---logo-sidebar {
    max-width: 200px;
  }

  .elements---page-hero {
    padding-top: 160px;
    padding-bottom: 80px;
  }

  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.small {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section.large {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.large.bottom-120px {
    padding-bottom: 80px;
  }

  .section.large.top-120px {
    padding-top: 80px;
  }

  .section.large.coming-soon-section {
    padding-top: 70px;
  }

  .section.top {
    padding-top: 160px;
  }

  .section.top.v2 {
    padding-top: 130px;
  }

  .section.template-pages-hero {
    padding-top: 160px;
    padding-bottom: 116px;
  }

  .section.hero-page {
    background-image: linear-gradient(#141414 60%, #2f2f2f 60%);
    padding-top: 160px;
    padding-bottom: 80px;
  }

  .section.hero-v1 {
    padding-top: 140px;
  }

  .section.medium {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.medium.top {
    padding-top: 160px;
  }

  .section.integrations-section {
    padding-top: 80px;
  }

  .section.bottom-80px {
    padding-bottom: 50px;
  }

  .section._180px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.hero-images, .section.integration-single-hero {
    padding-top: 160px;
  }

  .section._100px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.pd-128px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.pd-top-180px {
    padding-top: 80px;
  }

  .section.hero-v2 {
    padding-top: 160px;
  }

  .top-bar-wrapper {
    padding-left: 36px;
    padding-right: 36px;
  }

  .top-bar-padding {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .top-bar-padding.small-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .top-bar-flex {
    flex-direction: column;
  }

  .link-wrapper.highlighted {
    max-width: 144px;
  }

  .elements---element-title {
    margin-top: 48px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .heading-h1-size {
    font-size: 40px;
  }

  .heading-h2-size {
    font-size: 26px;
  }

  .heading-h3-size {
    font-size: 22px;
  }

  .heading-h4-size {
    font-size: 20px;
  }

  .heading-h5-size {
    font-size: 16px;
  }

  .heading-h6-size {
    font-size: 14px;
  }

  .header-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-nav-list-item.show-in-mobile {
    display: block;
  }

  .header-nav-list-item.show-in-mobile.btn-bottom {
    margin-top: 40px;
  }

  .header-logo-link {
    max-width: 184px;
  }

  .header-logo-link.left {
    padding-right: 32px;
  }

  .hamburger-menu-bar {
    width: 35px;
  }

  .btn-circle-primary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 24px;
  }

  .btn-circle-primary.small {
    width: 42px;
    height: 42px;
    min-height: 42px;
    min-width: 42px;
    font-size: 22px;
  }

  .btn-circle-primary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 32px;
  }

  .btn-circle-secondary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 24px;
  }

  .btn-circle-secondary.small {
    width: 42px;
    height: 42px;
    min-height: 42px;
    min-width: 42px;
    font-size: 22px;
  }

  .btn-circle-secondary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 32px;
  }

  .utility-page-wrap {
    min-height: 60vh;
  }

  .utility-page-wrap._404, .utility-page-wrap.password {
    padding-top: 160px;
    padding-bottom: 80px;
  }

  .utility-page-content, .utility-page-form, .utility-page-form.password-form-wrapper {
    max-width: 100%;
  }

  .flex-horizontal.start.gap-16px.vertical-mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-top {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .footer-bottom.text-right.text-center---tablet.pd-48px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-middle {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .success-message.white-message.newsletter {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .grid-footer-logo---paragraph {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .text-center.text-left---mbl {
    text-align: left;
  }

  .lightbox-video-icon-wrapper {
    width: 20%;
    min-height: 100px;
    min-width: 100px;
  }

  .cta-section {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .cta-section.v2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .cta-section.cta-v2 {
    padding-top: 80px;
  }

  .grid-6-columns.logo-strip {
    max-width: 600px;
    transform: scale(.96);
  }

  .accordion-item-wrapper {
    padding: 40px 24px;
  }

  .accordion-item-wrapper.main {
    padding: 40px 34px;
  }

  .accordion-spacer {
    min-height: 20px;
  }

  .accordion-side {
    margin-right: 16px;
  }

  .accordion-side.right-side {
    margin-left: 16px;
  }

  .accordion-title {
    font-size: 20px;
    line-height: 30px;
  }

  .pd---content-inside-card {
    padding-top: 32px;
    padding-bottom: 40px;
  }

  .pd---content-inside-card.large {
    max-width: 100%;
    padding: 100px 34px 80px;
  }

  .pd---content-inside-card.large.template-pages {
    padding-left: 32px;
    padding-right: 32px;
  }

  .team-member-content {
    max-width: none;
    bottom: 40px;
    left: 32px;
    right: 32px;
  }

  .image.fit-cover.author-image {
    object-position: 50% 20%;
  }

  .blog-card-featured-wrapper.card {
    border-radius: 18px;
  }

  .blog-card-image-wrapper.featured-v2 {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .categories-badges-item-wrapper, .categories-badges-wrapper {
    grid-column-gap: 14px;
    flex-wrap: wrap;
  }

  .blog-card-featured-inner-content {
    padding: 32px 34px 48px;
  }

  .blog-card-content-inside {
    max-width: 550px;
  }

  .image-wrapper.footer-cta-image {
    width: 100%;
    max-width: 75%;
    margin-top: -88px;
    margin-bottom: -48px;
    margin-left: 0%;
  }

  .image-wrapper.wallet-right-image {
    max-width: none;
  }

  .image-wrapper.wallet-left-image {
    max-width: none;
    border-radius: 12px;
  }

  .image-wrapper.wallet-bottom-left-image {
    max-width: none;
  }

  .image-wrapper.cta-v1-top-image {
    margin-top: 0%;
  }

  .image-wrapper.cta-v1-bottom-image {
    top: 36%;
  }

  .image-wrapper.cta-v2-image-wrapper {
    max-width: 100%;
  }

  .image-wrapper.our-story-image {
    width: 106%;
    max-width: 500px;
    min-width: auto;
  }

  .image-wrapper.blog-image-wrapper {
    border-radius: 18px;
  }

  .image-wrapper.author-card-image {
    max-height: 350px;
  }

  .image-wrapper.product-image-wrapper {
    max-width: 90px;
    margin-bottom: 16px;
  }

  .image-wrapper.career-hero-float-right {
    right: 0%;
  }

  .image-wrapper.coming-soon-top-left-float {
    max-width: 18%;
    top: 0%;
    left: 0%;
  }

  .image-wrapper.coming-soon-top-right-float {
    max-width: 20%;
    right: 0%;
  }

  .image-wrapper.coming-soon-bottom-left-float {
    max-width: 17%;
    left: 0%;
  }

  .image-wrapper.coming-soon-bottom-right {
    max-width: 10%;
    right: 0%;
  }

  .image-wrapper.section-featured-float-left {
    top: 88%;
    left: 0%;
  }

  .image-wrapper.section-featured-float-right {
    top: 0%;
  }

  .image-wrapper.order-list-image {
    max-width: 50px;
  }

  .cart-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .cart-container {
    max-width: 100%;
    border-radius: 18px;
  }

  .checkout-form {
    max-width: 100%;
  }

  .home-pages-cointainer {
    margin-top: 80px;
  }

  .image-home-link-wrapper {
    max-width: 209px;
  }

  .figma-file-text-wrapper {
    padding-left: 48px;
    padding-right: 48px;
  }

  .badge-home {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    line-height: 22px;
  }

  .home-surprises-card {
    border-radius: 18px;
  }

  .card-home-pages-wrapper {
    align-self: stretch;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .mg-bottom-12px {
    margin-bottom: 10px;
  }

  .cta-image-card {
    width: 980px;
    max-width: 980px;
  }

  .header-social-media-wrapper {
    display: none;
  }

  .grid-footer-v1---4-column {
    grid-column-gap: 40px;
    grid-template-columns: minmax(150px, .45fr) auto;
  }

  .footer-cta-wrapper {
    flex-direction: column;
  }

  .footer-title {
    font-size: 18px;
  }

  .footer-main-pages-wrapper {
    grid-column-gap: 40px;
    grid-row-gap: 18px;
    grid-template-columns: auto 1fr;
  }

  .footer-main-pages-wrapper.v2 {
    grid-column-gap: 48px;
  }

  .footer-cta-content-top {
    width: 100%;
    max-width: 100%;
    padding: 40px 32px 24px;
  }

  .social-media-icon-wrapper {
    align-items: center;
  }

  .footer-cta-wrapper-v2 {
    padding: 48px 34px;
  }

  .grid-footer-4-columns-v2 {
    grid-column-gap: 26px;
    grid-template-columns: auto auto .75fr;
  }

  .hero-image-container {
    margin-top: 16%;
  }

  .wallet-image-container {
    max-width: 500px;
    min-width: auto;
  }

  .security-image-container {
    width: 100%;
    max-width: 500px;
    min-width: auto;
  }

  .security-card-content.large {
    padding: 40px 24px;
  }

  .feature-card {
    padding: 48px 32px;
  }

  .feature-card.large {
    padding-top: 48px;
  }

  .feature-card.top-right {
    border-right-width: 0;
  }

  .feature-card.middle {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .cta-v1-content-left-wrapper {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .lightbox-wrapper {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .tab-link {
    padding: 40px 32px;
  }

  .tab-content-wrapper {
    max-width: 100%;
  }

  .tab-image-wrapper {
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    transform: scale(1);
  }

  .slider-phone {
    right: 60px;
  }

  .integration-badge, .integration-badge.slider-bagde {
    top: 48px;
  }

  .slider-arrow {
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
  }

  .slider-arrow.right {
    right: -60px;
  }

  .slider-arrow.left {
    left: -60px;
  }

  .slider-arrow.timeline-right-arrow {
    top: auto;
    bottom: -102px;
    left: 50%;
    right: auto;
    transform: translate(8px);
  }

  .slider-arrow.timeline-left-arrow {
    top: auto;
    bottom: -102px;
    left: auto;
    right: 50%;
    transform: translate(-8px);
  }

  .cta-content {
    max-width: 500px;
  }

  .team-member {
    border-bottom: 1px solid #2f2f2f;
  }

  .team-member:last-child {
    border-bottom-width: 0;
  }

  .values-content-wrapper {
    grid-column-gap: 20px;
    flex-direction: row;
    padding: 48px 34px;
  }

  .values-content-wrapper.top-left {
    border-right-style: none;
  }

  .values-content-wrapper.bottom-left {
    border-bottom: 1px solid #2f2f2f;
    border-right-style: none;
  }

  .values-image {
    max-width: 90px;
  }

  .timeline-slider {
    margin-bottom: 102px;
  }

  .testimonial-slide-card-wrapper {
    padding-left: 34px;
    padding-right: 34px;
  }

  .testimonial-slide-card-wrapper.left {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }

  .testimonial-slide-card-wrapper.right {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }

  .team-member-content-left {
    bottom: 40px;
    left: 32px;
  }

  .team-member-contert-right {
    flex-direction: column;
  }

  .team-member-contert-top-right {
    padding-top: 40px;
    padding-bottom: 34px;
  }

  .team-member-content-bottom-right {
    border-top-style: solid;
    border-left-style: none;
    padding-top: 34px;
    padding-bottom: 40px;
  }

  .author-card-content-left {
    left: 34px;
    right: 34px;
  }

  .author-card-content-top-right {
    padding-bottom: 40px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .author-card-content-right {
    border-top: 1px solid #2f2f2f;
    border-left-width: 0;
  }

  .integration-information-top {
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .integration-information-bottom {
    padding-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .integration-single-image {
    max-height: 100px;
    max-width: 100px;
  }

  .product-card-content-top {
    width: 100%;
    border-bottom-width: 1px;
    border-right-width: 0;
    padding: 40px 34px 34px;
  }

  .product-card-content-bottom {
    width: 100%;
    padding: 34px 34px 40px;
  }

  .career-hero-image-wrapper {
    max-width: 72%;
    margin-left: auto;
    margin-right: auto;
  }

  .perk-wrapper {
    padding: 54px 34px;
  }

  .perk-wrapper.top-left, .perk-wrapper.top-right, .perk-wrapper.bottom-left {
    border-right-width: 0;
  }

  .perk-wrapper.bottom-center {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .testimonial-grid-vertical {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .career-card {
    padding: 40px 34px;
  }

  .contact-card-wrapper {
    padding: 54px 34px;
  }

  .coming-soon-image-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .sign-card-content {
    padding-left: 34px;
    padding-right: 34px;
  }


  .dropdown-main-pages-wrapper {
    flex-wrap: wrap;
  }

  .sign-up-form {
    min-height: 344px;
  }

  .demo-form {
    min-height: 446px;
  }

  .footer-logo-link {
    max-width: 174px;
  }

  .footer-cta-content-top---wrapper {
    max-width: 100%;
  }

  .text-center---mbl {
    text-align: center;
  }

  .dot-span {
    top: -16px;
  }

  .form-button-inside {
    min-height: 68px;
  }

  .author-card-content-bottom-right {
    padding-left: 34px;
    padding-right: 34px;
  }

  .rich-text-v2 img {
    border-radius: 18px;
  }

  .rich-text-v2 h2 {
    margin-bottom: 10px;
    font-size: 36px;
  }

  .rich-text-v2 h3 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .rich-text-v2 figure {
    margin-top: 54px;
    margin-bottom: 54px;
  }

  .rich-text-v2 ul, .rich-text-v2 ol {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .rich-text-v2 h6, .rich-text-v2 h5, .rich-text-v2 h4 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .rich-text-v2 blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-top-31px {
    margin-top: 24px;
  }

  .token-information-wrapper {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .add-cart---main {
    min-height: 140px;
  }

  .career-hero-image---main {
    max-width: 100%;
    margin-bottom: -55%;
  }

  .testimonial-grid-column {
    grid-row-gap: 20px;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .contact-form-block {
    min-height: 616px;
  }

  .success-message-newsletter, .coming-soon-state-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .coming-soon-image-wrapper---main {
    max-width: 100%;
  }

  .coming-soon-image {
    width: 53%;
  }

  .sign-form {
    min-height: 574px;
  }

  .sign-up-form-v1 {
    min-height: 735px;
  }

  .demo-form-block {
    min-height: 745px;
  }

  .password-images-wrapper {
    max-width: 115px;
    margin-bottom: -75px;
  }

  .hidden-on-mbl {
    display: none;
  }

  .testimonial-grid-1-column {
    grid-template-columns: 1fr;
  }

  .flex-vertical---mbl {
    flex-direction: column;
    display: flex;
  }

  .cart-item-image-wrapper {
    max-width: 50px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 32px;
  }

  blockquote {
    border-radius: 16px;
    padding: 63px 24px;
    font-size: 20px;
  }

  .grid-2-columns.form {
    border-radius: 16px;
  }

  .grid-2-columns.contact-grid {
    grid-row-gap: 50px;
  }

  .grid-2-columns.cta-v1 {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.text-left-default.alt, .grid-2-columns.text-right-default.alt, .grid-2-columns.text-left-short {
    grid-row-gap: 50px;
  }

  .grid-2-columns.changelog-item {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.product-page {
    grid-row-gap: 50px;
  }

  .grid-2-columns.security-grid {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.slider-grid {
    grid-row-gap: 50px;
  }

  .grid-2-columns.gap-32px.gap-row-48px {
    grid-row-gap: 32px;
  }

  .grid-2-columns.cta-v2, .grid-2-columns.hero-images-grid, .grid-2-columns.sidebar-right-v1, .grid-2-columns.hero-images-grid-v2, .grid-2-columns.coming-soon-grid {
    grid-row-gap: 50px;
  }

  .card.whats-included, .card.sales-home-page, .card.buy-now, .card.security-card, .card._24px {
    border-radius: 16px;
  }

  .card._24px.add-to-cart-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card._24px.sign-in-card {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .card._24px.demo-card {
    padding: 60px 24px;
  }

  .card.features-card-wrapper {
    border-radius: 16px;
  }

  .card.integration-card {
    padding: 32px 24px;
    position: relative;
  }

  .card.testimonial-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  .style---color-block {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .grid-4-columns {
    grid-template-columns: 1fr;
  }

  .color-neutral-300.mg-bottom-12px.integration-paragraph {
    font-size: 14px;
    line-height: 1.667em;
  }

  .divider, .divider._64px {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .divider.integrations-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-bottom-16px {
    margin-bottom: 8px;
  }

  .mg-bottom-16px.keep, .mg-bottom-24px {
    margin-bottom: 16px;
  }

  .mg-bottom-24px.keep {
    margin-bottom: 24px;
  }

  .mg-top-48px {
    margin-top: 32px;
  }

  .display-1 {
    font-size: 36px;
  }

  .grid-1-column.gap-row-48px.gap-row-100px---mobile {
    grid-row-gap: 90px;
  }

  .grid-1-column.gap-40px.token-information {
    grid-template-columns: 1fr;
  }

  .display-2 {
    font-size: 30px;
  }

  .display-3 {
    font-size: 24px;
  }

  .display-4 {
    font-size: 20px;
  }

  .text-300.price-subheading {
    top: -4px;
  }

  .grid-3-columns, .grid-3-columns.dropdown-grid {
    grid-template-columns: 1fr;
  }

  .style---shadow-card {
    border-radius: 16px;
  }

  .style---heading {
    margin-bottom: 40px;
    padding-top: 66px;
    padding-bottom: 66px;
  }

  .inner-container._350px---mbp {
    max-width: 350px;
  }

  .inner-container._250px---mbp {
    max-width: 250px;
  }

  .style---content-heading {
    border-radius: 16px;
  }

  .style---heading-icon-wrapper {
    width: 58px;
    border-radius: 16px;
    margin-right: 14px;
  }

  .mg-bottom-0._18px-mobile {
    font-size: 18px;
  }

  .style---card-wrapper, .style---components-wrapper {
    border-radius: 16px;
  }

  .style---bg-white {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .style---bg-white.bottom, .style-buttons-dark {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .style-buttons-dark.top {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .btn-primary {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-primary.large {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-primary.inside-input {
    position: static;
  }

  .btn-primary.inside-input.default {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .btn-primary.button-row {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .btn-primary.download-app {
    justify-content: center;
  }

  .btn-primary.download-app.button-row._16px {
    margin-right: 0;
  }

  .btn-primary.wide {
    padding-left: 32px;
    padding-right: 32px;
  }

  .btn-primary.pagination-btn {
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  .btn-secondary {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-secondary.large {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-secondary.sign-in-button {
    padding-left: 16px;
    padding-right: 16px;
  }

  .btn-secondary.pagination-btn {
    margin-left: 0;
    margin-right: 0;
  }

  .input {
    padding-left: 20px;
    padding-right: 20px;
  }

  .input.large.button-inside {
    min-height: 64px;
    padding-right: 20px;
  }

  .input.button-inside {
    margin-bottom: 16px;
  }

  .input.input-form.top {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .input.input-form.bottom {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .input.input-form.top-left {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .input.input-form.bottom-right {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .position-relative.sign-up-image-wrapper {
    width: 150%;
    max-width: none;
  }

  .text-area {
    padding-left: 20px;
    padding-right: 20px;
  }

  .checkbox-field-wrapper {
    font-size: 16px;
    line-height: 20px;
  }

  .checkbox-field-wrapper.large {
    font-size: 18px;
    line-height: 22px;
  }

  .checkbox {
    min-height: 22px;
    min-width: 22px;
  }

  .checkbox.large {
    min-height: 26px;
    min-width: 26px;
  }

  .radio-button-field-wrapper {
    font-size: 16px;
    line-height: 22px;
  }

  .radio-button-field-wrapper.large {
    font-size: 18px;
    line-height: 24px;
  }

  .radio-button {
    min-height: 22px;
    min-width: 22px;
  }

  .radio-button.large {
    min-height: 26px;
    min-width: 26px;
  }

  .social-icon-font.button-icon-left {
    margin-right: 8px;
    font-size: 18px;
  }

  .social-icon-square {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    border-radius: 6px;
    font-size: 14px;
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 58px;
    line-height: 64px;
  }

  .line-square-icon.top-bar-close-icon {
    padding-left: 12px;
    padding-right: 12px;
  }

  .rich-text img {
    border-radius: 16px;
  }

  .rich-text figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text ul, .rich-text ol {
    padding-left: 20px;
  }

  .buttons-row {
    width: 100%;
    flex-direction: column;
  }

  .buttons-row.center.testimonial-button {
    bottom: 0;
  }

  .style---logo-sidebar {
    max-width: 160px;
  }

  .elements---page-hero {
    padding-top: 140px;
  }

  .section.large {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.large.coming-soon-section {
    background-size: auto, 150%;
    padding-top: 60px;
  }

  .section.top {
    padding-top: 140px;
  }

  .section.top.v2 {
    padding-top: 120px;
  }

  .section.template-pages-hero {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .section.template-pages-hero.short {
    padding-bottom: 88px;
  }

  .section.hero-page {
    padding-top: 140px;
  }

  .section.hero-v1 {
    padding-top: 120px;
    padding-bottom: 30px;
  }

  .section.medium.top {
    padding-top: 140px;
  }

  .section.medium.top.hero-bg {
    background-size: 160%;
  }

  .section.integrations-section {
    padding-top: 64px;
  }

  .section.bottom-80px {
    padding-bottom: 24px;
  }

  .section.hero-images, .section.integration-single-hero {
    padding-top: 140px;
  }

  .section.pd-128px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section.hero-v2 {
    padding-top: 140px;
  }

  .section.bg-default {
    background-size: 150%;
  }

  .top-bar-wrapper {
    padding-left: 19px;
    padding-right: 19px;
  }

  .elements---element-title.large {
    margin-top: 53px;
  }

  .heading-h1-size {
    font-size: 32px;
  }

  .heading-h3-size.mg-bottom-0.size-20px-mobile {
    font-size: 20px;
  }

  .header-logo-link {
    max-width: 153px;
  }

  .header-logo-link.left {
    padding-right: 0;
  }

  .hamburger-menu-wrapper {
    margin-left: 16px;
  }

  .hamburger-menu-bar {
    width: 32px;
  }

  .utility-page-wrap {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .utility-page-wrap._404 {
    background-size: 150%;
    padding-top: 140px;
  }

  .utility-page-wrap.password {
    padding-top: 120px;
  }

  .flex-horizontal.start.gap-24px {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .flex-horizontal.start.gap-24px.wrap-mobile {
    grid-row-gap: 30px;
    flex-wrap: wrap;
  }

  .flex-horizontal.start.gap-24px.coming-soon-social-media {
    flex-direction: column;
  }

  .flex-horizontal.start.integration-main-wrap {
    grid-row-gap: 16px;
    flex-wrap: wrap;
  }

  .footer-logo {
    width: 100%;
    max-width: 240px;
  }

  .success-message.white-message.newsletter, .success-message.coming-soon-state {
    border-radius: 16px;
  }

  .icon-list {
    margin-right: 8px;
  }

  .video-bg-image {
    filter: blur(1px);
  }

  .lightbox-video-icon-wrapper {
    min-height: 50px;
    min-width: 50px;
  }

  .cta-section {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .cta-section.cta-v2 {
    padding-top: 64px;
  }

  .grid-6-columns.logo-strip {
    grid-column-gap: 40px;
    grid-row-gap: 20px;
  }

  .accordion-item-wrapper.main {
    padding-left: 24px;
    padding-right: 24px;
  }

  .accordion-side.right-side {
    margin-left: 10px;
  }

  .accordion-title {
    font-size: 18px;
    line-height: 26px;
  }

  .pd---content-inside-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  .pd---content-inside-card.large {
    padding: 90px 24px 60px;
  }

  .pd---content-inside-card.large.template-pages {
    padding-left: 24px;
    padding-right: 24px;
  }

  .pd---content-inside-card.template-pages---sidebar {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .team-member-content {
    grid-row-gap: 10px;
    flex-wrap: wrap;
    bottom: 32px;
    left: 24px;
    right: 24px;
  }

  .blog-card-featured-wrapper.card {
    border-radius: 16px;
  }

  .blog-card-image-wrapper.featured-v2, .blog-card-image-wrapper.inside-card {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .categories-badges-item-wrapper, .categories-badges-wrapper {
    flex-direction: column;
  }

  .blog-card-wrapper.card {
    border-radius: 16px;
  }

  .blog-card-featured-inner-content {
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .blog-card-content-inside {
    padding-left: 24px;
    padding-right: 24px;
  }

  .image-wrapper.footer-cta-image {
    max-width: 95%;
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .image-wrapper.security-image-left.main {
    width: 78%;
  }

  .image-wrapper.feature-image {
    width: auto;
    max-width: none;
    margin-right: -24px;
    position: static;
  }

  .image-wrapper.blog-image-wrapper {
    border-radius: 16px;
  }

  .image-wrapper.product-image-wrapper {
    max-width: 70px;
  }

  .image-wrapper.sign-up-image, .image-wrapper.sign-up-top-left-image {
    left: 20%;
  }

  .image-wrapper.sign-up-bottom-left {
    right: 21%;
  }

  .image-wrapper.sign-up-right-image {
    left: 23%;
  }

  .image-wrapper.team-member-image {
    min-height: 400px;
  }

  .cart-button-wrapper {
    margin-right: 0;
  }

  .cart-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .cart-container {
    border-radius: 0;
  }

  .cart-list {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .cart-item-wrapper {
    flex-direction: column;
  }

  .pay-btn.cart {
    height: 53px;
  }

  .shipping-method {
    flex-direction: column;
  }

  .order-item {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .order-list-title {
    margin-bottom: 4px;
  }

  .order-item-content {
    margin-top: 16px;
    margin-left: 0;
  }

  .checkout-column {
    margin-bottom: 24px;
  }

  .home-pages-cointainer {
    margin-top: 70px;
  }

  .home-pages-top-wrapper {
    margin-bottom: 16px;
  }

  .image-home-link-wrapper {
    max-width: 180px;
    border-radius: 16px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .card-sales-home-image {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .card-sales-home-content, .figma-file-text-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .home-surprises-card {
    border-radius: 16px;
  }

  .card-home-pages-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .grid-footer-v1---4-column {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .footer-main-pages-wrapper {
    grid-column-gap: 32px;
    grid-template-columns: 1fr;
  }

  .footer-cta-content-top {
    padding: 32px 24px 16px;
  }

  .social-media-icon-wrapper.center-mobile {
    width: 100%;
    justify-content: center;
  }

  .footer-cta-wrapper-v2 {
    padding: 32px 24px;
  }

  .grid-footer-4-columns-v2 {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .security-card-content.top-left {
    border-right-width: 0;
  }

  .security-card-content.bottom-left {
    border-bottom: 1px solid #2f2f2f;
    border-right-width: 0;
  }

  .security-card-content.large {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .feature-card {
    padding: 40px 24px;
  }

  .feature-card.large {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .feature-card-large-content {
    margin-bottom: 40px;
  }

  .cta-v1-content-left-wrapper {
    padding-bottom: 10px;
  }

  .lightbox-wrapper {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .tab-link {
    padding-left: 24px;
    padding-right: 24px;
  }

  .tab-content-wrapper {
    grid-column-gap: 16px;
    flex-direction: column;
  }

  .tab-image-wrapper {
    max-height: 70px;
    max-width: 70px;
    min-height: 70px;
    min-width: 70px;
  }

  .tab-icon-wrapper {
    position: absolute;
    top: 60px;
    bottom: auto;
    left: auto;
    right: 24px;
  }

  .tab-body {
    overflow: hidden;
  }

  .feature-image {
    max-width: 60%;
  }

  .slider-frame {
    min-height: 473px;
  }

  .slider-phone {
    left: 5%;
    right: 42px;
  }

  .integration-card-content-top {
    grid-row-gap: 10px;
    align-items: flex-start;
  }

  .integration-badge {
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    position: static;
    top: 32px;
    right: 24px;
  }

  .integration-badge.slider-bagde {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  .slider-arrow {
    max-height: 60px;
    max-width: 60px;
    min-height: 60px;
    min-width: 60px;
  }

  .slider-arrow.right {
    right: -42px;
  }

  .slider-arrow.left {
    left: -42px;
  }

  .slider-arrow.timeline-right-arrow, .slider-arrow.timeline-left-arrow {
    bottom: -95px;
  }

  .integrations-wrapper {
    top: 6%;
    left: 6px;
  }

  .integration-image {
    max-height: 50px;
    max-width: 50px;
    margin-right: 8px;
  }

  .testimonial-content-bottom-wrapper {
    grid-row-gap: 10px;
    flex-wrap: wrap;
  }

  .details-wrapper {
    grid-row-gap: 6px;
    flex-wrap: wrap;
  }

  .details-wrapper.wrap-mobile {
    flex-wrap: wrap;
  }

  .values-content-wrapper {
    flex-direction: column;
    padding: 40px 24px;
  }

  .values-image {
    max-width: 80px;
  }

  .timeline-slider {
    margin-bottom: 95px;
  }

  .testimonial-slide-card-wrapper {
    height: 100%;
    min-height: 300px;
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;
  }

  .testimonial-slide-card-wrapper.left {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .testimonial-slide-card-wrapper.right {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .team-member-content-left {
    bottom: 32px;
    left: 24px;
    right: 24px;
  }

  .team-member-contert-top-right {
    padding-top: 34px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .team-member-content-bottom-right {
    padding-left: 24px;
    padding-right: 24px;
  }

  .author-card-content-left {
    left: 24px;
    right: 24px;
  }

  .author-card-content-top-right, .integration-information-top {
    padding-left: 24px;
    padding-right: 24px;
  }

  .integration-information-bottom {
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
  }

  .integration-single-image {
    max-height: 80px;
    max-width: 80px;
  }

  .product-card-content-top, .product-card-content-bottom {
    padding-left: 24px;
    padding-right: 24px;
  }

  .product-badge-wrapper {
    top: 24px;
    right: 18px;
  }

  .perk-wrapper {
    padding: 48px 24px;
  }

  .career-card {
    grid-column-gap: 8px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .contact-card-wrapper {
    padding: 48px 24px;
  }

  .sign-card-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .dropdown-main-pages-wrapper {
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .sign-up-form {
    min-height: 425px;
  }

  .demo-form {
    min-height: 445px;
  }

  .footer-logo-link {
    max-width: 145px;
  }

  .dot-span {
    top: -13px;
  }

  .form-button-inside {
    min-height: 140px;
  }

  .pagination {
    flex-flow: column;
  }

  .author-card-content-bottom-right {
    padding-left: 24px;
    padding-right: 24px;
  }

  .rich-text-v2 img {
    border-radius: 16px;
  }

  .rich-text-v2 h2 {
    font-size: 30px;
  }

  .rich-text-v2 figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v2 ul, .rich-text-v2 ol {
    padding-left: 20px;
  }

  .add-cart---main {
    min-height: 212px;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .contact-form-block {
    min-height: 666px;
  }

  .sign-form {
    min-height: 545px;
  }

  .sign-up-form-v1 {
    min-height: 695px;
  }

  .demo-form-block {
    min-height: 765px;
  }

  .password-images-wrapper {
    max-width: 28%;
    margin-bottom: -18%;
  }

  .shipping-method-content {
    margin: 15px 0 6px;
  }

  .cart-item {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .cart-item-content {
    margin-top: 16px;
    margin-left: 0;
  }
}

#w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa4d9-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa4e0-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa4e7-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa505-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa50b-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa511-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa517-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa51f-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa525-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa52b-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa533-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa539-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa541-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa547-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa54f-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa565-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa56d-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa575-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa57d-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa585-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa58d-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5a1-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5a8-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5ab-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5ad-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5b5-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5c2-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5c5-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5c7-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5cf-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5d6-42a43302 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5db-42a43302 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5e5-42a43302 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e3565173-e9d2-3001-847c-69d20877c713-0877c70f {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-e3565173-e9d2-3001-847c-69d20877c71d-0877c70f {
  grid-area: span 1 / span 3 / span 1 / span 3;
  align-self: start;
}

#w-node-_46509bcb-0cb3-fd48-0523-4ea31dbd3400-74388f57 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-ff2c4e6c-0520-d72b-ad85-4e70e78784ff-74388f57, #w-node-_86ec4c97-e25c-0ab3-6ea9-aba445ffea1a-74388f57, #w-node-_58ccf660-aee3-5f7b-d573-fc528ffccf51-74388f57 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_58ccf660-aee3-5f7b-d573-fc528ffccf52-74388f57, #w-node-_58ccf660-aee3-5f7b-d573-fc528ffccf54-74388f57 {
  justify-self: start;
}

#w-node-_89d425e1-a721-d4c5-275f-bb7712de3ee2-74388f57, #w-node-f04dd387-65ca-6576-f568-541e1b796ce1-74388f57, #w-node-_743ca011-2ed2-fcd3-20b1-2e503c8d462a-74388f57, #w-node-_7d04ee4e-ffc7-7327-90fe-fbab6f1b4a86-74388f57, #w-node-_4920cb9f-9b0f-3600-faf8-c57b6c368768-74388f57, #w-node-_3dbe68c4-8d7e-3103-fce1-4ce44c672d23-74388f57, #w-node-_160dfc50-735b-5420-591c-f431193c8736-74388f57, #w-node-_160dfc50-735b-5420-591c-f431193c8738-74388f57, #w-node-_160dfc50-735b-5420-591c-f431193c873a-74388f57, #w-node-_160dfc50-735b-5420-591c-f431193c873c-74388f57, #w-node-_12b159f9-b7b6-b2f9-956d-f9e1270120f2-74388f57, #w-node-b8bbf89b-0f46-5496-6d6e-785223da5983-74388f57, #w-node-_4fb4b617-c160-0860-1dd4-cf200c9d7ebc-42a4331b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a765fa0e-83b5-6555-fc1e-0ad771841e1d-42a4331b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8012c7fb-f0b2-9669-b389-e3f7ce5e98e7-42a4331b, #w-node-_8012c7fb-f0b2-9669-b389-e3f7ce5e98ee-42a4331b, #w-node-_8012c7fb-f0b2-9669-b389-e3f7ce5e98f5-42a4331b, #w-node-_8012c7fb-f0b2-9669-b389-e3f7ce5e98fc-42a4331b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4de1384b-2e58-c1ea-4d8d-c572bea57309-42a4331b {
  justify-self: end;
}

#w-node-_9a32fd0f-84c5-1712-2c0a-1215274a67c5-42a4331b {
  align-self: end;
}

#w-node-_145d997f-09ec-de5e-a72f-47a645ebcc9d-42a4331b {
  align-self: end;
  justify-self: end;
}

#w-node-_23127cce-e9db-ab6e-16a5-98e2321fb985-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb986-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb996-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9a6-42a4331b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9a7-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9b7-42a4331b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9c7-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9c8-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9d8-42a4331b, #w-node-_1ff94680-e1f2-e521-1d8b-7d1b62565673-42a4331b, #w-node-_7ce19f06-5bb0-b958-5710-6dcc57fbe931-42a4331b, #w-node-_6907853c-6370-0c03-83ea-349da4bb9be3-42a4331b, #w-node-c0a2e832-43a9-6635-2a62-646ae86f1039-42a4331b, #w-node-d689f168-1099-cdd8-9899-3ca78d5e05a6-8d5e05a3, #w-node-d689f168-1099-cdd8-9899-3ca78d5e05ba-8d5e05a3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8b8e7523-b0bc-89fe-8310-a4361fba6eb8-42a4331d, #w-node-_8b8e7523-b0bc-89fe-8310-a4361fba6eba-42a4331d {
  align-self: stretch;
}

#w-node-_2b8ed19d-c0c1-7b74-5a8d-af5865ca232b-42a4331d {
  align-self: end;
  justify-self: end;
}

#w-node-b64bfa2d-1b1e-2c15-731c-eb7486f56287-42a4331d {
  align-self: stretch;
}

#w-node-_4428f0b7-624d-ebe9-7445-1aab55d9edda-5b1e69c5, #w-node-_3b3a7ac0-419b-32ff-bc8e-22e2c084bb1c-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a5473699-1adb-0a68-4a2c-a39de3d56b72-42a43321 {
  align-self: stretch;
}

#w-node-_332bcbd3-7714-feae-5362-58becf64b036-42a43321, #w-node-_5e42e6fd-cd19-b8d4-ecc0-e2dcdc7dd7ae-42a43321 {
  align-self: end;
}

#w-node-_7030aa40-2046-1dd2-e89b-be3a61109f05-42a43321, #w-node-_0bd5b0d0-be98-6a66-4c70-c503098105b5-42a43321, #w-node-_9984ff1e-2e73-1a88-ecdf-3ad0e7dc65de-42a43321, #w-node-_4528ebe1-b4ee-56f4-f7a4-c7ada7ad2be9-42a43321, #w-node-c1b95aaf-626c-04e5-2b54-943273cd939e-42a43321, #w-node-b5274f1d-b98a-dbdc-2d4a-8d1939b75449-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_46aa0cb8-18c8-7716-b25c-8ec5c2c806fd-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-b1634343-04f3-fd70-91cd-043dacd317b2-42a43321, #w-node-f85f193e-9226-6996-7c5a-cf2f6aaad6a4-42a43321, #w-node-d35a16e8-af13-b5e0-6d60-e43cd189a44d-42a43321, #w-node-eb52453e-c588-87ca-1974-97b368d50948-42a43321, #w-node-eb52453e-c588-87ca-1974-97b368d5096c-42a43321, #w-node-eb52453e-c588-87ca-1974-97b368d5098c-42a43321, #w-node-f70c528c-f532-d35d-30aa-9d2ad0df5cc8-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0291bf6c-f376-b804-e772-b8ecae7f6735-42a43321, #w-node-e7615a4b-3c96-dd49-1a92-9d568e10b9f7-42a43321, #w-node-_5d43933e-ad58-dcd3-86b0-f7008127cd17-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_229c1706-e1ca-ce7a-7351-a76eab337108-42a43321, #w-node-aa46edee-86db-a254-908c-71a8f32292de-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4be096a4-6783-b81e-184e-9ce4e245e8ee-42a43321 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1850eca6-9777-7470-dd54-db554685eb93-42a43321 {
  align-self: start;
}

#w-node-aee127df-1f5f-1759-698f-b0e3e08ecd86-42a43324, #w-node-_0c31133e-590c-0e88-9d50-f60151d12c37-42a43324 {
  order: -9999;
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_59b5e8e7-45bb-4915-40fb-e48dfbbc1d4e-42a43324 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
  justify-self: center;
}

#w-node-b6d9d684-65fd-669e-6f30-09beed726f7b-42a43324 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: center;
}

#w-node-_218889cb-cd43-1c46-3a29-b20874b07d86-42a43325, #w-node-_9b7515a0-7cfa-731b-c65a-8c695efa9698-42a43325 {
  justify-self: start;
}

#w-node-_111d0742-1c68-c3f8-2732-86d768991d13-42a43325 {
  justify-self: end;
}

#w-node-_111d0742-1c68-c3f8-2732-86d768991d27-42a43325 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_111d0742-1c68-c3f8-2732-86d768991d27-42a43329090{
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_111d0742-1c68-c3f8-2732-86d768991d2b-42a43325 {
  justify-self: start;
}

#w-node-_5b7f055b-2cbe-48ba-4b65-cb6eda3da67f-42a43325, #w-node-a4574d5b-da46-9045-0e35-d450bf795a8b-42a43325, #w-node-b5637138-40ad-9507-3bf8-7fc1685d11aa-42a43325 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_493bd0a9-9dfe-9fcc-b6b9-5479a3ae2ff6-42a43325 {
  align-self: start;
}

#w-node-_37c19fb0-9214-8e1e-efb3-d910fec28137-42a4333d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_66570e75-c2ad-ce00-1b06-da252a835767-42a4333d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0eab14a7-b3aa-382e-9790-6b455e12ab79-42a4333d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_110da81c-ba24-af4d-fb28-350e68bd2ef4-42a4333d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_773d8468-46f0-81be-afcc-f0d3443ec5d8-42a4333d, #w-node-_638d7e69-a4d0-89d7-cfb3-91c74f5b7eea-42a4333e {
  align-self: stretch;
}

#w-node-_967a86d6-3f3c-0d35-676a-05dd5a61f261-42a4333f, #w-node-_43f90f36-07db-7bbb-b0b9-44ea69ba8002-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_9d2ff0d4-e11d-0f4b-0e28-93ec8cdafb41-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_7ef798a7-50b2-dec3-f4ad-a337899dc688-42a4333f, #w-node-_202e6150-e1ad-66fc-0837-d4c60b30eca1-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_202e6150-e1ad-66fc-0837-d4c60b30eca3-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c2e3b667-48ba-bce1-b421-b7bfa84d035d-42a4333f, #w-node-_67f502bd-6347-2e97-5960-8123c0bca162-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_67f502bd-6347-2e97-5960-8123c0bca164-42a4333f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_139da7ca-b1b9-7270-4f19-115a53cce021-42a43340 {
  align-self: end;
}

#w-node-_31bc2ed4-bffe-8e8c-d65c-9b2d1f8f8867-42a43341 {
  align-self: start;
}

#w-node-_6665e674-7d76-7e1b-e750-c3dc45daf369-42a43341 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
  justify-self: end;
}

#w-node-_0ce4b8fb-31a5-5ffd-7625-88ebab04446c-42a43341, #w-node-_1e48b455-fdba-c87c-404c-364768cb4afe-42a43341, #w-node-_3dd4b819-3b02-3e28-fb46-bf5e31c52e64-42a43341, #w-node-ac0d1f32-48c9-8a03-ea54-586fbc17b1f3-42a43341, #w-node-_7ebef5cc-4020-0d8b-4a22-5736647ba0ec-42a43341 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a8edfe1f-212b-6260-474f-f00a50e23c70-42a43341 {
  justify-self: end;
}

#w-node-_0be85de7-69eb-30e6-682d-1cb88ef2da53-42a43341, #w-node-_57436ed5-6523-7838-67d4-8f4db7019544-42a43341 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2f7c441c-f746-e6e5-1488-e3624cf54ad9-42a43341 {
  align-self: stretch;
}

#w-node-_17b666a1-9096-2fc9-f0c5-f4be96d03d86-42a43342 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-dbf466ca-8f17-e78b-fa11-038c0e2b26a8-42a43342 {
  justify-self: end;
}

#w-node-e4668d05-42ec-469f-450a-7d79cc99a423-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_79c0478e-d137-d87e-80c7-118c8139505e-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_82382069-2c66-c6f3-9efc-db93b9b1609e-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_4d2a0919-d08f-6e18-13aa-ea445a67f193-42a43344, #w-node-_8c7b8cc7-3fed-6719-a084-3278d614e919-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-e373db94-a31d-7882-7612-b44a099d7438-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5fee4328-f680-f087-5741-56abe98cc5ac-42a43344 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_78d8d014-1bb9-4ffb-1070-b7302ac2df57-42a43344 {
  align-self: stretch;
}

#w-node-_86735add-90bf-fd40-e933-27a6542f0fbb-42a4335d {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_26795409-f2ab-40c2-739d-d789fd775acb-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775acc-42a4335d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_26795409-f2ab-40c2-739d-d789fd775ae5-42a4335d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_26795409-f2ab-40c2-739d-d789fd775aee-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775aef-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775af5-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775b04-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775b0b-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775b12-42a4335d, #w-node-_26795409-f2ab-40c2-739d-d789fd775b19-42a4335d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3d71fe17-fb83-8e40-d654-744fd8e60a1e-d8e60a04 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-d93d3855-73cc-7d48-e014-7a066164a8ba-65ef080b {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-d93d3855-73cc-7d48-e014-7a066164a8be-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8cb-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8d6-65ef080b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-d93d3855-73cc-7d48-e014-7a066164a8d7-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8d9-65ef080b {
  justify-self: start;
}

#w-node-d93d3855-73cc-7d48-e014-7a066164a8e0-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8e4-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8e6-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8e8-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8ea-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8ec-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8f0-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8f2-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8f4-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8f6-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8f8-65ef080b, #w-node-d93d3855-73cc-7d48-e014-7a066164a8fa-65ef080b, #w-node-_6628db97-6a5a-5ed8-1808-23655e3af232-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccde2-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccdf8-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f0732de4-544d-19c1-f5b0-954f33ae264f-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce02-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce08-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce11-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce18-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce1f-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce26-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce39-42a43363 {
  align-self: end;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce3f-42a43363 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce67-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce72-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce8e-42a43363 {
  align-self: stretch;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce93-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccec6-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cced2-42a43363, #w-node-d0727e0d-d938-419a-1cc9-45bd2a31b767-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccf8f-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfb9-42a43363, #w-node-f002b7f2-c2c1-bee5-4a25-730572e767ec-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccf9d-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfc7-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_966f8a0b-66e5-5826-bbb0-e873946581b5-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfab-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfd5-42a43363 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfee-42a43363 {
  justify-self: end;
}

#w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccff3-42a43363 {
  align-self: stretch;
}

#w-node-_966cd335-390a-c6ad-3dd5-2d5ba0aba2ea-42a43365 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_491d1e55-e0e2-d481-95c8-8a20b279993a-42a43365 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: stretch;
}

#w-node-_62e3a805-8cb3-7efb-a7f6-5f0f4ed9aa08-42a43365 {
  align-self: stretch;
}

#w-node-_28e291e7-7068-90ae-06e1-95839ef3d6bf-42a4336e {
  align-self: end;
}

#w-node-_4e9309ad-6939-352c-3132-92020ae95147-42a4336e {
  align-self: start;
}

#w-node-_31f5ccb9-eeb2-fd20-188b-8f937bae7d6f-42a4336e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_94893c30-5a57-5570-921e-530e09dc8bea-42a43374, #w-node-_732710bf-e7e5-6b49-9cd9-76e21b62d7e4-42a43374, #w-node-_46014353-f034-a7ae-3477-203e24d9a2aa-42a43374 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
}

#w-node-_4e9309ad-6939-352c-3132-92020ae95147-42a43374 {
  align-self: start;
}

#w-node-_31f5ccb9-eeb2-fd20-188b-8f937bae7d6f-42a43374 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e84cf130-8f50-89e0-e46f-b98d42069d35-42a43385 {
  justify-self: start;
}

#w-node-_61914fa1f3028ad34c1c480b000000000014-42a43386 {
  align-self: stretch;
}

#w-node-f1bc498d-290c-3270-f633-c82f5b0ae8f3-42a43387 {
  justify-self: start;
}

#w-node-_2cb2cbd8-8a21-e8ae-4599-19c0a5d5f9fe-42a43388, #w-node-fcd46c2b-9dad-6bdd-73a7-0a6c548e3aae-42a43388 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_7481ab2a-57a8-39f6-3e37-925e5bd0cc4e-42a43388 {
  justify-self: start;
}

#w-node-_2bc0a067-fea3-51d5-38b4-db409b34db9a-42a43388 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
  justify-self: end;
}

#w-node-a44792ea-eb67-509b-1b2d-2d8a6da96e19-42a43388, #w-node-_48179c42-8780-db30-ba01-1c22fea07ab4-42a4339e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (min-width: 1920px) {
  #w-node-_7481ab2a-57a8-39f6-3e37-925e5bd0cc3e-42a43388 {
    justify-self: stretch;
  }
}

@media screen and (min-width: 1440px) {
  #w-node-e3565173-e9d2-3001-847c-69d20877c713-0877c70f {
    grid-area: span 2 / span 1 / span 2 / span 1;
  }

  #w-node-_1850eca6-9777-7470-dd54-db554685eb93-42a43321, #w-node-fb2f252d-2a0b-2aba-3cbe-192e8653d172-42a43321 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: start;
  }

  #w-node-e373db94-a31d-7882-7612-b44a099d7438-42a43344 {
    justify-self: end;
  }
}

@media screen and (max-width: 991px) {
  #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5ad-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5c7-42a43302 {
    order: -9999;
  }

  #w-node-e3565173-e9d2-3001-847c-69d20877c713-0877c70f {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-e3565173-e9d2-3001-847c-69d20877c71d-0877c70f {
    grid-column: span 2 / span 2;
  }

  #w-node-_619f19bf53fba71664558e2800000000000b-42a43319 {
    justify-self: center;
  }

  #w-node-_421fc606-a787-6166-95b6-090642653b44-42a43319 {
    order: 9999;
  }

  #w-node-_9a32fd0f-84c5-1712-2c0a-1215274a67c5-42a4331b, #w-node-_145d997f-09ec-de5e-a72f-47a645ebcc9d-42a4331b {
    justify-self: center;
  }

  #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb985-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9a6-42a4331b, #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9c7-42a4331b {
    grid-column: span 2 / span 2;
    align-self: stretch;
  }

  #w-node-d689f168-1099-cdd8-9899-3ca78d5e05a6-8d5e05a3, #w-node-d689f168-1099-cdd8-9899-3ca78d5e05ba-8d5e05a3, #w-node-_2b8ed19d-c0c1-7b74-5a8d-af5865ca232b-42a4331d, #w-node-a5473699-1adb-0a68-4a2c-a39de3d56b72-42a43321, #w-node-_229c1706-e1ca-ce7a-7351-a76eab337108-42a43321 {
    justify-self: center;
  }

  #w-node-_4be096a4-6783-b81e-184e-9ce4e245e8ee-42a43321 {
    order: -9999;
    justify-self: center;
  }

  #w-node-_1850eca6-9777-7470-dd54-db554685eb93-42a43321, #w-node-_7ebdd7aa-892d-aa45-93ee-3879e70fc69d-9234ebf4 {
    justify-self: center;
  }

  #w-node-_493bd0a9-9dfe-9fcc-b6b9-5479a3ae3001-42a43325 {
    order: 9999;
  }

  #w-node-a8edfe1f-212b-6260-474f-f00a50e23c70-42a43341 {
    justify-self: start;
  }

  #w-node-_26795409-f2ab-40c2-739d-d789fd775ae5-42a4335d {
    justify-self: center;
  }

  #w-node-_3d71fe17-fb83-8e40-d654-744fd8e60a1e-d8e60a04 {
    grid-column: span 3 / span 3;
  }

  #w-node-_85016d80-d8f2-00a2-5639-ebc90fba553c-d8e60a04, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccdf8-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce67-42a43363 {
    justify-self: center;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce72-42a43363 {
    order: -9999;
    justify-self: center;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce73-42a43363 {
    order: -9999;
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: end;
  }

  #w-node-d0727e0d-d938-419a-1cc9-45bd2a31b767-42a43363, #w-node-f002b7f2-c2c1-bee5-4a25-730572e767ec-42a43363, #w-node-_966f8a0b-66e5-5826-bbb0-e873946581b5-42a43363 {
    grid-column: span 2 / span 2;
    align-self: stretch;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfea-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1ccfee-42a43363 {
    justify-self: center;
  }

  #w-node-_2bc0a067-fea3-51d5-38b4-db409b34db9a-42a43388 {
    justify-self: auto;
  }
}

@media screen and (max-width: 767px) {
  #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5ad-42a43302, #w-node-cbeeec54-468e-7ae0-5a5c-e6cdc66fa5c7-42a43302 {
    order: -9999;
  }

  #w-node-e3565173-e9d2-3001-847c-69d20877c713-0877c70f, #w-node-_46509bcb-0cb3-fd48-0523-4ea31dbd3400-74388f57 {
    grid-column: span 2 / span 2;
  }

  #w-node-_10f80058-3a2b-e35f-758f-8e756964f85d-42a4331b {
    order: 9999;
    justify-self: center;
  }

  #w-node-_23127cce-e9db-ab6e-16a5-98e2321fb9c7-42a4331b {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: stretch;
  }

  #w-node-_8b8e7523-b0bc-89fe-8310-a4361fba6eba-42a4331d {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_229c1706-e1ca-ce7a-7351-a76eab337108-42a43321 {
    justify-self: stretch;
  }

  #w-node-_111d0742-1c68-c3f8-2732-86d768991d27-42a43325 {
    grid-column: span 1 / span 1;
  }

  #w-node-b5637138-40ad-9507-3bf8-7fc1685d11aa-42a43325 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_86735add-90bf-fd40-e933-27a6542f0fbb-42a4335d {
    grid-column: span 1 / span 1;
  }

  #w-node-_26795409-f2ab-40c2-739d-d789fd775aef-42a4335d {
    justify-self: center;
  }

  #w-node-_26795409-f2ab-40c2-739d-d789fd775af5-42a4335d {
    order: -9999;
  }

  #w-node-_3d71fe17-fb83-8e40-d654-744fd8e60a32-d8e60a04 {
    order: 9999;
  }

  #w-node-d93d3855-73cc-7d48-e014-7a066164a8ba-65ef080b {
    grid-column: span 2 / span 2;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce02-42a43363 {
    justify-self: center;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce08-42a43363 {
    order: -9999;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce3f-42a43363 {
    grid-column: span 1 / span 1;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce67-42a43363 {
    justify-self: start;
  }

  #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce72-42a43363, #w-node-cd49c5f0-c3b7-c48d-73ff-0422dd1cce73-42a43363 {
    order: -9999;
  }

  #w-node-_966f8a0b-66e5-5826-bbb0-e873946581b5-42a43363 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    align-self: stretch;
  }

  #w-node-_7481ab2a-57a8-39f6-3e37-925e5bd0cc3e-42a43388 {
    justify-self: stretch;
  }

  #w-node-_2cb2cbd8-8a21-e8ae-4599-19c0a5d5f9fe-42a43388, #w-node-fcd46c2b-9dad-6bdd-73a7-0a6c548e3aae-42a43388 {
    grid-column: span 1 / span 1;
  }

  #w-node-e3a90f75-d470-7097-bbbe-55a933809da3-42a4339e, #w-node-_4d23d9c3-32aa-001b-0596-e5a65931aef9-42a4339e, #w-node-_5d0cf41d-1172-6000-505c-5abc2306569e-42a4339e, #w-node-ae36a11d-9aa6-9b99-1c4e-812c3ccf1b6f-42a4339e, #w-node-_529f5180-557e-0011-a8c7-d413bd690ec5-42a4339e, #w-node-_529f5180-557e-0011-a8c7-d413bd690ed0-42a4339e, #w-node-_529f5180-557e-0011-a8c7-d413bd690edb-42a4339e, #w-node-_529f5180-557e-0011-a8c7-d413bd690ee6-42a4339e, #w-node-_3e7a3750-203b-4f65-b2ff-7193010c307e-42a4339e, #w-node-_820331d6-c4e7-e22c-17e6-307c4ba0cf79-42a4339e, #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d263-42a4339e, #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d26e-42a4339e, #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d279-42a4339e, #w-node-cd97ed09-7ee7-f868-9f24-92008cbffbf9-42a4339e, #w-node-_678fa4ae-1fca-b02f-fd27-9efe550463d6-42a4339e, #w-node-_053b3848-5278-b0d0-565f-4e26607311a1-42a4339e, #w-node-_8638ecc7-fc7a-8770-ed2b-943d852abcf3-42a4339e, #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0a9-42a4339e, #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0bd-42a4339e, #w-node-fdedfdaf-07eb-26ce-ec5a-44f9429fbce6-42a4339e, #w-node-e5098d5c-99e5-a310-1707-51b579e0852f-42a4339e, #w-node-_1d39ea8e-bae3-1512-4ef0-cdf93fc0f8da-42a4339e, #w-node-_0959ac05-e5ad-fd43-8e7e-a09dd2f57df9-42a4339e, #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0d1-42a4339e, #w-node-f9a626ae-4f05-7075-0d66-3072978dbb53-42a4339e, #w-node-_5e353be1-d012-39fc-eeb8-2763382b8e35-42a4339e {
    order: -9999;
  }
}

@media screen and (max-width: 479px) {
  #w-node-e3565173-e9d2-3001-847c-69d20877c713-0877c70f, #w-node-e3565173-e9d2-3001-847c-69d20877c71d-0877c70f {
    grid-column: span 1 / span 1;
  }

  #w-node-e3565173-e9d2-3001-847c-69d20877c741-0877c70f {
    order: 9999;
  }

  #w-node-_46509bcb-0cb3-fd48-0523-4ea31dbd3400-74388f57 {
    grid-column: span 1 / span 1;
  }

  #w-node-_86ec4c97-e25c-0ab3-6ea9-aba445ffea1a-74388f57 {
    order: 2;
  }

  #w-node-_58ccf660-aee3-5f7b-d573-fc528ffccf51-74388f57 {
    order: 9998;
  }

  #w-node-_10f80058-3a2b-e35f-758f-8e756964f85d-42a4331b {
    justify-self: start;
  }

  #w-node-_2b8ed19d-c0c1-7b74-5a8d-af5865ca232b-42a4331d {
    justify-self: stretch;
  }

  #w-node-ee656db5-0d27-f062-3870-55aa99aadfbe-42a43322 {
    order: 9999;
  }

  #w-node-_3d71fe17-fb83-8e40-d654-744fd8e60a1e-d8e60a04 {
    grid-column: span 1 / span 1;
  }

  #w-node-_3d71fe17-fb83-8e40-d654-744fd8e60a42-d8e60a04 {
    order: 9999;
  }

  #w-node-d93d3855-73cc-7d48-e014-7a066164a8ba-65ef080b {
    grid-column: span 1 / span 1;
  }

  #w-node-d93d3855-73cc-7d48-e014-7a066164a8cb-65ef080b {
    order: 2;
  }

  #w-node-d93d3855-73cc-7d48-e014-7a066164a8d6-65ef080b {
    order: 9998;
  }

  #w-node-_2cb2cbd8-8a21-e8ae-4599-19c0a5d5f9fe-42a43388, #w-node-fcd46c2b-9dad-6bdd-73a7-0a6c548e3aae-42a43388 {
    grid-column: span 1 / span 1;
  }
}

.perk-tab-inside{
  padding: 12px;
  border: 1px solid #2f2f2f !important;
}

.perk-tab-inside a {
  color: #e7e7e7;
}

.phone_number{
  display: flex;
}

.phone_number select {
  width: 35%;
  margin-right: 0px;
  /* font-size: 15px; */
  padding: 13px;
  padding-left: 24px;
}
 
.phone_number select option{
  background: #b0f127;
  color: #000;
}



/* tab view */

/* Hide all tab content by default */
.tabcontent {
  display: none;
}

/* Style the tab buttons */
.tab button {
  background-color: #060606;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}

/* Style the active tab button */
.tab button.active {
    background-color: #b0f127;
    color: #2f2f2f;
}

/* Style the tab content */
.tabcontent {
  margin-top: 20px;
}

.tab_view{
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f2f2f;
  border-bottom: 8px solid #b0f127;
  padding: 25px;
}

.tab_view .tab{
  display: flex;
  margin-bottom: 20px;
}

span.help-block.form-error {
  color: rgb(185, 74, 72);
}



/* Modal Content */
.modal_contactUs.modal{
  display: none;
    position: fixed;
    z-index: 9999 !important;
    overflow: auto;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
}
.modal_contactUs .modal-content {
  background-color: #060606 !important;
  margin: 1% auto !important;
  padding: 20px !important;
  border: 1px solid #888 !important;
  max-width: 600px !important;
  min-height: 742px !important;
}

/* Close Button */
.modal_contactUs .btn-primary:hover{
  color: #060606 !important;
}

.modal_contactUs .close {
  color: #aaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
  margin-left: auto;
}

.modal_contactUs h3{
  color: #fff;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.417em;
}

.modal_contactUs .contact-form-block{
  min-height: 508px;
}

.modal_contactUs .btn-primary{
  color: #060606 !important;
    text-align: center;
    transform-style: preserve-3d;
    background-color: #b0f127;
    border: none !important;
    box-shadow: none !important;
    border-radius: 48px;
    padding: 20px 38px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.111em;
    text-decoration: none;
    transition: border-color .3s, background-color .3s, transform .3s, color .3s;
}

.modal_contactUs .close:hover,
 .modal_contactUs .close:focus {
  color: #b0f127;
  text-decoration: none;
  cursor: pointer;
}

.modal_contactUs .select_option option{
  background: #000 !important;
}

.modal_contactUs .g-recaptcha{
  margin-top: 20px;
}

.jconfirm .jconfirm-holder{
  max-width: 350px;
  margin: auto;
}

.jconfirm .jconfirm-box.jconfirm-type-green{
  background: #060606;
  border-top: solid 7px #9bcf2d !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.15), 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.input-wrapper_select{
    display: flex;
    align-items: center;
}

.input-wrapper_select select option{
  background-color: #141414;
}

.input-wrapper_select select {
  width: 40%;
}

.modal-content .contact-form-block{
  width: 100%;
}


.text-area-zero{
  border-radius: 0px !important;
}

.jconfirm .jconfirm-box.jconfirm-type-red{
  background: #131313;
}

.jconfirm .jconfirm-holder{
  max-width: 350px;
  margin: auto;
}

.jconfirm .jconfirm-box.jconfirm-type-green{
  background: #060606;
  border-top: solid 7px #9bcf2d !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.15), 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.input-wrapper_select{
    display: flex;
    align-items: center;
}

.input-wrapper_select select option{
  background-color: #141414;
}

.input-wrapper_select select {
  width: 40%;
}

.modal-content .contact-form-block{
  width: 100%;
}


.text-area-zero{
  border-radius: 0px !important;
}

.jconfirm .jconfirm-box.jconfirm-type-red{
  background: #131313;
}

.jconfirm .jconfirm-box-container.jconfirm-no-transition{
  min-width: 350px !important;
}

.jconfirm .jconfirm-box-container{
  min-width: 350px !important;
}



@media (max-width:1440px) {
  .perk-tab-inside .display-4 {
    font-size: 20px;
  }

  .perk-tab-inside p{
    font-size: 15px;
  }
  /* .cta-section-form  */
}

@media (max-width:1250px) {
  .tab_view .grid-4-columns{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width:1024px) {
  .tab_view .grid-4-columns{
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width:767px) {
  .tab_view .grid-4-columns{
    grid-template-columns:  1fr;
  }
}

@media (max-width:991px) {
  .tab_view .tab{
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width:576px) {
  .tab_section{
    padding: 80px 24px 80px 24px !important;
  }
  .tab button{
    font-size: 17px;
  }
}

@font-face {
  font-family: 'Social Icons Font';
  src: url('../fonts/social-icon-font.woff2') format('woff2'), url('../fonts/social-icon-font.eot') format('embedded-opentype'), url('../fonts/social-icon-font.woff') format('woff'), url('../fonts/social-icon-font.ttf') format('truetype'), url('../fonts/social-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Line Square Icons';
  src: url('../fonts/line-square-icons.woff2') format('woff2'), url('../fonts/line-square-icons.eot') format('embedded-opentype'), url('../fonts/line-square-icons.woff') format('woff'), url('../fonts/line-square-icons.ttf') format('truetype'), url('../fonts/line-square-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Filled Icons';
  src: url('../fonts/filled-icon-font.woff2') format('woff2'), url('../fonts/filled-icon-font.eot') format('embedded-opentype'), url('../fonts/filled-icon-font.woff') format('woff'), url('../fonts/filled-icon-font.ttf') format('truetype'), url('../fonts/filled-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Line Rounded Icons';
  src: url('../fonts/line-rounded-icons.woff2') format('woff2'), url('../fonts/line-rounded-icons.eot') format('embedded-opentype'), url('../fonts/line-rounded-icons.woff') format('woff'), url('../fonts/line-rounded-icons.ttf') format('truetype'), url('../fonts/line-rounded-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}