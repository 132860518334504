body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #b5b8bb;
  outline: 2px solid #b5b8bb;
}

.table-responsive::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #b5b8bb;
  outline: 2px solid #b5b8bb;
}

.pagination {
  margin-top: 15px;
}

/* modal css */

.modalCustom {
  top: 10%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: #201e1e;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modalCustom h1 {
  color: #fff !important;
}

.borderCVE {
  border: none !important;
  background: #201e1e;
  padding: 15px !important;
}

.borderCVE .block-header {
  margin-bottom: -8px !important;
}

.borderCVEGrad {
  max-width: 49% !important;
}

#main-content {
  overflow-y: hidden;
}

.borderCVERef {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px !important;
}

.table_div {
  display: flex;
  flex-direction: column;
}

.outline_h2 {
  margin: 0px 19px 0px 19px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

select option {
  background: #141414 !important;
}

.span_total {
  font-size: 18px;
  color: #b0f127;
}

.summaryGraph text {
  font-size: 22px !important;
  fill: #ffffff;
}

.nav-table-tab {
  color: #fff !important;
  font-size: 14px !important;
  cursor: pointer !important;
  width: 220px !important;
  text-align: center !important;
}

.nav-table-tab-big {
  width: 300px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
}

.MuiSelect-select {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid #fff;
}

.MuiSelect-select {
  border: 1px solid #fff;
}

.MuiList-root {
  background: #060606 !important;
  color: #fff !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.css-1xcs70u-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #b0f127 !important;
  border-width: 1px !important;
}

.modal_mob {
  height: auto !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #b0f127 !important;
}

.MuiMenuItem-root {
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  max-height: 12px;
  font-size: 14px;
  min-height: 14px;
}

.MuiSelect-select {
  padding: 9px !important;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background: #b0f127;
  color: #141414 !important;
} */

.MuiSelect-select {
  background: #fbecea;
  color: #141414 !important;
  border-radius: 40px !important;
  padding: 5px 9px 5px 15px !important;
}

.css-qiwgdb.MuiSelect-select {
  background: #fbecea;
  color: #141414 !important;
  padding: 8px 10px;
  font-size: 14px;
}

.css-r8u8y9 {
  background: #fdf5f5 !important;
  color: #1e1a1f !important;
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.MuiButtonBase-root {
  padding: 6px 12px 6px 14px !important;
  width: 100%;
  justify-content: unset !important;
  font-size: 14px !important;
  border-radius: 8px !important;
}

.Mui-selected {
  background-color: rgb(25 118 210 / 31%) !important;
}

.Unsnooze {
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.2px !important;
}

.checkUncheck {
  width: 17px;
  height: 17px;
  margin-top: 3px;
}

.page-item.active .page-link {
  color: #000 !important;
}

.custom-select-search {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid !important;
  background: transparent !important;
  padding: 0px 15px !important;
  font-size: 15px !important;
}

#react-select-5-live-region {
  display: none !important;
}

.css-b62m3t-container {
  position: absolute !important;
  width: 100%;
  top: -18px;
}
