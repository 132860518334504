@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css?family=Poiret+One");

/* body, html {
	height: 100%;
	background-repeat: no-repeat; 
	background:black;
	position: relative;
} */

#login-box {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 440px;
  margin: 0 auto;
  border: 1px solid black;
  background: rgba(48, 46, 45, 1);
  min-height: 250px;
  padding: 20px;
  z-index: 9999;
}
#login-box .logo .logo-caption {
  font-family: "Poiret One", cursive;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 20px;
}
#login-box .logo .tweak {
  color: #ff5252;
}
#login-box .form-auth-small {
  padding-top: 30px;
}
#login-box .form-auth-small input {
  border-radius: 0px;
  background: #626060;
  border: 0px;
  color: white !important;
  font-family: "Nunito", sans-serif;
}
#login-box .form-auth-small input:focus {
  box-shadow: none;
}
#login-box .form-auth-small input:focus-visible {
  border: none;
  outline: none;
}

/* #login-box input:-webkit-autofill,
#login-box input:-webkit-autofill:hover, 
#login-box input:-webkit-autofill:focus, 
#login-box input:-webkit-autofill:active{
	-webkit-text-fill-color: #626060 !important;
	transition: background-color 5000s ease-in-out 0s;
} */

#login-box button.btn-custom {
  border-radius: 2px;
  margin-top: 8px;
  background: #ff5252;
  border-color: rgba(48, 46, 45, 1);
  color: white;
  font-family: "Nunito", sans-serif;
}
#login-box button.btn-custom:hover {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  background: rgba(48, 46, 45, 1);
  border-color: #ff5252;
}
#particles-js {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: fixed;
  top: 0px;
  z-index: 1;
}

#login-box h1 {
  font-size: 40px;
}

#login-box #email,
#login-box #passwords {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

#login-box #password {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#login-box .btn {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
}

#login-box ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.dashboardFirstHeading {
  color: #ffffff;
}

.dashboardSecondHeading {
  float: right;
  color: #ffffff;
}

.fa-file-text:before,
.fa-certificate:before,
.fa-exclamation:before {
}

.login:before,
.reset_password:before {
  content: "";
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  z-index: -1;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.login,
.reset_password {
  position: relative;
  margin: 30px auto;
  padding: 0px 20px 20px 20px;
  width: 420px;
  height: 466px;
  background: white;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 200px rgb(255 255 255 / 50%),
    0 1px 2px rgb(0 0 0 / 30%);
  box-shadow: 0 0 200px rgb(255 255 255 / 50%), 0 1px 2px rgb(0 0 0 / 30%);
}

.reset_password .form-control-new {
  color: #000000;
  width: 360px;
  margin: 5px;
  padding: 0 10px;
  height: 40px;
  /* color: #ffffff; */
  background: none;
  border: none;
  border-color: #7e7e7e;
  border-radius: 2px;
  outline: 1px solid #7e7e7e;
  -moz-outline-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
}

.login .form-control-new {
  width: 360px;
  margin: 5px;
  padding: 0 10px;
  height: 40px;
  color: #ffffff;
  background: none;
  border: none;
  border-color: #7e7e7e;
  border-radius: 2px;
  outline: 1px solid #7e7e7e;
  -moz-outline-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
}

.loginBg {
  background-image: linear-gradient(
    #2f3443,
    #2a2f3d,
    #1e222e,
    #141821
  ) !important;
}

.login_logo {
  background-image: url("../images/image-gallery/brandsek.png");
  width: 90px;
  height: 90px;
  display: flex;
  margin: auto;
  margin-top: 10px;
  background-size: contain;
}

.brandshield {
  background-image: url("../images/image-gallery/Final_Logo.png");
  width: 200px;
  height: 74px;
}

.invinsense {
  background-image: url("../images/image-gallery/invinsense.png");
  width: 200px;
  height: 35px;
}

.login h1,
.reset_password h1 {
  margin: -20px -20px 21px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #555 !important;
  text-align: center;
  text-shadow: 0 1px white;
  background: #f3f3f3;
  border-bottom: 1px solid #cfcfcf;
  border-radius: 3px 3px 0 0;
  background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
  background-image: linear-gradient(to bottom, whiteffd, #eef2f5);
  -webkit-box-shadow: 0 1px whitesmoke;
  box-shadow: 0 1px whitesmoke;
}

.login p.remember_me label,
.reset_password p.remember_me label {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.login p.remember_me input,
.reset_password p.remember_me input {
  position: relative;
  bottom: 1px;
  margin-right: 4px;
  vertical-align: middle;
}

.login p.remember_me,
.reset_password p.remember_me {
  /* float: left; */
  line-height: 31px;
}

.login p,
.reset_password p {
  margin: 20px 0 20px 10px;
}
/* 
.login p.submit {
    text-align: right;
} */

.login .loginButton,
.reset_password .loginButton {
  padding: 0 22px;
  height: 32px;
  margin-left: 12px;
  font-size: 14px;
  color: #ffffff;
  text-shadow: 0 1px #e3f1f1;
  background: #181c26;
  border: 1px solid;
  border-color: #b4ccce #b3c0c8 #9eb9c2;
  border-radius: 16px;
  box-sizing: content-box;
  /* padding: 0 22px;
    height: 32px;
	margin-left: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #527881;
    text-shadow: 0 1px #e3f1f1;
    background: #cde5ef;
    border: 1px solid;
    border-color: #b4ccce #b3c0c8 #9eb9c2;
    border-radius: 16px;
    outline: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-image: -webkit-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -moz-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -o-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: linear-gradient(to bottom, #edf5f8, #cde5ef);
    -webkit-box-shadow: inset 0 1px white, 0 1px 2px rgb(0 0 0 / 15%);
    box-shadow: inset 0 1px white, 0 1px 2px rgb(0 0 0 / 15%); */
}

.login .loginFooter {
  display: flex;
}

.login .loginGoogle {
  height: 32px;
  width: 360px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px #e3f1f1;
  background: none;
  /* background: #ffffff; */
  border: 1px solid;
  border-color: #7e7e7e;
  outline: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.login-help {
  margin: 20px 0;
  font-size: 14px;
  color: white;
  text-align: center;
  text-shadow: 0 1px #2a85a1;
}

.login-help a {
  color: #cce7fa;
  text-decoration: none;
}

.login .form-control-feedback {
  position: absolute;
  top: 19px;
  right: 15px;
  width: 34px;
  text-align: center;
  height: 34px;
  z-index: 2;
  cursor: pointer;
}

.login .has-feedback {
  position: relative;
}

.tox-notifications-container {
  display: none;
}

.arrowRed {
  color: #ff0000;
  font-size: 20px !important;
  margin-right: 4px;
}

.arrowGreen {
  color: #00ff1f;
  font-size: 20px !important;
  margin-right: 4px;
}

.securityHeader.first {
  margin-left: 1%;
}
.securityHeader.last {
  margin-right: 0%;
  width: 18% !important;
}

.securityHeader .card {
  margin-bottom: 0px;
  background-color: #4c4c4c;
}

.securityHeader {
  width: 19%;
  margin-right: 1%;
}

.table,
.textColor {
  color: #1e1a1f;
}

.textColor:focus {
  color: #1e1a1f !important;
}

.surface-table table tr td {
  padding: 4px;
}

.apexcharts-canvas {
  color: #000000;
}

.falsePositive,
.mitigated {
  background-color: blue;
  color: #1e1a1f;
}

.clientName {
  font-size: 14px !important;
  color: #dd3731 !important;
  margin-top: 2px;
  
    position: absolute;
    right: 5px;
    width: 86px;
    white-space: normal;
    line-height: 16px;
    height: 33px; 
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #b5b8bb;
  outline: 2px solid #b5b8bb;
}

.table-responsive::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #b5b8bb;
  outline: 2px solid #b5b8bb;
}

.pagination {
  margin-top: 15px;
}

/* modal css */

.modalCustom {
  top: 10%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: #201e1e;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modalCustom h1 {
  color: #1E1A1F !important; font-size: 22px;
}



.borderCVEGrad {
  max-width: 49% !important;
  margin: 64px 16px 0 0;
}

#main-content {
  overflow-y: auto;margin: 64px 16px 0 0;
}

.borderCVERef {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px !important;
}

.table_div {
  display: flex;
  flex-direction: column;
}

.outline_h2 {
  margin: 0px 19px 0px 19px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

select option {
  background: #e9e0e7 !important;
  color: #1e1a1f;
}

.span_total {
  font-size: 40px;
  color: #dd3731;
}

.summaryGraph text {
  font-size: 22px !important;
  fill: #1e1a1f;
}

.nav-table-tab {
  color: #1e1a1f !important;
  font-size: 14px !important;
  cursor: pointer !important;
  width: 220px !important;
  text-align: center !important;
}

.nav-table-tab-big {
  width: 300px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #1e1a1f !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid #fff;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #fdf5f5 !important;
  color: #1e1a1f !important;
  border-radius: 8px;
  box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
  padding: 10px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #1e1a1f !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #1e1a1f !important;
}

.css-1xcs70u-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #1e1a1f !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fae2e2 !important;
  border-width: 0px !important;
}

.modal_mob {
  height: auto !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #fae2e2 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  max-height: 12px;
  font-size: 14px;
  min-height: 14px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 9px 5px 15px !important;
}

.MuiMenuItem-gutters:hover {
  background: #fae2e2 !important;
  color: #141414 !important;
}

#wizard_horizontal .nav-tabs {
  flex-wrap: nowrap !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dd3731 !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 40px !important;
}

/*Update UI CSS*/
.white-box-dashboard h4,
.white-box-dashboard h6 {
  background: #fadfe1;
  color: #1e1a1f;
  font-size: 16px;
  font-weight: 500;
  border-radius: 16px;
  padding: 10px 16px;
  text-align: left;
}

.card-box h5,
.card-box h2 {
  color: #1e1a1f;
}

.white-box-dashboard .card-box h4 {
  background-color: transparent;
  padding: 0;
}

.progress_progress__9MVlF {
  width: 50% !important;
  margin: 0 auto;
}
.block-header {
  margin: 0;
}
/* .borderCVE {
  border: none !important;
  background: #201e1e;
  padding: 15px !important;
} */

.borderCVE .block-header {
  padding-top: 0;
  margin-bottom: -5px !important;
}
.borderCVE ,
.modalCustom,
.card-box, .card-box-form {
  background: #fcf1f0;
  padding: 16px;
  border-radius: 16px;
  margin-right: -15px;
  color: #1e1a1f;
  height: calc(100% - 16px);
  margin-bottom: 16px;
  border: 0;
}

.modalCustom{background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);}

.card-box-form{background-color: #fff; border: 1px solid #fadfe1;}
.card-box.card {
  width: calc(100% + 16px);
}
.card-box.card.mr-0 {
  width: 100%;
}


.card-box label {
  font-size: 16px;
  text-align: left;
  width: 100%;
}
.card-box h6 {
  font-size: 22px;
  font-weight: 500;
}
.btn-secondary,.btn-primary {
  background-color: #dd3731;
  border-color: #dd3731;
  color: #fff !important;
  border-radius: 40px;
  font-weight: 500;
  padding: 0px 15px;
  height: 32px;
  line-height: 32px;
}
.btn-outline-primary{
  color: #dd3731;
  border-color: #dd3731;
  border-radius: 40px;
  font-weight: 500;
  padding: 0px 15px;
  height: 32px;
  line-height: 32px;
}
.btn-primary .fa {
  margin-right: 10px;
}
.btn-primary .fa:last-child {
  margin-right: 0;
}

.btn-primary:hover {
  background-color: #fadfe1;
  border-color: #fadfe1;
  color: #231728 !important;
}
.btn-outline-primary:hover {
  background-color: #fadfe1;
  border-color: #fadfe1;
  color: #231728 !important;
}
.btn-secondary{background-color: #FAE1E0; color: #231728 !important; border-color: #FAE1E0;}
.btn-secondary:hover{background-color: #dd3731; color: #fff !important; border-color: #dd3731;}

.btn-brder-detail{border-color:#7d747e; color: #dd3731 !important; background-color: #fff;}


.card-box .card-footer {
  border-top: 1px solid #d8c2bf;
  margin: 15px -16px 0;
  padding-top: 16px;
  text-align: center;
  background-color: transparent !important;
}
.btn-border-footer {
  border-radius: 40px;
  border: 1px solid #7d747e;
  color: #231728;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 8px 16px;
}
.btn.btn-border-footer{color: #dd3731;line-height: normal; padding: 11px 20px; }
.btn.btn-border-footer:hover{background-color: #dd3731; color: #fff; border-color: #dd3731;}
.btn.btn-border-footer .fa{font-size: 14px; margin: 0 5px 0 0; }
.dashboardFirstHeading {
  color: #dd3731;
  font-weight: 700;
}

.icon-dashboard {
  font-size: 50px !important;
  color: #1e1a1f;
}

.custom-file-input,
.light_version .custom-file .custom-file-label {
  background-color: #f8d9d8;
  border-radius: 40px;
  border: 0;
  padding: 8px 15px;
  font-size: 14px; height: 34px;
}
.light_version .custom-file-label::after {
  background-color: #dd3731;
  font-weight: 500;
  color: #fff;
  border-radius: 40px;
  border: 0;
  height: 35px;
  padding: 7px 15px;
}
.light_version .nav.nav-tabs {
  background-color: #f7dad7;
  color: #4c444d;
  font-weight: 600;
  border: 0;
  border-bottom: 0px solid #f7dad7;
}

.MuiFormControl-root {
  background-color: transparent;
}
.MuiInputBase-root {
  background-color: #f4f4f4;
  font-weight: bold;
  background: #fbecea !important;
  color: #1e1a1f !important;
  border-radius: 40px !important;
  letter-spacing: 0.00938em;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot {
  padding-right: 30px !important;
}

.MuiInput-underline:hover {
  background-color: #ebebeb;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #7d747e !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important ;
}
#tags-standard {
  color: #1e1a1f;
  padding: 8px 10px;
  font-size: 14px;
  width: 100%;
}
select.form-control:not([size]):not([multiple]) {
  border: 0;
  border-bottom: 1px solid #7d747e;
  background-color: #f4f4f4;
  border-radius: 0;
}
.hide-close-box .MuiAutocomplete-clearIndicator {
  display: none;
}
.hide-close-box .MuiAutocomplete-endAdornment {
  bottom: 0px !important;
  top: auto !important;
}
.light_version .card .body,
.light_version .card .card-body {
  border: 0;
  background-color: transparent;
  padding: 0px;
}

.list-with-number {
  background-color: #fae2e2;
  padding: 10px 10px 0;
  margin-bottom: 5px; width: 100%;
}
.list-with-number label {
  margin: 0 0 10px;
  text-align: left;
}
.Mui-selected {
  background-color: #fae2e2 !important;
}
.font-red-theme {
  color: #dd3731 !important;
}
.card-box h2.font-red-theme {
  font-size: 40px !important;
  margin: 0 0 0 -15px;
}
.border-red {
  border-color: #fae2e2;
}
.loader {
  border-top-color: #dd3731 !important;
}

.metismenu a.active{color: #000;}

.css-13cymwt-control{border: 0 !important; border-bottom: 1px solid #7d747e !important; border-radius: 0 !important; background-color: #f4f4f4 !important;}

.badge{border-radius: 16px; padding: 7px 12px 5px;}
.badge-success{border-color: #20A651; 
  color: #20A651;}
  .badge-warning{border-color: #dd3731; 
    color: #dd3731;}
    .badge-med {
       
      border-color: #FF746F;
      color: #FF746F;
  }
    .light_version .modal-dialog .modal-content{border-radius: 16px; border-color: transparent; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);}
    .light_version .modal-dialog .modal-content .modal-header, .light_version .modal-dialog .modal-content .modal-footer{background-color: #f4f4f4; border:0 !important; }
    .light_version .modal-dialog .modal-content .modal-header{border-radius: 16px 16px 0 0;}
    .light_version .modal-dialog .modal-content .modal-footer{border-radius:0 0  16px 16px;}
    .modal-dialog .close{color: #dd3731 !important;}
    .modal{background-color: rgba(0, 0, 0, 0.8);}
    .table.table-custom.spacing5{ border-bottom: 1px solid #dee2e6;}
    .progress.progress-xxs{height: 4px; border-radius: 3px;}
    .progress-bar-success{ background-color:  #dd3731 ;}
    .theme-cyan .card .header h2,
    .theme-cyan .form-control:focus,
    .modal-title{color:#1e1a1f ; }
    .theme-cyan .card .header h2{font-weight: bold;}

    .form-control{color:#1E1A1F ;}
    input[type="text"].form-control,
input[type="number"].form-control,
input[type="email"].form-control {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f4f4f4;
  border: 0;
  border-bottom: 1px solid #7d747e !important;
  font-size: 14px;
  color: #1E1A1F;
  margin: 0 !important;
  border-radius: 0;
  height: 32px;
  padding: 8px 10px;
}
.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #dd3731;
  border-color: #dd3731;
box-shadow: 0 0 0 .2rem #FCEBEB;}
.light_version #left-sidebar{background-color: transparent;}
.offcanvas-active #left-sidebar .btn-toggle-offcanvas{position: absolute; right: 0px;     top:5px;
  font-size: 24px;}

  .apexcharts-canvas .apexcharts-yaxis .apexcharts-yaxis-label, .apexcharts-canvas .apexcharts-yaxis .apexcharts-xaxis-label, .apexcharts-canvas .apexcharts-xaxis .apexcharts-yaxis-label, .apexcharts-canvas .apexcharts-xaxis .apexcharts-xaxis-label{fill:#818181  !important}
  .apexcharts-canvas .apexcharts-legend .apexcharts-legend-text{color: #4C444D !important;}
  .progress-bar{background-color: #dd3731;}
  .text-warning{color: #f39a00 !important;}

  .user-account .dropdown .dropdown-menu a{border-radius: 20px; padding: 6px 15px; }
  .user-account .dropdown .dropdown-menu a:hover{background-color: #fAE1E0 !important;}
.badge-cve{display: flex; background-color:#fAE1E0; font-size: 13px; font-weight: 500; border: 0; }
.badge-cve button{color: #DD3731;}
.ref-box{background-color: #fAE1E0; border-radius: 16px;}
.border-cve-btm{border-bottom: 1px solid #f3d5d4; padding:5px 10px;}
.border-cve-btm p{margin: 0;}
.divider._24px{margin-top: 24px; margin-bottom: 24px;}

#navbar-search input[type="text"].form-control{height: 35px;}



  /* Fine Update for UI */
  .top-navbar{margin: 8px 16px;}
  #left-sidebar .navbar-brand{margin: 8px 0px 0 10px; padding: 8px 3px;}
  
  #left-sidebar .navbar-brand a span.logo-default,
  #left-sidebar .navbar-brand a:hover span.logo-hover{ display: inline-block;}

  #left-sidebar .navbar-brand a span.logo-hover,
  #left-sidebar .navbar-brand a:hover span.logo-default{ display: none;}
  #left-sidebar .navbar-brand img{height: 30px;}

  .navbar-nav .icon-menu:hover{background-color: #fAE1E0; border-radius:50%;}
  .navbar-nav .icon-menu{width: 40px; height: 40px; border-radius: 50%; padding: 0; line-height: 40px; text-align: center; cursor: pointer;}
  .metismenu a:hover{color: #231728;    background-color: #fae2e2;border-radius: 20px;}
  .metismenu a{padding: 0px 0 3px 14px;}
  .dashboardSecondHeading{color: #231728;}
  .overflow-auto-router{height: calc(100vh - 72px);}

  #left-sidebar{width: 256px;}
  #main-content{width: calc(100% - 272px);} 
  .btn-primary:hover{transform:translate3d(0, 0px, .01px); box-shadow: none;}
  
  .theme-cyan .sidebar-nav .metismenu > li ul .active a,
  .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle{color:#231728 ;}
  .metismenu .has-arrow:before{margin-left: 6px;vertical-align:2px;}
  .navbar-nav{margin-right: 8px !important;}
  .user-account .dropdown .dropdown-menu{padding: 8px;}
  
  @media screen and (max-width: 1200px) {

    #left-sidebar{left: -380px;}
    #main-content {
      width: calc(100% - 30px);
    }
    .offcanvas-active #left-sidebar .btn-toggle-offcanvas:hover{box-shadow: none;}
    .light_version #left-sidebar{background-color: #fff;} 
   
    
  }

  .active-scan-container {
    display: flex;
    align-items: center; /* Align items to the bottom */
    gap: 10px; /* Space between the label and switch */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .active-scan-label {
    margin: 0;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 22px;
    font-size: 0;
    /* Rounded sliders */
  }
  
  .switch input {
    display: none;
  }
  
  .switch .slider {
    transition: all 0.4s ease-in-out;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.1875rem;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .switch .slider:before {
    transition: all 0.4s ease-in-out;
    background-color: #fff;
    border-radius: 0.1875rem;
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
  }
  
  .switch input:checked+.slider {
    background-color: #05C168;
  }
  
  .switch input:focus+.slider {
    box-shadow: 0 0 1px #05C168;
  }
  
  .switch input:checked+.slider:before {
    transform: translateX(12px);
  }
  
  .switch input:disabled+.slider {
    opacity: 0.3;
  }
  
  .switch .slider.round {
    border-radius: 30px;
  }
  
  .switch .slider.round:before {
    border-radius: 50%;
  }

  .checkBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
  }
  
  .switch {
    margin-left: 10px !important;
  }
  
  .alert-ul{
    padding: 0px !important;
    margin: 0px !important;
  }
  
  .alert-ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  